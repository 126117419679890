import { ListingStatusEnum } from '../../models/create-listing/iCardListing';
import { IListing } from '../../models/create-listing/iListing';

import { IndexSearchResponse } from '../../services/IAZSearch';
import { SearchTermFields } from '../../actions/iSearchTermFields';
import { error as e, logEvents } from '../../actions/logActions';
import { ReCaptchaAction } from '../../helpers/recaptcha';
import { toFilter } from '../../utils/utilities';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SubmitListingProps } from '../../actions/iSubmitListingProps';
import { IActionThunks } from '../../actions/iActionThunks';
import { IGraphSearchResult } from '../../services/search-service/SolrSearchResults';
import { getListingSearchParameters, searchFiltersToDictionary, SearchType, toSolrQueryFormat } from '../../services/search-service/searchUtilities';

import { postAsync, getAsync } from '../../services/baseService';

import { mapToSearchResultItem } from '../../services/search-service/mapper';
import { listingAutoCompleteService } from '../../services/search-service/search-service';
import { IStoreState } from '../../stores/IStore';

import { v4 as uuid } from 'uuid';
import { apolloQuery } from '../../useApolloQuery';
import { pagedListingSearchQuery1 } from '../../data/gql/queries/pagedListingSearchQuery';
import axios from 'axios';

/**
 * @deprecate
 * MOVE ALL ACTIONS TO A RELATED ACTION FILE IN THE FEATURE FOLDER
 */

// const searchCardsUrl = process.env.REACT_APP_SEARCH_CARDS_URL || '';
// const searchAdvertsUrl = process.env.REACT_APP_SEARCH_ADVERT_URL || '';
const createListingUrl = process.env.REACT_APP_CREATE_LISTING_URL || '';
const getFiltersURL = process.env.REACT_APP_LISTING_FILTERS || '';
const getValidationTokenURL = process.env.REACT_APP_ANTIFORGERY_TOKEN || '';
const getListingCategoryCountURL = process.env.REACT_APP_LISTING_FILTERS_COUNT || '';
const getListingCountURL = process.env.REACT_APP_LISTING_COUNT || '';

const token = 'beetlejuice';
const random = Math.floor(Math.random() * 1000);


const dedupArray = (array: any[]) => {
  const seen = new Set(array);
  return Array.from(seen);
};

const getAntiForgery = async () => {
  const result: IThunkResult<any> = {};
  try {
    // const recap = await getRecapToken(ReCaptchaAction.api_get);
    result.result = await axios.get(getValidationTokenURL, {
      headers: {
        'x-cap-tok': token,
      },
    });
  } catch (ex) {
    result.err = ex;
  }

  if (result.err) {
    return result.err;
  }

  return result.result;
};

const updateListing = (actionName: string, listingStatus: ListingStatusEnum) =>
  createAsyncThunk(actionName, async (payload: SubmitListingProps, thunkApi) => {
    try {
      const requestPayload = {
        ...payload.createListing,
        listingStatus,
        id: payload.createListing.id ?? uuid(),
        grade: {
          ...payload.createListing.grade,
          grade: {
            ...payload.createListing?.grade?.grade,
            value: `${payload.createListing?.grade?.grade?.value}`, // bad value type on the fnapp-listings stored as a string, should be a number
          },
        }
      };

      const url = createListingUrl + `?id=${requestPayload.id}&countryCode=${requestPayload.countryCode ?? 'AU'}`;

      const result = await postAsync<IListing, any>(
        url,
        requestPayload,
        undefined,
        ReCaptchaAction.create_listing,
        true
      );
      if (result.err) {
        result.result?.listingStatus === ListingStatusEnum.Published
          ? thunkApi.dispatch(
            logEvents({
              eventName: 'LISTING_PUBLISH_ERROR',
              payload: result.err,
            })
          )
          : thunkApi.dispatch(
            logEvents({
              eventName: 'LISTING_DRAFT_ERROR',
              payload: result.err,
            })
          );
        return thunkApi.rejectWithValue(result.err);
      } else {
        result.result?.listingStatus === ListingStatusEnum.Published
          ? thunkApi.dispatch(
            logEvents({
              eventName: 'LISTING_PUBLISHED_SUBMIT',
              payload: { message: 'Success' },
            })
          )
          : thunkApi.dispatch(
            logEvents({
              eventName: 'LISTING_DRAFT_SUBMIT',
              payload: result.result,
            })
          );
        return result.result;
      }
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  });


const actions: IActionThunks = {
  getValidationTokenThunk: createAsyncThunk('api/get-validation-token', async (args: any, thunkApi: any) => {
    const result = await getAntiForgery();
    if (result.err) {
      thunkApi.rejectWithValue(result.err);
      return result.err;
    }
    return result.result;
  }),

  getListingFiltersCounts: createAsyncThunk('listing/listing-counts', async (filterParameters, thunkApi: any) => {

    // start using React toolkit's query
    try {
      const categories = await axios.get('/data/config/menuCategories.json').then((response) => response.data);
      const { query, queryFilter } = getListingSearchParameters(thunkApi.getState() as IStoreState, SearchType.filters);

      const result: IThunkResult<IndexSearchResponse> = await postAsync<IndexSearchResponse, any>(
        getListingCategoryCountURL,
        null,
        {
          x_q_re: btoa(query),
          x_q_f: btoa(queryFilter),
        },
        ReCaptchaAction.api_get_listing_categories,
        false
      );

      if (!result.err && result.result) {
        const returnResult = toFilter(result.result.facets, categories);
        return returnResult;
      }

      thunkApi.dispatch(logEvents({ eventName: 'LISTING_SEARCH_ERROR', payload: result.err }));
      return thunkApi.rejectWithValue(result.err);
    } catch (ex: any) {
      // eslint-disable-next-line no-console
      console.error(ex);
      thunkApi.dispatch(logEvents({ eventName: 'LISTING_SEARCH_ERROR', payload: ex }));
      return thunkApi.rejectWithValue(ex?.stack ?? ex?.message ?? 'failed to get listing filter counts');
    }
  }),
  getListingCount: createAsyncThunk('listing/listing-count', async (filterParameters, thunkApi: any) => {
    try {
      const listingCount = await getAsync(getListingCountURL, {}, ReCaptchaAction.api_get_listings_count);
      return listingCount;
    } catch (ex: any) {
      thunkApi.dispatch(logEvents({ eventName: 'LISTING_COUNT_ERROR', payload: ex }));
      return thunkApi.rejectWithValue(ex?.stack ?? ex?.message ?? 'Failed to get listing count');
    }
  }),
  listingAutoComplete: createAsyncThunk('lookup/autocomplete', async (searchString: string, thunkApi: any) => {
    try {
      const query = toSolrQueryFormat('search_all', searchString);
      const results = await listingAutoCompleteService(query);

      const returnValue = results?.result?.grouped?.search_all.groups.flatMap((i) =>
        i.doclist.docs.map((b) => ({
          id: dedupArray(b.search_all as Array<string>).join('-').replace(/\s/g, '-'),
          value: dedupArray(b.search_all as string[]).filter((_, index) => index < 4).join(' '),
        }))
      ) || [searchString];
      return ([...returnValue]);
    } catch (ex: any) {
      // eslint-disable-next-line no-console
      console.error(ex);
      return thunkApi.rejectWithValue(ex?.stack ?? ex?.message ?? 'failed to get listing autocomplete');
    }
  }),
  searchListings: createAsyncThunk('listing/searchv2', async (page, thunkApi) => {
    const { listingSearchParameters, filterData } = (thunkApi.getState() as IStoreState).listingSearch;
    const filters = searchFiltersToDictionary(filterData);

    const rSortKey = filters.length === 0 && !listingSearchParameters?.autoCompleteSearchString ? random.toString() : undefined;

    try {
      const {
        data, error, loading
      } = await apolloQuery(pagedListingSearchQuery1.query, {
        ...pagedListingSearchQuery1.variables,
        offset: page * 20,
        searchTerm: listingSearchParameters?.autoCompleteSearchString ?? '',
        filter: filters,
        sortBy: listingSearchParameters?.searchParams?.sortOrder,
        randomSortKey: rSortKey,
      });

      if (error) {
        e('LISTING_SEARCH_ERROR', { errorMessage: error.message });
        return thunkApi.rejectWithValue(error);
      }

      while (loading) {
        await new Promise(resolve => setTimeout(resolve, 400));
      }

      return {
        records: await mapToSearchResultItem(data.listings?.listings,),
        totalRecords: data.listings?.numFound || 0,
        facets: 0
      } as IGraphSearchResult;

    } catch (ex: any) {
      e('LISTING_SEARCH_ERROR', ex);
      return thunkApi.rejectWithValue(ex?.stack ?? ex?.message ?? 'failed to return search results v2');
    }
  }),
  publishListing: updateListing('createListing/publish', ListingStatusEnum.Published),
  updateDraftListing: updateListing('createListing/update', ListingStatusEnum.Draft),
  getFilters: createAsyncThunk('listingSearch/getFilters', async (_, thunkApi) => {

    const getFiltersResponse: IThunkResult<IndexSearchResponse> = await postAsync<IndexSearchResponse, any>(
      getFiltersURL,
      undefined,
      {},
      ReCaptchaAction.get_filters
    );

    if (getFiltersResponse.err) {
      thunkApi.dispatch(
        logEvents({
          eventName: 'FILTER_SEARCH_ERROR',
          payload: getFiltersResponse.err,
        })
      );
      return thunkApi.rejectWithValue(getFiltersResponse.err);
    }

    if (!getFiltersResponse.err && getFiltersResponse.result) {
      return toFilter(getFiltersResponse.result.facets);
    }

    thunkApi.dispatch(
      logEvents({
        eventName: 'FILTER_SEARCH_ERROR',
        payload: 'getFilters: No error or response from post',
      })
    );
    return thunkApi.rejectWithValue(getFiltersResponse.err);
  }),
};

export const {
  getValidationTokenThunk,
  searchListings,
  listingAutoComplete,
  updateDraftListing,
  publishListing,
  getFilters,
  getListingFiltersCounts,
} = actions;

export interface IThunkResult<T> {
  result?: T;
  err?: any;
}

export type KeyOfSearchTermField = keyof SearchTermFields;
export type SearchTermType = KeyOfSearchTermField | KeyOfSearchTermField[];
