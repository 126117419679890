import React from 'react';
import { SearchBar } from 'src/components/SearchBar/SearchBar';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { FlexBox, StyledDivider, StyledHeading } from 'src/components/styled/StyledComponents';


export interface UserPageHeaderProps {
  className?: string;
  title: string;
  pageTitle: string;
  icon?: any;
  showSearchBar?: boolean;
  searchBarPlaceholder?: string;
  showEdit?: boolean;
  isEditable?: boolean;
  onSearchTextChanged?: (text: string) => void;
  onEditClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancelClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onSaveClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export function UserPageHeader(props: UserPageHeaderProps) {
  const Icon = () => props.icon;
  return (
    <FlexBox direction='column' style={{ minHeight: 40 }}>
      <StyledHeading color="#98FFF9" size='large'>
        <FlexBox justifyContent='space-between'>
          <FlexBox alignItems='center' justifyContent='space-between' >
            <Icon />{props.pageTitle}
          </FlexBox>
          <EditSaveCancel
            showEdit={props.showEdit}
            isEditable={props.isEditable}
            onEditClick={props.onEditClick}
            onCancelClick={props.onCancelClick}
            onSaveClick={props.onSaveClick} />
        </FlexBox>
      </StyledHeading >
      {
        props.showSearchBar && <SearchBar style={{ padding: '30px 0' }} onInputTextChanged={props.onSearchTextChanged} placeholder={props.searchBarPlaceholder ?? 'Search listings'}></SearchBar>
      }
      <StyledHeading color="white" text={props.title} size='medium' />
      <StyledDivider />

    </FlexBox >
  );
}



export function EditSaveCancel(props: {
  isEditable?: boolean;
  showEdit?: boolean;
  onEditClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancelClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onSaveClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {

  if (!props.showEdit) {
    return null;
  }


  return (
    <FlexBox justifyContent='space-between' style={{ padding: '20px 0' }}>
      {
        !props.isEditable
          ? <IconButton onClick={props.onEditClick}>
            <EditIcon style={{ color: '#ffffff60' }} />
          </IconButton>
          : <><IconButton onClick={props.onSaveClick}>
            <SaveIcon style={{ color: '#ffffff60' }} />
          </IconButton>
            <IconButton onClick={props.onCancelClick}>
              <CancelIcon style={{ color: '#ffffff60' }} />
            </IconButton>
          </>
      }
    </FlexBox>
  );
}