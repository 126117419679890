/* eslint-disable no-console */
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useMobileFooterBar } from 'src/views/useMobileFooterBar';
import { StyledRainbowButton } from 'src/components/shared/Button';
import { Dialog } from 'src/components/shared/dialog';
import { StyledInformation } from 'src/components/shared/Instruction';
import { FlexBox, StyledDivider, StyledText } from 'src/components/styled/StyledComponents';
import { useSnackbarSend } from 'src/features/snackBarContext';
import { verifyEmailAction } from 'src/features/user/actions';
import { useAppDispatch } from 'src/hook';
import { routeConfig } from 'src/routes/routeConfig';


export function EmailVerification() {
    const { MobileFooterBar } = useMobileFooterBar();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { loginWithPopup } = useAuth0();
    const { enqueue } = useSnackbarSend();
    const [emailSent, setEmailSent] = React.useState(false);

    async function verifyEmail() {
        dispatch(verifyEmailAction());
        enqueue?.('success', 'Verification email sent, please check your inbox');
        setEmailSent(true);
    }

    async function continueToApp() {
        await loginWithPopup();
        navigate(routeConfig.home);

    }

    return (
        <FlexBox style={{ width: '100%', height: '100%' }} direction='column' rowGap={16} alignItems='center' justifyContent='center'>
            <h1>Email Verification</h1>
            {emailSent
                ? <StyledInformation italic style={{ height: 50, alignContent: 'center' }}>Verification email sent, please check your inbox</StyledInformation>
                : <>
                    <StyledInformation color='white' >
                        Your email address has not been verified.
                        To place an advert the email address you provided needs to be verified.
                    </StyledInformation>
                    <FlexBox direction='column'>
                        <StyledInformation italic >
                            * You might need to check your spam folder for the verification email.
                        </StyledInformation>
                        <StyledInformation italic >
                            ** if you keep returning to this screen, try logging out and back in again.
                        </StyledInformation>
                    </FlexBox>
                </>

            }
            <StyledRainbowButton onClick={() => verifyEmail()} variant='secondary-outline' style={{ width: 250, height: 40 }} >
                Resend Verification Email
            </StyledRainbowButton>
            <Dialog show={emailSent}
                onClose={function (): void {
                    console.log('false');
                }} >
                <FlexBox direction='column' rowGap={8} alignItems='center' justifyContent='center' style={{ padding: 20 }}>
                    <StyledText width="370px" >
                        <StyledInformation italic >
                            An email has been sent to your email address. Please verify your email before continuing
                        </StyledInformation>
                        <StyledInformation italic >
                            You might need to check your spam folder for the verification email.
                        </StyledInformation>
                    </StyledText>
                    <StyledDivider />
                    <StyledRainbowButton onClick={() => continueToApp()} style={{ width: 250, height: 40 }}>
                        Continue
                    </StyledRainbowButton>
                </FlexBox>
            </Dialog>
            {MobileFooterBar}
        </FlexBox>
    );
}