import React from 'react';
import { Main, PageType } from 'src/components/Main';


export function CheckoutPage(props: { children: any; }) {
    return (
        <Main pageType={PageType.Checkout}>
            {props.children}
        </Main>
    );
}