import React, { useMemo } from 'react';
import { ISearchResultItem } from '../models/iSearchResultItem';
import classes from '../styles/SearchResultItem.module.scss';
import noPhoto from '../assets/svg/no-photo.svg';
import { toCurrency } from '../utils/utilities';
import { Link } from 'react-router-dom';
import { RemoteImage } from '../features/cardSearch/RemoteImage';
import { Img } from './Image';
import { ReactComponent as TrendingDown } from '../assets/svg/trendingdown.svg';
import { ReactComponent as TrendingUp } from '../assets/svg/trendingup.svg';

export function CardSearchResultItem(props: {
    item: ISearchResultItem;
    imageProps?: React.ImgHTMLAttributes<HTMLImageElement>;
    busy?: boolean;
    onSelected?: (item: ISearchResultItem) => void;
}) {


    const getUrl = (item: ISearchResultItem) => {
        return encodeURI(`${item.id}/${item.heading}/${item.subHeading}/${item.title}`);
    };

    const frontImage = props.item.imageSrc[0] ?? noPhoto;
    const rarity = useMemo(() => {
        return <div className={classes.conditionContent}>
            <div className={classes.rarity}>
                <div style={{ color: '#8A8E90' }}>
                    <span><RemoteImage style={{ width: 16 }} imgProps={{ style: { height: 13, width: 13, transform: 'translate(1px, 1px)' } }} imageName={props.item.rarity} path="pokemon/rarity" /></span>
                    <span className={classes.rarityLabel}>{props.item.rarity}</span>
                </div>
            </div>
            <span>{props.item.number}</span>
        </div>;
    }, [props.item.id]);

    const Trending = (props: { growth: number; }) => {

        if (props.growth < 0)
            return <div style={{ display: 'flex', alignItems: 'center', gap: 6, color: '#FF8F00', fontWeight: 'bolder' }}><TrendingDown style={{ fill: '#FF8F00' }} />{props.growth}%</div>;
        else if (props.growth > 0)
            return <div style={{ display: 'flex', alignItems: 'center', gap: 6, color: '#1CE30C', fontWeight: 'bolder' }}><TrendingUp style={{ fill: '#1CE30C' }} />{props.growth}%</div >;
        else
            return <></>;
    };

    const cardDetails = useMemo(() => {
        return <div className={classes.detail}>
            <div className={classes.name}>{props.item.title ?? props.item.name}</div>
            <div className={classes.background}>
                <div className={classes.seriesContent}>
                    <span className={classes.series}>{props.item.heading ?? props.item.series}</span>
                    <span className={classes.price}>
                        {toCurrency(props.item.marketSummary?.[0]?.sold_price_median_current_months)}
                    </span>
                </div>
                <div className={classes.setContent}>
                    <span className={classes.set}>{props.item.subHeading}</span>
                    <span className={classes.marketGrowth}>
                        <Trending growth={props.item.marketSummary?.[0]?.growth_rate_median_all_months} />
                        {props.item.marketSummary?.[0]?.growth_rate_median_all_months &&
                            <span>Current Market Avg.</span>
                        }
                    </span>
                </div>
            </div>
            {rarity}
        </div>;


    }, [props.item.id]);

    const cardDetail = useMemo(() => {
        return cardDetails;
    }, [props.item.id]);


    return (
        <div className={classes['search-item-container']}>
            <div className={classes.thumbnail}>
                <Link to={`/insights/card/${getUrl(props.item)}`} onClick={() => props.onSelected?.(props.item)}>
                    <Img className={props.imageProps?.className} src={frontImage} loading='lazy' fallback={noPhoto} name={props.item.name} style={props.imageProps?.style} alt={props.item.name} />
                </Link>
            </div>
            <div className={classes.content}>
                {cardDetail}
            </div>
        </div>
    );
}
