/* eslint-disable react/react-in-jsx-scope */

import { useEffect } from 'react';
import { HeaderBar } from './HeaderBar';
import { FlexBox } from './styled/StyledComponents';
import React from 'react';


export enum PageType {
  Insights = 'Insights',
  Marketplace = 'Marketplace',
  Checkout = 'Checkout',
  CreateListing = 'CreateListing',
  Faq = 'Faq',
  CardInsight = 'CardInsight',
  User = 'User',
  BulkListingEditor = 'BulkListingEditor',
}

export function Main(props: { children?: JSX.Element | undefined; className?: string; pageType: PageType; }) {

  const [headerType, setHeaderType] = React.useState<PageType>(props.pageType);

  useEffect(() => {
    setHeaderType(props.pageType);
  }, [props.pageType]);


  return (
    <FlexBox direction='column' alignItems='center' height={'100%'} >
      <HeaderBar type={headerType} />
      <main-page class={props.className} style={{ width: '100%', backgroundColor: '#1a2329', paddingTop: 60 }}>{props.children}</main-page>
    </FlexBox>
  );
}
