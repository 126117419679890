import React from 'react';
import { HeadingProps } from './InstructionProps';


export function Heading(props: HeadingProps) {
  return (
    <heading-text class={props.className}>
      {props.children || props.text}
    </heading-text>
  );
}

export function ErrorText(props: { text?: string; children?: React.ReactNode; }) {
  return props.text ? (
    <div
      style={{
        color: 'tomato',
        lineHeight: 2,
        border: '1px solid tomato',
        boxShadow: '0px 0px 14px 2px tomato',
        borderRadius: 15,
        padding: 4,
      }}
    >
      <span>{props.text}</span>
    </div>
  ) : (
    <>{props.children}</>
  );
}
