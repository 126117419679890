import React, { useEffect, useMemo } from 'react';
import { TumbleWeed } from '../../components/shared/TumbleWeed';
import { UserPageHeader } from './components/UserPageHeader';
import classes from './my-listings.module.scss';
import { UserAsset } from './UserAsset';
import { ReactComponent as SellersHub } from '../../assets/svg/user-profile/sellers-hub.svg';
import { useAppDispatch, useAppSelector } from '../../hook';
import { LoadingWidget } from '../../components/shared/Loading';
import { selectPurchaseHistory } from './buyer/buyerSelector';
import { IUserRating, getPurchaseHistory, sendMessage, submitSellerRating } from './buyer/buyerActions';
import { IListing } from '../../models/create-listing/iListing';
import { RatingDialog } from '../../components/RatingDialog';
import { IMessage, MessageDialog } from '../../components/MessageDialog';
import { useSnackbar } from '../../shared/hooks/snackbar';
import { useAuthHook } from '../../shared/hooks/useAuthHook';

export function PurchaseHistory() {
  const dispatch = useAppDispatch();
  const { user } = useAuthHook();

  const { data, isLoading } = useAppSelector(selectPurchaseHistory);
  const [isRatingDialogOpen, setIsRatingDialogOpen] = React.useState(false);
  const [selectedListing, setSelectedListing] = React.useState<IListing>();
  const [listingRatings, setListingRatings] = React.useState<IUserRating[]>([]);
  const [isMessageDialogOpen, setIsMessageDialogOpen] = React.useState(false);
  const [selectedRating, setSelectedRating] = React.useState<IUserRating>();


  //need to centralise this logic to make it MORE reusable
  const { enqueue, snackbar } = useSnackbar();

  useEffect(() => {
    if (selectedListing) {
      const rating = listingRatings.find((r) => r.listingId === selectedListing.id);
      setSelectedRating(rating);
    }
  }, [listingRatings]);


  const submitMessage = (listing: IListing) => {
    setSelectedListing(listing);
    setIsMessageDialogOpen(true);
  };

  const rateUser = (listing: IListing) => {
    setSelectedListing(listing);
    setIsRatingDialogOpen(true);
  };


  const onMessageDialogClose = () => {
    setIsMessageDialogOpen(false);
  };

  const onRatingDialogClose = () => {
    setIsRatingDialogOpen(false);
  };

  const onRatingSubmit = async () => {
    setIsRatingDialogOpen(false);

    const response = await dispatch(submitSellerRating(listingRatings.reverse()[0]));

    if (response.meta.requestStatus === 'fulfilled') {
      enqueue('success', 'Rating submitted successfully');
    }

  };

  const onMessageSubmit = async (message: IMessage) => {
    setIsMessageDialogOpen(false);

    if (!selectedListing) {
      enqueue('error', 'We ran into a problem submitting your message, please try again later, or come chat to us on discord.');
    }

    message.listingId = selectedListing?.id ?? '';
    message.recipient = selectedListing?.userId ?? '';
    message.sender = btoa(user?.email || '');

    const response = await dispatch(sendMessage(message));

    if (response.meta.requestStatus === 'fulfilled') {
      enqueue('success', 'Your message has left the building 👍🏛️');
    }

  };

  const onRatingSelected = (rating: IUserRating) => {
    if (selectedListing) {
      rating.listingId = selectedListing.id;
      rating.timestamp = new Date();
      setListingRatings([...listingRatings, rating]);
    }
  };

  useEffect(() => {
    if (user?.email) {
      dispatch(getPurchaseHistory(btoa(user?.email)));
    }
    else {
      // eslint-disable-next-line no-console
      console.error('User not logged in, or user email is not defined');
    }
  }, []);



  const assets = useMemo(() => {
    return data?.map((listing) => {
      const img = JSON.parse(listing.imageList[0])?.name;
      return {
        asset: {
          id: listing.id,
          imageUrl: `${process.env.REACT_APP_IMAGES_URL}/userImages/thumbnails/${img}`,
          line1: listing.name,
          line2: listing.number,
          line3: listing.rarity,
          line4: listing.number,
          price: listing.price,
          purchaseDate: '',
        },
        actions: [{
          name: 'Leave Feedback',
          disabled: false,
          action: () => {
            const rating = listingRatings.reverse().find((r) => r.listingId === listing.id);
            setSelectedRating(rating);
            rateUser(listing);
          }
        }, {
          name: 'Contact Seller',
          action: () => {
            submitMessage(listing);
          }
        }
        ],
      };
    });
  }, [data]);

  if (!isLoading && !data?.length) {
    return <TumbleWeed message="List a collectible! Then edit it here!" />;
  }



  return (
    <div className={classes.myListings}>
      {snackbar}
      <UserPageHeader title="Items I have purchased" pageTitle="Purchase History" icon={<SellersHub height={30} width={30} />} />
      <RatingDialog show={isRatingDialogOpen} onClose={onRatingDialogClose} onSubmit={onRatingSubmit} onRatingSelected={onRatingSelected} selectedRating={selectedRating} />
      <MessageDialog show={isMessageDialogOpen} onClose={onMessageDialogClose} onSubmit={onMessageSubmit} />
      <LoadingWidget isLoading={isLoading} style={{ marginTop: 40, height: 100 }}>
        {assets?.map((asset, index) => (
          <React.Fragment key={index}>
            <UserAsset key={index} asset={asset.asset} actions={asset.actions}></UserAsset>
          </React.Fragment>
        ))}
        <hr />
      </LoadingWidget>
    </div>
  );
}


