/* eslint-disable no-console */
import React, { useMemo } from 'react';
import { ISearchResultItem } from '../models/iSearchResultItem';
import classes from '../styles/SearchResultItem.module.scss';
import noPhoto from '../assets/svg/no-photo.svg';
import { toCurrency } from '../utils/utilities';
import { Link } from 'react-router-dom';
import { RemoteImage } from '../features/cardSearch/RemoteImage';
import { UserName } from './UserName';
import { ListingType } from './tiles/Tiles';
import { Img } from './Image';
import { FlexBox } from './styled/StyledComponents';
import ShoppingCart from '@mui/icons-material/AddShoppingCart';
import { RainbowButton } from './shared/Button';


export function ListingSearchResultItem(props: {
  item: ISearchResultItem;
  imageProps?: React.ImgHTMLAttributes<HTMLImageElement>;
  busy?: boolean;
  onSelected?: (item: ISearchResultItem) => void;
  addToCart?: (item: ISearchResultItem) => void;
}) {

  const tags = useMemo(() => {
    return <div className={classes.tagChip}>
      {
        props.item.tags?.filter((_, index) =>
          index <= 5)?.map((i: string, index: number) =>
            <span key={index}>{i}</span>)
      }
    </div >;
  }, [props.item.listingType]);

  const getUrl = (item: ISearchResultItem) => {
    // once all the adverts have been updated to use the new heading, subheading, footer, footer2 format remove set and series.
    return encodeURI(`${item.id}/${item.heading ?? item.series}/${item.subHeading ?? item.set}/${item.title ?? item.name}`);
  };

  const getShipping = (shippingCost: number | undefined) => {
    if (shippingCost === 0 || shippingCost === undefined)
      return 'Free Shipping';
    return toCurrency(shippingCost ?? 0);
  };

  const frontImage = props.item.imageList?.[0]?.name
    ? `${process.env.REACT_APP_IMAGES_URL}/userImages/thumbnails/${props.item.imageList?.[0].name} `
    : noPhoto;


  const rarity = useMemo(() => {
    if (props.item.listingType !== ListingType.singleCard)
      return null;
    else
      return <div className={classes.conditionContent}>
        <div className={classes.rarity}>
          <div style={{ color: '#8A8E90' }}>
            <span><RemoteImage style={{ maxHeight: 13, maxWidth: 13 }} imgProps={{ style: { height: 13, width: 13, transform: 'translate(1px, 1px)' } }} imageName={props.item.rarity} path="pokemon/rarity" /></span>
            <span className={classes.rarityLabel}>{props.item.rarity}</span>
          </div>
        </div>
        <span>{props.item.number}</span>
      </div>;
  }, [props.item.id]);


  const condition = useMemo(() => {
    if (props.item.listingType !== ListingType.singleCard)
      return null;
    else
      return <span className={classes.condition} style={{ color: '#ffffff' }}>
        <span>{props.item.graded}</span>
      </span>;


  }, [props.item.id]);

  const cardDetails = useMemo(() => {
    return <div className={classes.detail}>
      <div className={classes.name}>{props.item.title ?? props.item.name}</div>
      <div className={classes.background}>
        <div className={classes.seriesContent}>
          <span className={classes.series}>{props.item.heading ?? props.item.series}</span>
          <span className={classes.price}>
            {toCurrency(props.item.price)}
          </span>
        </div>
        <div className={classes.setContent}>
          <span className={classes.set}>{props.item.subHeading ?? props.item.set}</span>
          <span className={classes.shippingPrice}>
            {getShipping(props.item.shippingCost)}
          </span>
        </div>
        <div className={classes.shippingTypeContent}>
          <span>{condition}</span>
          <span>{props.item.isTracked?.[0] && 'Tracked'} Shipping</span>
        </div>
        <div className={classes.userName}>
          <UserName name={props.item.seller} avatar={props.item.sellerAvatar} />
        </div>
      </div>

      {rarity}
    </div>;


  }, [props.item.id]);

  const mixedBundleDetails = useMemo(() => {
    return (
      <div className={classes.mixedBundleDetail}>
        <div className={classes.name}>{props.item.title}</div>
        <div className={classes.background} style={{ width: '100%' }}>
          <div className={classes.tagContent} >
            <span className={classes.mixedBundleLabel}>Mixed Bundle</span>
            <div className={classes.series} style={{ width: '200%' }}>{tags}</div>
          </div>
          <div className={classes.priceContent}>
            <div className={classes.price}>{toCurrency(props.item.price)}</div>

            <div className={classes.shippingTypeContent}>
              <div className={classes.shippingPrice}>
                +{toCurrency(props.item.shippingCost ?? 0)}
              </div>
              <div className={classes.shippingType}>{props.item.isTracked?.[0] && 'Tracked'} Shipping</div>
            </div>
          </div>
          <div className={classes.userName} style={{ width: '100%', display: 'flex', alignItems: 'space-between' }}>
            <UserName name={props.item.seller} avatar={props.item.sellerAvatar} className={classes.username} />
          </div>
        </div>

        {rarity}
      </div>);
  }, [props.item.id]);


  const listingDetails = useMemo(() => {
    switch (props.item.listingType) {
      case ListingType.singleCard:
        return cardDetails;
      case ListingType.mixedBundle:
        return mixedBundleDetails;
      default:
        return cardDetails;

    }
  }, [props.item]);

  return (
    <div className={classes['search-item-container']}>
      <FlexBox className={`${classes.thumbnail} ${classes.hiddenActionBarContainer}`}
        gap={10}
        justifyContent='flex-end'
        alignItems='flex-end'
        style={{ position: 'relative' }}>
        <FlexBox direction='row' gap={10} position='absolute' style={{ zIndex: 1, padding: '8px 8px', alignSelf: 'flex-start' }} className={classes.actionButtonContainer} >
          <RainbowButton variant='secondary-outline' title='add to cart' onClick={() => props.addToCart?.(props.item)} style={{ width: 35, height: 35 }}><ShoppingCart style={{ fontSize: 18 }} /></RainbowButton>
        </FlexBox>
        <Link to={`/listing/${getUrl(props.item)}`} onClick={() => props.onSelected?.(props.item)}>
          <Img className={props.imageProps?.className} src={frontImage} loading='lazy' alt={props.item.title ?? props.item.name} fallback='https://dev.midnight-merchant.com/userImages/thumbnails/noPhoto.svg'></Img>
        </Link>
      </FlexBox>
      <div className={classes.content}>
        {listingDetails}
      </div>
    </div >
  );
}

export function useFieldSwitcher<T1, T2, TReturnType = any | T1 | T2>(field1: T1, field2: T2, condition: boolean,
  callback: (value: T1 | T2) => TReturnType): TReturnType {
  if (condition)
    return callback(field1);
  else
    return callback(field2);
}