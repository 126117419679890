import React from 'react';
import classes from '../styles/mandatedLinks.module.scss';

export function QuickLinks() {
  return (
    <footer className={classes.footer}>
      <div className={classes.footermain}>
        <a aria-label='link' href={`${window.location.origin}`}>Marketplace</a>
        <a aria-label='link' href={`${window.location.origin}/create-listing/listing-type`}>Create a listing</a>
        <a aria-label='link' href={'https://discord.com/channels/946943217969037332/1002481157927096340'}>Contact us</a>
        <a aria-label='link' href={`${window.location.origin}/terms`}>Terms and conditions</a>
        <a aria-label='link' href={`${window.location.origin}/privacy`}>Privacy</a>
        <a aria-label='link' href={`${window.location.origin}/company`}>
          Okonomi One © {new Date().getFullYear()} - Midnight Merchant © {new Date().getFullYear()}
        </a>
        <a aria-label='link' href={`${window.location.origin}/sites`}>Sites</a>
      </div>
    </footer>
  );
}
