import DeleteOutline from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import { CreateOrderActions, CreateOrderData, OnApproveActions, OnApproveData } from '@paypal/paypal-js';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Img } from 'src/components/Image';
import { Main, PageType } from 'src/components/Main';
import { PayButtons } from 'src/components/paypal/PayButtons';
import { RainbowButton } from 'src/components/shared/Button';
import { StyledInformation } from 'src/components/shared/Instruction';
import { LoadingWidget } from 'src/components/shared/Loading';
import { FlexBox, StyledChip, StyledDivider, StyledHeading, StyledList, StyledListItem, StyledText } from 'src/components/styled/StyledComponents';
import { StyledUsername } from 'src/components/UserName';
import usePaymentService from 'src/hooks/paymentService/usePaymentService';
import { useSession } from 'src/hooks/session/useSession';
import { getZones } from 'src/locales/useLocales';
import { Breakdown, CartItem } from 'src/models/payments/Breakdown';
import { ICheckoutResponse } from 'src/services/payment-service/payment-service';
import { toCurrency } from 'src/utils/utilities';
import { ReactComponent as Rectangle } from '../../assets/svg/Rectangle.svg';
import { routeConfig } from '../../routes/routeConfig';

export function Checkout() {
    const { checkout, approveOrder } = usePaymentService();
    const { cart, removeItem, clearCart } = useSession();
    const [checkoutData, setCheckoutData] = React.useState<ICheckoutResponse | undefined>();
    const [errors, setErrors] = React.useState<any[] | undefined>();
    const [merchantIds, setMerchantIds] = React.useState<string[]>([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onApproveHandler = async (data: OnApproveData, actions: OnApproveActions): Promise<void> => {
        const response = await approveOrder(data.orderID, cart.map(i => i.listingId));
        if (response.err) {
            // eslint-disable-next-line no-console
            console.warn('Error approving order', response.err);
            navigate(routeConfig.checkout.failed);
            return;
        }

        clearCart();
        navigate(routeConfig.checkout.success);

    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const createPaypalOrderHandler = async (data: CreateOrderData, actions: CreateOrderActions) => {
        return checkoutData?.orderId ?? '';
    };


    React.useEffect(() => {

        if (cart && cart.length === 0) return;

        const listings = cart.map(l => ({
            listingId: l.listingId,
            quantity: l.quantity,
        }));

        (async function locale() {
            setLoading(true);
            const zone = getZones();
            const d = await checkout(listings, zone?.name).finally(() => setLoading(false));
            setCheckoutData(d?.data);
            setErrors(d?.errors);
            setMerchantIds(d?.data.merchants.map(m => m.merchantId ?? '') ?? []);

        })();
    }, [cart]);

    if (loading)
        return <Main pageType={PageType.Checkout}>
            <FlexBox justifyContent='center' alignItems='center' style={{ height: 400 }}>
                <LoadingWidget isLoading={loading} />
            </FlexBox>
        </Main>;

    if (cart.length === 0 || (checkoutData?.cartBreakdown.sellers && checkoutData.cartBreakdown.sellers.length === 0)) {
        return <Main pageType={PageType.Checkout}>
            <FlexBox justifyContent='center'>
                <FlexBox style={{ paddingTop: 60 }} justifyContent='center' rowGap={16} direction='column' alignItems='center'>
                    <StyledHeading size='xLarge'>Your Cart Is Empty</StyledHeading>
                    <StyledDivider style={{ width: 490 }} />
                    <StyledText size='xLarge'>Head back to the marketplace to find a great deal!</StyledText>
                    <RainbowButton onClick={() => navigate('/')} style={{ marginTop: 16, padding: 0, height: 45, width: 376 }}>Marketplace</RainbowButton>
                </FlexBox>
            </FlexBox>
        </Main>;
    }

    const sellerName = (sellerGroup: Breakdown) => {
        if (!sellerGroup.sellerName) {
            return atob(sellerGroup.sellerId);
        }
        return sellerGroup.sellerName;
    };


    return <Main pageType={PageType.Checkout}>
        <FlexBox style={{ padding: 20 }} justifyContent='center' columnGap={16} reverseOnMobile>
            <FlexBox justifyContent='flex-start' direction='column' style={{ flex: 1, maxWidth: 700 }}>
                {(errors?.length ?? 0) > 0 &&
                    <FlexBox style={{ backgroundColor: '#252d32', color: 'tomato', fontWeight: 400, height: 'fit-content', minWidth: 360, padding: 16, marginBottom: 16 }} direction='column'>
                        <StyledHeading size='xLarge' style={{ color: 'black', fontSize: 24 }}>Item/s Problem</StyledHeading>
                        <StyledDivider />
                        <FlexBox direction="column" style={{ padding: '8px 0px' }}>
                            <StyledHeading>Reason</StyledHeading>
                            <StyledList>
                                <StyledListItem>
                                    <StyledInformation color='white' size='small'>
                                        Unfortunately an item/s in your cart cannot be processed right now.
                                        - {errors?.map((e, index) => (<strong key={index}> {e.name}</strong>))}
                                    </StyledInformation>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledInformation color='white' size='small'>
                                        We have notified the seller.
                                    </StyledInformation>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledInformation color='white' size='small' >
                                        Your other items are unaffected and your final amount will be adjusted accordingly during PayPal checkout.
                                    </StyledInformation>
                                </StyledListItem>
                            </StyledList>

                        </FlexBox>
                    </FlexBox>
                }
                <StyledHeading>Your Cart</StyledHeading>
                <StyledDivider />
                <FlexBox direction='column'>
                    {checkoutData?.cartBreakdown.sellers?.map(sellerGroup => (

                        <FlexBox key={sellerGroup.sellerId} direction='column' >
                            <FlexBox direction='row' style={{ columnGap: 8 }}>
                                <StyledUsername
                                    avatar={sellerGroup.sellerAvatar}
                                    name={sellerName(sellerGroup)} style={{ width: 110 }} hideRating />
                                <BundledNotBundled bundled={sellerGroup.bundled}
                                    threshold={sellerGroup.shippingPreferences.thresholdAmount} postageFee={sellerGroup.shippingPreferences.postageAmountFee} />
                            </FlexBox>
                            <StyledDivider />
                            <FlexBox direction='column'>
                                {sellerGroup.items.map(item => (
                                    <CheckoutItem key={item.id} item={item} removeItem={removeItem} isBundled={sellerGroup.bundled && !item.excludeFromBundleShipping} />
                                ))}
                            </FlexBox>
                            <FlexBox rowGap={8} direction='column' style={{ width: 'calc(100% - 51px)' }}>
                                <FlexBox justifyContent='space-between' style={{ padding: '0 8px' }}>
                                    <StyledText size='small'>Items ({sellerGroup.items.length})</StyledText>
                                    <StyledText size='small'>{toCurrency(sellerGroup.items.reduce((a, c) => c.price + a, 0))}</StyledText>
                                </FlexBox>
                                <FlexBox justifyContent='space-between' style={{ padding: '0 8px' }}>
                                    <StyledText size='small'>Shipping Cost</StyledText>
                                    <StyledText size='small' color='#ffffff80'>Bundled Shipping = {sellerGroup.bundled ? toCurrency(sellerGroup.shippingTotal) : 'N/A'}</StyledText>
                                </FlexBox>
                                <FlexBox justifyContent='space-between' style={{ padding: '0 8px' }}>
                                    <StyledText></StyledText>
                                    <StyledText size='small'>Shipping Total = {toCurrency(sellerGroup.shippingTotal)}</StyledText>
                                </FlexBox>
                            </FlexBox>

                        </FlexBox>
                    ))}
                </FlexBox>
            </FlexBox>
            <FlexBox direction='column'>
                <FlexBox style={{ backgroundColor: '#D9D9D9', color: '#161D22', fontWeight: 400, height: 'fit-content', padding: 16, minWidth: 320 }} direction='column'>
                    <StyledHeading size='xLarge' style={{ color: 'black', fontSize: 24 }}>Cost and Fees</StyledHeading>
                    <StyledDivider />
                    <FlexBox direction='column' style={{ columnGap: 8 }} >
                        <FlexBox justifyContent='space-between'>
                            <StyledText size='small'>Subtotal</StyledText>
                            <StyledText size='small'>{toCurrency(checkoutData?.cartBreakdown.sellers?.reduce((a, c) => c.items.reduce((a, c) => c.price + a, 0) + a, 0))}</StyledText>
                        </FlexBox>
                        <FlexBox justifyContent='space-between'>
                            <StyledText size='small'>Shipping cost total</StyledText>
                            <StyledText size='small'>{toCurrency(checkoutData?.cartBreakdown.shippingTotal)}</StyledText>
                        </FlexBox>
                        <FlexBox justifyContent='space-between'>
                            <StyledText size='small' >GST</StyledText>
                            <StyledText size='small' color="#00000080">Inclusive</StyledText>
                        </FlexBox>
                        <FlexBox justifyContent='space-between'>
                            <StyledText size='small'>Midnight Merchant Fee</StyledText>
                            <StyledText size='small'>N/A</StyledText>
                        </FlexBox>
                        <FlexBox justifyContent='space-between'>
                            <StyledText size='small'>Total</StyledText>
                            <StyledText size='small'>{toCurrency((checkoutData?.cartBreakdown.shippingTotal ?? 0) + (checkoutData?.cartBreakdown.sellers?.reduce((a, c) => c.items.reduce((a, c) => c.price + a, 0) + a, 0) ?? 0))}</StyledText>
                        </FlexBox>
                        <StyledDivider />
                        <FlexBox justifyContent='space-between' alignItems='center' style={{ height: 24 }}>
                            <StyledHeading size='small'>Total:</StyledHeading>
                            <FlexBox columnGap={4} alignItems='flex-end' justifyContent='center'  >
                                <StyledText style={{ height: 29 }} size='xLarge'>{toCurrency((checkoutData?.cartBreakdown.shippingTotal ?? 0) + (checkoutData?.cartBreakdown.sellers?.reduce((a, c) => c.items.reduce((a, c) => c.price + a, 0) + a, 0) ?? 0))}</StyledText>
                                <StyledText size='xSmall' >AUD</StyledText>
                            </FlexBox>
                        </FlexBox>
                        <FlexBox justifyContent='center' style={{ marginTop: 16 }}>
                            <PayButtons errorText={'Item currently unavailable'} merchantIds={merchantIds} createOrderHandler={createPaypalOrderHandler} onApproveHandler={onApproveHandler} isLoading={loading} />
                        </FlexBox>
                    </FlexBox>
                    <StyledText size='xSmall'>* Total amount is an estimate please see the final PayPal payment amount</StyledText>
                </FlexBox>
                <FlexBox direction="column" style={{ padding: '8px 0px' }}>
                    <StyledHeading>Delivery Address</StyledHeading>

                    <StyledInformation size='small' italic>
                        Your shipping address is handled by your PayPal account. Please ensure your PayPal shipping address is correct.
                    </StyledInformation>

                    <StyledInformation size="small" italic>
                        *International currency conversion fee will be charged via chosen payment method. <br />
                        **This fee on average sits between 3% to 4% of total value
                    </StyledInformation>

                    <StyledInformation size="small" italic> </StyledInformation>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    </Main >;

}


export function CheckoutItem(props: { item: CartItem; removeItem: (id: string) => void; isBundled: boolean; }) {
    return <FlexBox style={{ padding: '0px 0px 8px 8px' }} justifyContent='center'>
        <Img src={props.item.imageUrl} fallback='https://images.pokemontcg.io/swsh4/SWSH04-044.png' style={{ width: 65, height: 75, objectFit: 'cover' }} />
        <FlexBox style={{ width: '100%', maxWidth: 800, backgroundColor: '#161d22', padding: 8, borderRadius: 8 }} justifyContent='space-between' direction='column' >
            <FlexBox direction='row' style={{ columnGap: 8 }} justifyContent='space-between'>
                <Link to={`/listing/${props.item?.id}/product`} style={{ textDecoration: 'none' }}><StyledHeading>{props.item.title}</StyledHeading></Link>
                <FlexBox columnGap={8} alignItems='flex-end'>
                    <StyledText size='large'>{toCurrency(props.item.price)}</StyledText>
                    <StyledText style={{ paddingBottom: 5, fontSize: 9 }}>AUD</StyledText>
                </FlexBox>
            </FlexBox>
            <StyledDivider margin={2} />
            <FlexBox direction='row' style={{ columnGap: 8 }} justifyContent='space-between'>
                <FlexBox columnGap={16} alignItems='center'>
                    <Rectangle style={{ stroke: 'white', width: 15 }} /> <StyledText>{props.item.subTitle}</StyledText>
                </FlexBox>
                <FlexBox direction='column'>
                    <FlexBox columnGap={8} alignItems='flex-end'>
                        <StyledText size='xSmall'>Shipping</StyledText>
                        <StyledText size='xSmall'>{toCurrency(props.item.shippingPrice)}</StyledText>
                    </FlexBox>
                    {props.item.isFreeShipping
                        ? <StyledText size='xSmall' color='#6bae5f' textAlign='right'>Free Shipping</StyledText>
                        : !props.isBundled
                            ? <StyledText size='xSmall' color='#EE8F00' textAlign='right'>Not Bundled</StyledText>
                            : <StyledText size='xSmall' color='#00B1D8' textAlign='right'>Bundled</StyledText>
                    }
                </FlexBox>
            </FlexBox >
        </FlexBox>
        <IconButton style={{ color: 'white' }} onClick={() => props.removeItem(props.item.id)}>
            <FlexBox direction='column' justifyContent='center' alignItems='center' >
                <DeleteOutline style={{ fontSize: 35 }} />
            </FlexBox>
        </IconButton>
    </FlexBox >;
}


export function BundledNotBundled(props: { bundled: boolean; threshold?: number, postageFee?: number; nonBundledShippingCost?: number; isFreeShipping?: boolean; }) {

    if (props.isFreeShipping)
        return <FlexBox style={{ borderTopRightRadius: 17, borderBottomRightRadius: 17 }} stackOnMobile>
            <StyledChip color='#6bae5f' style={{ minWidth: 150, maxWidth: '100%', justifyContent: 'center' }} borderColor='#6bae5f'>Free Shipping</StyledChip>
        </FlexBox>;


    if (props.bundled)
        return <>
            <FlexBox style={{ width: 335, backgroundColor: '#2F3D48', border: '1px solid #2F3D48', borderTopRightRadius: 17, borderBottomRightRadius: 17 }} stackOnMobile>
                <StyledChip backgroundColor="#1A2329" color='#00B1D8' style={{ minWidth: 150, maxWidth: '100%', justifyContent: 'center' }}>Bundled Postage</StyledChip>
                <StyledText size='small' textAlign='center' color='#00B1D8' style={{ margin: 'auto' }}>
                    Up to {props.threshold} items for {toCurrency(+(props.postageFee || 0))}
                </StyledText>
            </FlexBox>
        </>;

    if (props.nonBundledShippingCost)
        return <FlexBox style={{ backgroundColor: '#ee8f0010', border: '1px solid #2F3D48', borderTopRightRadius: 17, borderBottomRightRadius: 17 }} stackOnMobile>
            <StyledChip backgroundColor="#1A2329" color='#ee8f00' style={{ minWidth: 150, maxWidth: '100%', justifyContent: 'center', borderColor: '#ee8f00' }}>No Bundled Postage</StyledChip>
            <StyledText size='small' textAlign='center' color='#ee8f00' style={{ margin: 'auto', padding: '0 16px' }}>
                +{toCurrency(+(props.nonBundledShippingCost || 0))} Shipping
            </StyledText>
        </FlexBox>;

    return <StyledChip backgroundColor="#1A2329" color='#ee8f00' borderColor='#ee8f00'>No bundled postage</StyledChip>;



}