import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PostageSettingsModel } from 'src/features/user/MyPostalSettings';
import { useSnackbarSend } from 'src/features/snackBarContext';
import { TransientCartItem } from 'src/models/payments/Breakdown';



interface CartContextProps {
    cart: TransientCartItem[];
    addItem: (item: TransientCartItem, shippingPreferences: PostageSettingsModel) => void;
    updateItemQuantity: (id: string, quantity: number) => void;
    removeItem: (id: string) => void;
    clearCart: () => void;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

export const CartProvider: React.FC<{ children: ReactNode; }> = ({ children }) => {
    const { user } = useAuth0();
    const [cart, setCart] = useState<TransientCartItem[]>([]);
    const { enqueue } = useSnackbarSend();
    useEffect(() => {
        // if (user) {
        const storedCart = getCookie(`cart_${user?.sub ?? 'guest'}`);
        if (storedCart) {
            setCart(JSON.parse(storedCart));
        }
        // }
    }, [user]);

    useEffect(() => {
        if (cart.length !== 0) {
            setCookie(`cart_${user?.sub ?? 'guest'}`, JSON.stringify(cart), 7); // Set cookie to expire in 7 days
        } else {
            removeCookie(`cart_${user?.sub ?? 'guest'}`);
        }

    }, [cart, user]);

    const addItem = (item: TransientCartItem, shippingPreferences: PostageSettingsModel) => {
        setCart(prevCart => {
            const existingItem = prevCart.find(cartItem => cartItem.listingId === item.listingId);
            if (existingItem) {
                enqueue?.('warning', `${item.title ?? 'Item -'} is already in the cart`);
                return prevCart; // Do not add the item again
            }
            enqueue?.('success', `${item.title ?? 'Item -'} added to cart`);
            return [...prevCart, { ...item, title: item.title, quantity: item.quantity, shippingPreferences }];
        });
    };

    const updateItemQuantity = (id: string, quantity: number) => {
        setCart(prevCart =>
            prevCart.map(seller => seller.listingId === id ? { ...seller, quantity } : seller));
    };

    const removeItem = (id: string) => {
        setCart(prevCart => prevCart.filter(seller => seller.listingId !== id));
    };

    const clearCart = () => {
        setCart([]);
    };

    return (
        <CartContext.Provider value={{ cart, addItem, updateItemQuantity, removeItem, clearCart }}>
            {children}
        </CartContext.Provider>
    );
};

export const useSession = (): CartContextProps => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useSession must be used within a CartProvider');
    }
    return context;
};

// Helper functions to work with cookies
const setCookie = (name: string, value: string, days: number) => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=/';
};

const getCookie = (name: string) => {
    return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=');
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, '');
};

const removeCookie = (name: string) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};