import React, { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { ReactComponent as CloseIcon } from '../../assets/svg/exit.svg';
import classes from './snackbar.module.scss';
import Slide from '@mui/material/Slide';
import { createPortal } from 'react-dom';

// this needs a complete overhall, this should actually be the snackbar provider but the snackbar provider calls this hook
export function useSnackbar() {
  const [opn, setOpen] = React.useState(false);
  const [variant, setVariant] = React.useState<'success' | 'error' | 'warning' | 'info'>('info');
  const messageCollector = React.useRef<{ message: string, variant: typeof variant; }[]>([]);
  const [messageTime, setMessageTime] = React.useState(0);
  const [message, setMessage] = React.useState<{ message: string, variant: typeof variant; }[] | undefined>();
  const [timer, setTimer] = React.useState<NodeJS.Timeout>();



  useEffect(() => {
    if (messageCollector.current.length === 0) return;

    if (timer) clearTimeout(timer);

    const handle = setTimeout(() => {
      setMessage(messageCollector.current);
      setOpen(true);
      setVariant(variant);
      messageCollector.current = [];

      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }, 1000);

    setTimer(handle);
  }, [messageCollector.current, messageTime]);

  // useEffect(() => {
  //   console.log('message changed', message);
  //   messageCollector.current = [];
  // }, [message]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const TransitionDown = (props: any) => {
    return <Slide {...props} direction="down" />;
  };

  return {
    snackbar: opn ? createPortal(
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={opn}
        autoHideDuration={56000}
        onClose={handleClose}
        TransitionComponent={TransitionDown}
      >
        <div className={classes.snackbar}>
          <div className={classes.close} onClick={handleClose}><CloseIcon /></div>
          {message?.map((item, index) =>
            <div key={index} className={`${classes.messages} ${classes[item.variant]}`}>
              <div className={`${classes.iconPanel} ${classes[item.variant]}`}></div>
              <div className={classes.message}>{item?.message}</div>
            </div>
          )}
          {// only show this message when there is an error
            variant === 'error' &&
            <div className={classes.callForHelp}>
              While we have measure in place to try and detect this sort of thing we would be eternally grateful if you could let us know via our <a aria-label='link' href={process.env.REACT_APP_DISCORD_URL}>Discord Server</a>.
            </div>
          }
        </div>
      </Snackbar >, document.getElementById('snackbar-portal') ?? {} as HTMLElement
    )
      : null,
    enqueue: (variant: 'success' | 'error' | 'warning' | 'info', message: string) => {

      setOpen(false);
      if (!messageCollector.current.find((item) => item?.message === message)) {
        messageCollector.current = [...messageCollector.current, { message, variant }];
        setMessageTime(Date.now());
      }


    },
  };
}
