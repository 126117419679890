const config = {
  buyer: {
    getPurchaseHistoryUrl: process.env.REACT_APP_LISTINGS_BY_BUYER || '',
    setUserRating: process.env.REACT_APP_SET_USER_RATING || '',
  },
  user: {
    getPostagePreferences: process.env.REACT_APP_GET_POSTAGE_PREFERENCES || '',
    updatePostagePreferences: process.env.REACT_APP_UPDATE_POSTAGE_PREFERENCES || '',
    resendVerificationEmail: process.env.REACT_APP_USER_RESEND_VERIFICATION_EMAIL || '',
  },
  seller: {
    getSellerListingsUrl: process.env.REACT_APP_LISTINGS_BY_MERCHANT || '',
    getSellerSoldListingsUrl: process.env.REACT_APP_SOLD_LISTINGS_BY_MERCHANT || '',
    updateMidUrl: process.env.REACT_APP_USER_URL || '',
  },
  listing: {
    setTrackingNumberUrl: process.env.REACT_APP_SET_TRACKING_NUMBER || '',
    getListingUrl: process.env.REACT_APP_GET_LISTING || '',
    deleteListingUrl: process.env.REACT_APP_DELETE_LISTING || '',
    reportUserUrl: process.env.REACT_APP_REPORT || '',
    reportListingUrl: process.env.REACT_APP_REPORT || '',
    analytics: {
      snapshot: `${process.env.REACT_APP_MARKET_ANALYTICS}/snapshot`,
      summary: `${process.env.REACT_APP_MARKET_ANALYTICS_SUMMARY}/summary`,
    },
  },
  cards: {
    searchCardsUrl: process.env.REACT_APP_SEARCH_CARDS_URL || '',
    cardAutocompleteURL: process.env.REACT_APP_CARD_AUTOCOMPLETE || '',
  },
  createListing: {
    createListingUrl: process.env.REACT_APP_CREATE_LISTING_URL || '',
  },
  searchService: {
    getFiltersURL: process.env.REACT_APP_LISTING_FILTERS || '',
    getListingCategoryCountURL: process.env.REACT_APP_LISTING_FILTERS_COUNT || '',
    getListingCountURL: process.env.REACT_APP_LISTING_COUNT || '',
    listingAutocompleteURL: process.env.REACT_APP_LISTING_AUTOCOMPLETE || '',
    searchListingsUrl: process.env.REACT_APP_SEARCH_ADVERT_URL || '',
  },
  payments: {
    checkout: process.env.REACT_APP_PAYPAL_CHECKOUT || ''
  },
  other: {
    getValidationTokenURL: process.env.REACT_APP_ANTIFORGERY_TOKEN || '',
    sendMessageUrl: process.env.REACT_APP_SET_USER_MESSAGING || '',
  },
};

export const { other, cards, searchService, listing, createListing, seller, buyer, user, payments } = config;
