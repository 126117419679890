import React, { useCallback, useEffect } from 'react';
import classes from '../styles/rainbowChips.module.scss';
import { IRainbowChipsProps } from '../features/createListing/iRainbowChipsProps';
import { RainbowButton } from './shared/Button';
import { IOption } from './shared/AutoComplete';
import { useRowHighlighter } from '../hooks/useRowHighlighter';

export function RainbowChips(props: IRainbowChipsProps) {
    const [selectedChip, setSelectedChip] = React.useState<IOption | undefined>(props.selected);

    const variant = useCallback((id: string) => {
        return selectedChip?.id === id ? 'outlined' : 'no-outline';
    }, [selectedChip]);

    const onSelected = (i: IOption) => {
        setSelectedChip(i);
        props.onSelected?.(i);
    };

    return (
        <>
            <RowHighlighter
                highLightClassName={classes.highlight}
                className={`${classes.chipsCollection} ${props.className || ''}`}
                onSelected={(i) => setSelectedChip(props.options?.[i])}>
                {(props.options || []).map((i, index) =>
                    <RainbowButton
                        key={index}
                        className={`${classes.chip}`}
                        variant={variant(i.id)}
                        onClick={() => onSelected(i)}>
                        {i.label}
                        {props.showCount && i.count > 0 && <span className={classes.countBadge}>{i.count}</span>}
                    </RainbowButton>)
                }
            </RowHighlighter>
        </>
    );
}

export interface IRowHighlighterProps {
    children: JSX.Element[],
    className?: string,
    highLightClassName: string,
    onSelected?: (i: number) => void,
    onIndexChanged?: (i: number) => void;
}

export function RowHighlighter(props: IRowHighlighterProps) {

    const { getHighlightClass,
        highlightedIndex,
        onRowKeyDown,
        setHighlightedIndex
    } = useRowHighlighter(props.children.length || 0, props.highLightClassName, props.onSelected);

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => onRowKeyDown(event);

    useEffect(() => {
        props.onIndexChanged?.(highlightedIndex);
    }, [highlightedIndex]);

    return <div onKeyDown={onKeyDown} className={props.className}>
        {
            props.children
                .map((i, index) => {
                    return {
                        ...i, props: {
                            ...i.props, className: getHighlightClass(index) + ' ' + (i.props.className || ''),
                            onClick: () => {
                                setHighlightedIndex(index);
                                i.props.onClick && i.props.onClick();
                            }
                        } as any
                    };
                })
        }
    </div >;
}