import React from 'react';
import { ReactComponent as RatingStar } from '../assets/svg/Star.svg';
import { ReactComponent as BlankAvatar } from '../assets/svg/avatar.svg';
import classes from '../styles/usernamestyle.module.scss';
import PersonAddAlt1 from '@mui/icons-material/PersonAddAlt1';
import { useMedia } from 'react-use';
export function UserName(props: { avatar?: string; name: string; rating?: number; className?: string; showUserBadge?: boolean; }) {

    return <div className={`${classes.userName} ${props.className ?? ''}`}>
        {props.avatar
            ? <span className={classes.profilePic}>
                <img
                    src={props.avatar}
                    referrerPolicy="no-referrer"
                    alt="."
                    className={classes.profilePic}
                ></img>
            </span>
            : <span className={classes.profilePic}>
                <BlankAvatar />
            </span>
        }
        <span className={classes.user}> {props.name} </span>
        {props.showUserBadge && <Rating rating={props.rating} />}
    </div>;
}

function Rating(props: { rating?: number; }) {
    const isMobile = useMedia('(max-width: 800px)');


    const ratedStyle = props.rating ? classes.rated : classes.unrated;

    return <div className={classes.rating}>
        {props.rating
            ? <RatingStar className={`${classes.ratingstar} ${ratedStyle}`} />
            : <span>
                {
                    isMobile
                        ? <PersonAddAlt1 />
                        : <PersonAddAlt1 />
                }
            </span>}
    </div>;
}