
export const routeConfig = {
  login: '/login',
  insights: {
    path: '/insights'
  },
  checkout: {
    root: '/checkout',
    success: '/checkout/success',
    failed: '/checkout/cancel',
  },
  faq: {
    path: '/faq',
    sellerInformation: 'sellerInformation',
    buyerInformation: 'buyerInformation',
    cardKnowledge: 'cardKnowledge',
    priceData: 'priceData',
    discord: 'discord',
    siteRules: 'siteRules',
    disputes: 'disputes',
  },
  user: {
    path: '/user',
    profile: '/user/profile',
    purchaseHistory: '/user/purchase-history',
    sellingHistory: '/user/sale-history',
    sellerActions: '/user/seller-action',
    SellerHub: '/user/seller-hub',
    myListings: '/user/my-listings',
    myAccount: '/user/my-account',
    feedback: '/user/feedback',
    messages: '/user/messages',
    onboardingCallback: '/user/onboarded',
    postalSettings: '/user/postal-settings',
  },
  searchResults: {
    path: '/',
    seriesId: '/series/:series',
    seriesIdSetId: '/series/:series/set/:set',
    seriesNameSetNameCardName: '/series/:series/set/:set/name/:name',
    filters: '/filters',
    filter: '/filters/:filter',
  },
  createListing: {
    path: '/create-listing',
    faq: '/create-listing/faq',
    search: '/create-listing/search',
    title: '/create-listing/title',
    listingType: '/create-listing/listing-type',
    singleCard: '/create-listing/single-card',
    mixedBundle: '/create-listing/mixed-bundle',
    sealedSingle: '/create-listing/sealed-single',
    condition: '/create-listing/condition',
    selfAssessment: '/create-listing/self-assessment',
    gradingCompany: '/create-listing/grading-company',
    imageUpload: '/create-listing/upload-images',
    description: '/create-listing/description',
    shipping: '/create-listing/shipping',
    transaction: '/create-listing/transaction',
    registerPaymentService: '/create-listing/register-payment-service',
    preview: '/create-listing/preview',
    complete: '/listing-complete/:id/:title/:imageUrl',
  },
  createListingComplete: 'listing-complete',
  createListingFailed: 'listing-failed',
  terms: '/terms',
  privacy: '/privacy',
  companys: '/company',
  contactUs: '/contact-us',
  listingByIdSeriesSetName2: '/listing2/:id/:series/:set/:name/',
  listingByIdSeriesSetName: '/listing/:id/:series/:set/:name/',
  listing: {
    root: '/listing',
    listingByIdSeriesSetName: '/listing/:id/:series/:set/:name/',
    listingById: '/listing/:id/product',
    buyNow: '/listing/buyNow/:listingId',
    purchaseComplete: '/listing/purchase-complete',
    purchaseFailed: '/listing/purchase-failed',
    notFound: '/listing/not-found',
  },
  advertsById: '/adverts/:advertid',
  home: '/',
  locales: {
    locales: '/locales/',
    en: 'en/en/en.json',
    de: 'de/de/de.json',
  },
  all: '*',
};


export type CreateListingRoutes = keyof typeof routeConfig.createListing;
export type UserRoutes = keyof typeof routeConfig.user;
export type ListingRoutes = keyof typeof routeConfig.listing;
export type SearchResultsRoutes = keyof typeof routeConfig.searchResults;
export type RouteConfig = typeof routeConfig;

export type AllRouteConfigs = CreateListingRoutes | UserRoutes | ListingRoutes | SearchResultsRoutes;

export type RouteConfigType = typeof routeConfig;

