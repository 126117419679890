import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import { App } from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import store from './stores/store';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import createTheme from '@mui/material/styles/createTheme';
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { initializeGlobals } from './CustomConsole';
import './services/search-service/searchUtilities';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './loggin/aILog';
import { SnackbarProvider } from './features/snackBarContext';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { SEOProvider } from './HelmetSEO';
import { CartProvider } from './hooks/session/useSession';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
initializeGlobals();

const theme = createTheme({
  palette: {
    primary: {
      main: '#1a2329',
    },
    secondary: {
      main: '#98FFF9',
    },
  },
});

//initialise language hook /service
i18n
  // .use(LanguageDetector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS: 'en',
    backend: {
      backends: [
        backend, // if a namespace can't be loaded via normal http-backend loadPath, then the inMemoryLocalBackend will try to return the correct resources
        resourcesToBackend((language, namespace, callback) => {
          import(`./locales/${language}/${namespace}.json`)
            .then(({ default: resources }) => {
              callback(null, resources);
            })
            .catch((error) => {
              callback(error, null);
            });
        }),
      ],
      backendOptions: [
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
    compatibilityJSON: 'v3',
    ns: ['en'],
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
  });

const container = document.getElementById('root') as Element;

const root = ReactDOMClient.createRoot(container);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri: process.env.REACT_APP_GRAPHQL_URL,
});


const auth0Props: Auth0ProviderOptions = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  useRefreshTokensFallback: true,
  authorizationParams: {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
    scope: process.env.REACT_APP_AUTH0_SCOPE || '',
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    redirect_uri: window.location.origin,
  }
};


const index = <React.StrictMode>
  <AppInsightsContext.Provider value={reactPlugin}>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Auth0ProviderWithRedirectCallback {...auth0Props}>
            <SnackbarProvider>
              <SEOProvider position={11}>
                <ApolloProvider client={apolloClient}>
                  <CartProvider>
                    <App />
                  </CartProvider>
                </ApolloProvider>
              </SEOProvider>
            </SnackbarProvider>
          </Auth0ProviderWithRedirectCallback>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </AppInsightsContext.Provider>
</React.StrictMode >;


root.render(index);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// eslint-disable-next-line no-console
reportWebVitals(() => {
  //no op
});

function Auth0ProviderWithRedirectCallback(props: Auth0ProviderOptions) {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: any) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {props.children}
    </Auth0Provider>
  );
}


