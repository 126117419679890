import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { HeaderBar } from '../../components/HeaderBar';
import { getUserMenuOptions } from '../../views/UserPage';
import { useNavigate } from 'react-router-dom';
import { useAuthHook } from 'src/shared/hooks/useAuthHook';
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
        '& .MuiPaper-root': {
            backgroundColor: '#252d32',
            color: 'white'
        },
        '& .MuiListItemIcon-root,& .MuiSvgIcon-root': {
            fill: '#FFFFFF40'
        }


    }),
);

export default function MiniDrawer(props: any) {
    const { logout } = useAuthHook();
    const [open, setOpen] = React.useState(props.open);
    const navigate = useNavigate();
    const toggleDrawer = () => {
        setOpen(!open);
        props.onToggle(!open);
    };

    React.useEffect(() => {
        if (props.open !== open)
            setOpen(props.open);

    }, [props.open]);

    const OpenClose = () => {
        const Icon = open ? ChevronLeftIcon : ChevronRightIcon;
        return <Icon style={{ fill: 'white' }} />;
    };




    return (
        <Box sx={{ display: 'flex', position: 'absolute' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <HeaderBar />
            </AppBar>
            <Drawer variant={open ? 'permanent' : 'temporary'} open={open} anchor='left'>
                <DrawerHeader
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: open ? 'flex-end' : 'center',
                        height: '100%'
                    }}
                >
                    <IconButton onClick={toggleDrawer}>
                        <OpenClose />
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {getUserMenuOptions(['side-bar']).map((option, index) =>
                        <ListItem key={index} disablePadding>
                            <ListItemButton title={option.value} onClick={() => {
                                if (option.value === 'logout')
                                    logout();
                                else
                                    navigate(option.value);

                                toggleDrawer();
                            }}>
                                <ListItemIcon>
                                    {option.icon}
                                </ListItemIcon>
                                <ListItemText primary={option.label} />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
                <Divider />

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: open ? 'flex-end' : 'center',
                    height: '100%'
                }}>
                    <Divider />
                    <IconButton onClick={toggleDrawer}>
                        <OpenClose />
                    </IconButton>
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {props.children}
            </Box>
        </Box>
    );
}
