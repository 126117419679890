import React from 'react';
import styles from '../styles/App.module.scss';
import { Logo } from './Logo';
import classes from '../styles/HeaderBar.module.scss';
import { ReactComponent as Marketplace } from '../assets/svg/bottombar/marketplace.svg';
import { ReactComponent as Insight } from '../assets/svg/bottombar/data.svg';
import { ReactComponent as PlusIcon } from '../assets/svg/create-listing.svg';
import { ReactComponent as News } from '../assets/svg/bottombar/News.svg';
import { ReactComponent as Cart } from '../assets/svg/bottombar/shopping-cart.svg';
import { ReactComponent as Discord } from '../assets/svg/bottombar/discord.svg';
import { Link, useNavigate } from 'react-router-dom';
import { ifDev } from '../CustomConsole';
import { printSession, StorageKey } from '../stores/Storage';
import { useAppDispatch, useAppSelector } from '../hook';
import { initialiseCurrentListing } from '../features';
import { routeConfig } from '../routes/routeConfig';
import NavMenu, { NavOption } from './NavMenu';
import { getUserMenuOptions } from '../views/UserPage';
import { useAuthHook } from '../shared/hooks/useAuthHook';
import { FlexBox, StyledDivider, StyledText } from './styled/StyledComponents';
import { useSession } from 'src/hooks/session/useSession';
import { useMedia } from 'react-use';
import { direction, useScrollDirection } from 'src/hooks/useScrollDirection';
import IconButton from '@mui/material/IconButton';
import { Search } from '@mui/icons-material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { ListingSearchBar } from './SearchBar/ListingSearchBar';
import { useMobileFooterBar } from 'src/views/useMobileFooterBar';
import { selectListingSearchParameters } from '../features/listingSearch/listingSearchSelectors';
import { InsightsSearchBar } from './SearchBar/InsightsSearchBar';
import { PageType } from './Main';
export const HeaderBarCenterLinks = () => {
  const DividerWithStyle = () => <StyledDivider orientation={'vertical'} thickness={1.5} style={{ marginBottom: 20 }} />;

  return <FlexBox style={{ columnGap: 16, marginTop: 8 }} justifyContent='center' alignItems='flex-end'>
    <Link to={routeConfig.createListing.listingType} aria-label='place advert'>
      <PlusIcon />
    </Link>
    <DividerWithStyle />
    <Link to={routeConfig.searchResults.path} aria-label='marketplace'>
      <Marketplace />
    </Link>
    <Link to={routeConfig.insights.path} aria-label='insights'>
      <Insight />
    </Link>
    <Link to={routeConfig.faq.path} aria-label='FAQ'>
      <News />
    </Link>
    <Link to={process.env.REACT_APP_DISCORD_URL ?? ''} target='_blank' rel="noreferrer" aria-label='Discord'>
      <Discord />
    </Link>
    <DividerWithStyle />

    <Link to={routeConfig.checkout.root} aria-label='Cart' style={{ position: 'relative' }}>
      < CartIcon />
    </Link>

  </FlexBox >;
};

export function CartIcon() {
  const session = useSession();

  return (
    <>
      {session.cart.length > 0 && <span className={classes.cartCount}>{session.cart.length}</span>}
      <Cart />
    </>
  );
}

interface IHeaderBarProps {
  type?: PageType;
}

export function HeaderBar(props: IHeaderBarProps) {

  const [autoHideSearchBar, setAutoHideSearhcBox] = React.useState<boolean>(true);
  const isMobile = useMedia('(max-width: 799px)');
  const searchResultRef = React.useRef(window);
  const [showMobileListingSearch, setShowMobileListingSearch] = React.useState<boolean>(false);
  const { showActionBar, setShowActionBar, } = useMobileFooterBar();

  useScrollDirection((e: direction) => {
    switch (e) {
      case 'SCROLL_DIRECTION_DOWN': {
        setAutoHideSearhcBox(false);
        break;
      }
      case 'SCROLL_DIRECTION_UP': {
        setAutoHideSearhcBox(true);
        break;
      }
    }
  }, searchResultRef);


  const selectSearchTerm = useAppSelector(selectListingSearchParameters);

  const showSlidingActionBar = () => {
    setShowActionBar(!showActionBar);
  };



  const MobileDefaultHeaderBar = () => (<>
    <div className={classes.title} tabIndex={-1}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <a aria-label='link' href="/" style={{ display: 'flex' }}><img src="/static/media/logo.59c3e8d1873c6bf23e932fcc2cf43e1b.svg" alt="logo" height="40" /></a>
        {props.type ?? 'Marketplace'}
      </span>
      <IconButton className={classes.searchButton} onClick={() => setShowMobileListingSearch(true)}>
        <Search style={{}} />
      </IconButton>
    </div>
  </>
  );

  const SearchCardsHeaderBar = () => (<>
    <div className={classes.staticContent}>
      <IconButton className={classes.back} onClick={() => setShowMobileListingSearch(false)}>
        <ChevronLeft />
        Insights
      </IconButton>
      <div className={classes.searchListingsTitle} tabIndex={-1}>
        Search Cards
      </div>
      <div style={{ width: 100, userSelect: 'none' }}></div>
    </div>
    <InsightsSearchBar
      value={selectSearchTerm?.autoCompleteSearchString}
      // onSelected={(value) => { console.log(value); }}
      className={`${styles.searchBar} `}

    />
  </>);

  const SearchListingsHeaderBar = () => (<>
    <div className={classes.staticContent}>
      <IconButton className={classes.back} onClick={() => setShowMobileListingSearch(false)}>
        <ChevronLeft />
        MarketPlace
      </IconButton>
      <div className={classes.searchListingsTitle} tabIndex={-1}>
        Search Listings
      </div>
      <div style={{ width: 100, userSelect: 'none' }}></div>
    </div>
    <ListingSearchBar
      showSlidingActionBar={showSlidingActionBar}
      value={selectSearchTerm.autoCompleteSearchString}
      className={`${styles.searchBar} `}
    />
  </>);


  const ActiveMobileHeader = React.useMemo(() => {
    if (props.type === 'Insights') {
      return isMobile && showMobileListingSearch ? <SearchCardsHeaderBar /> : <MobileDefaultHeaderBar />;
    } else {
      return isMobile && showMobileListingSearch ? <SearchListingsHeaderBar /> : <MobileDefaultHeaderBar />;
    }
  }, [isMobile, showMobileListingSearch]);



  if (isMobile)
    return <div className={`${classes.searchBarContainer} ${autoHideSearchBar === false ? classes.hideSearchBar : ''} ${classes.hideFullSearchBar}`}>
      <div className={classes.mobileSearchBar}>
        {ActiveMobileHeader}
      </div>
    </div>;

  return (<>
    <div className={classes.headerBar}>
      <Logo></Logo>
      <div className={classes.actions}>
        <HeaderBarCenterLinks />
      </div>
      <FlexBox style={{ columnGap: 32 }} justifyContent='center' alignItems='center'>
        <StyledText style={{ fontWeight: 'bold' }}>$ AUD</StyledText>
        <UserMenu className={classes.userName} />
      </FlexBox>
    </div >
  </>);
}


function UserMenu(props: { className?: string; }) {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuthHook();

  const navigate = useNavigate();

  const onUserMenuOptionSelected = (option: NavOption) => {
    if (option.value === 'logout') {
      logout();
    } else {
      navigate(option.value);
    }
  };

  return (
    <div className={props.className}>
      {isAuthenticated ? (
        <user-name>
          {user?.picture &&
            <FlexBox style={{ columnGap: 8 }} alignItems='center'>
              <img
                src={user && user.picture}
                referrerPolicy="no-referrer"
                alt="Profile"
                className={classes.profilePic}
              ></img>
            </FlexBox>}
          <span className={classes.name}>{user?.name}</span>
          <NavMenu
            options={getUserMenuOptions(['userName'])}
            onOptionSelected={onUserMenuOptionSelected}
          ></NavMenu>
        </user-name>
      ) : (
        <a
          onClick={() => {
            loginWithRedirect({
              authorizationParams: { redirectUri: window.location.origin },
            });
          }}
          style={{ cursor: 'pointer', color: '#b1b1b1' }}
        >
          sign-in
        </a>
      )}
    </div>
  );
}

export function Environment() {
  return ifDev(() => (
    <span style={{ color: '#FFFF0040' }}>
      {process.env.REACT_APP_ENVIRONMENT}
    </span>
  ))();
}

export function ClearSession() {
  const dispatch = useAppDispatch();
  return ifDev(() => (
    <>
      <span style={{ marginLeft: 20, cursor: 'pointer', color: '#ff000090' }}>
        <a
          onClick={async () => {
            await dispatch(initialiseCurrentListing);
            // eslint-disable-next-line no-console
            console.clear();
            sessionStorage.clear();
            location.reload();
            // eslint-disable-next-line no-console
            console.log('cleared storage');
            printSession(StorageKey.createListing);
          }}
        >
          Clear Session
        </a>
      </span>
    </>
  ))();
}




