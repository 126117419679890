
import { IAutocompleteResult } from '../../models/iCardSearchState';
import { RootState } from '../../stores/store';
import { ICard } from '../createListing/models/iCard';
import { ICardSearchParameters } from './iCardSearchParameters';

const selectors = {
  selectTotalCards: (state: RootState): number => state.cardSearch.totalCards,
  selectAutocompleteResults: (state: RootState): IAutocompleteResult[] => state.cardSearch.autoCompleteResults,
  selectCardSearchResults: (state: RootState): ICard[] => state.cardSearch.cardSearchResults,
  selectCardSearchResultsFacets: (state: RootState): any => state.cardSearch.facets,
  selectCardSearchResultsFacetCounts: (state: RootState): any => state.cardSearch.facetCounts,
  selectTotalSearchResults: (state: RootState): number => state.cardSearch.totalSearchResults,
  selectCardSearchParameters: (state: RootState): ICardSearchParameters | undefined => state.cardSearch.cardSearchParameters,
  selectIsLoadingCards: (state: RootState): boolean => state.cardSearch.isLoadingCards,
};

export const {
  selectCardSearchResults,
  selectTotalCards,
  selectAutocompleteResults,
  selectTotalSearchResults,
  selectCardSearchParameters,
  selectIsLoadingCards,
  selectCardSearchResultsFacets,
  selectCardSearchResultsFacetCounts
} = selectors;
