/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Section } from './Section';
import { ActiveRadioGroup } from '../../components/shared/RadioButton';
import '../../styles/material.css';
import classes from '../../styles/shipping.module.scss';
import { useAppDispatch, useAppSelector } from '../../hook';
import { IListing } from '../../models/create-listing/iListing';
import { IShipping } from '../../models/create-listing/IShipping';
import { selectCurrentListing } from './createListingSelectors';

import { Heading } from '../../components/shared/Heading';
import { Information, Instruction, StyledInformation } from '../../components/shared/Instruction';
import { InputAdornment, TextField } from '@mui/material';
import { setShipping, setShippingBundled } from './store/createListingSlice';
import { IInput, CustomInputComponent } from '../../components/input/IInput';
import { Translation } from 'react-i18next';
import { AddressSearch, AddressSearchResult } from '../../components/address/AddressSearch';
import { FlexBox, StyledCheckbox, StyledDivider, StyledHeading } from 'src/components/styled/StyledComponents';
import { selectPostageSettings } from '../user/components/userSelectors';
import { useEffectOnce } from 'react-use';
import { getUserPostageSettingsAction } from '../user/actions';
import { IOption } from 'src/components/shared/AutoComplete';
import { PropaneSharp } from '@mui/icons-material';
import { t } from 'i18next';

export enum ShippingType {
  domestic = 'Domestic',
  domesticInternational = 'Domestic & International',
}

const shippingOptions = [
  { value: ShippingType.domestic, label: ShippingType.domestic, id: ShippingType.domestic, count: 0 },
  {
    value: ShippingType.domesticInternational,
    label: ShippingType.domesticInternational,
    id: ShippingType.domesticInternational,
    count: 0,
  },
];

export enum ShippingChargeType {
  freePostage = 'Free Postage',
  chargePostage = 'Charge Postage',
}

const shippingChargeOptions = [
  {
    value: ShippingChargeType.freePostage,
    label: ShippingChargeType.freePostage,
    id: ShippingChargeType.freePostage,
    count: 0,
  },
  {
    value: ShippingChargeType.chargePostage,
    label: ShippingChargeType.chargePostage,
    id: ShippingChargeType.chargePostage,
    count: 0,
  },
];

export function Shipping() {
  const dispatch = useAppDispatch();
  const preferences = useAppSelector(selectPostageSettings);

  const currentListing: IListing = useAppSelector(selectCurrentListing);
  const [shippingTracked, setShippingTracked] = useState<boolean>(currentListing.shipping?.isTracked ?? false);

  const [shippingType, setShippingType] = useState<ShippingType | undefined>(
    currentListing.shipping?.shippingType ?? undefined
  );
  const [shippingChargeType, setShippingChargeType] = useState<ShippingChargeType | undefined>(
    currentListing.shipping?.shippingChargeType ?? undefined
  );

  const [domesticShippingCharge, setDomesticShippingCharge] = useState<number | undefined>(
    currentListing.shipping?.domesticShippingCharge ?? undefined
  );
  const [internationalShippingCharge, setInternationalShippingCharge] = useState<number | undefined>(
    currentListing.shipping?.internationalShippingCharge ?? undefined
  );

  const [itemAddress, setItemAddress] = useState<AddressSearchResult | undefined>(
    currentListing.shipping?.address
  );

  const [rememberThisLocation, setRememberThisLocation] = useState<boolean>(currentListing.shipping?.savePreferences ?? false);
  const [excludeFromPostageThreshold, setExcludeFromPostageThreshold] = useState<boolean>(currentListing.shipping?.excludeFromShippingThreshold ?? false);

  useEffectOnce(() => {
    dispatch(getUserPostageSettingsAction(currentListing.userId));
  });

  useEffect(() => {
    if (!excludeFromPostageThreshold && preferences.data?.postage?.threshold === 'threshold') {
      setShippingChargeType(undefined);
      setInternationalShippingCharge(undefined);
      setDomesticShippingCharge(preferences.data?.postage?.postageAmountFee);
    }

  }, [excludeFromPostageThreshold, preferences.data?.postage?.postageAmountFee]);


  useEffect(() => {
    if (preferences.isLoading) return;

    dispatch(setShippingBundled(preferences?.data?.postage?.threshold === 'threshold'));

    if (preferences.data?.postage) {
      setItemAddress({
        fullAddress: preferences.data.postage.itemLocation?.suburb,
        postcode: preferences.data.postage.itemLocation?.postCode,
        country: preferences.data.postage.itemLocation?.country,
        locality: preferences.data.postage.itemLocation?.suburb,
      });
    }

  }, [preferences]);

  const onSelected = (shipping: IShipping) => dispatch(setShipping({ ...shipping, bundled: preferences.data?.postage?.threshold === 'threshold' }));

  const onShippingTrackedChanged = (isTracked: boolean) => {
    setShippingTracked(isTracked);
    const s = { ...currentListing.shipping, isTracked: isTracked };
    dispatch(setShipping(s));
  };

  const onAddressChanged = (address: AddressSearchResult) => {
    setItemAddress(address);
  };

  useEffect(() => {
    const shippingItem: IShipping = {
      shippingType: ShippingType.domestic, //shippingType, // disabled until international shipping is implemented
      shippingChargeType: shippingChargeType,
      domesticShippingCharge: domesticShippingCharge,
      internationalShippingCharge: internationalShippingCharge,
      address: itemAddress,
      isTracked: shippingTracked,
      savePreferences: rememberThisLocation,
      excludeFromShippingThreshold: excludeFromPostageThreshold,
      bundled: false,

    };
    onSelected(shippingItem);
  }, [shippingType, shippingChargeType, domesticShippingCharge, internationalShippingCharge, itemAddress, shippingTracked, rememberThisLocation, excludeFromPostageThreshold]);



  const ShippingMethod = React.useCallback(() => {

    if (preferences.data?.postage?.threshold === 'threshold')
      return <Heading>{t('createListing.shipping.bulkShippingEnabled', { fee: preferences.data.postage.postageAmountFee, amount: preferences.data.postage.thresholdAmount })}</Heading>;
    return <Heading>{t('createListing.shipping.bulkShippingDisabled')}</Heading>;
  }, [preferences.data?.postage?.threshold]);


  return (
    <Section
      title={<Translation>{(t) => <>{t('createListing.shipping.postage')}</>}</Translation>}
      subTitle={<Translation>{(t) => <>{t('Shipping Options')}</>}</Translation>}
      className={classes.shipping}>

      <FlexBox direction='column'>
        <FlexBox alignItems='center' style={{ columnGap: 8 }}>
          <Information text="Item Location" color='white' />
          <AddressSearch onSelected={onAddressChanged} value={itemAddress} />
        </FlexBox>
        <Information text="We only use your country and postcode for distance calculations." size='small' />
      </FlexBox>

      {!preferences.data?.postage?.itemLocation?.postCode ?
        <FlexBox direction='column'>
          <StyledCheckbox label='Remember this location' checked={rememberThisLocation} onChange={() => {
            setRememberThisLocation(!rememberThisLocation);
          }} />
          <StyledInformation text='Save time and remember this location for next time?' italic size='small' />
        </FlexBox>
        : <></>
      }

      <ShippingMethod />

      {preferences.data?.postage?.threshold === 'threshold' ? (
        <FlexBox direction='column'>
          <StyledCheckbox label='Exclude from postage threshold' checked={excludeFromPostageThreshold} onChange={() => {
            setExcludeFromPostageThreshold(!excludeFromPostageThreshold);
          }} />
          <StyledInformation text='Combined shipping cost, see postage preferences for more info' italic size='small' />
          <FlexBox>

          </FlexBox>
        </FlexBox>)
        : <></>
      }


      <StyledDivider />
      {/* <ShippingDestination value={shippingType} onSelected={setShippingType} /> */}
      <ShippingChargeOptions
        show={!!excludeFromPostageThreshold || preferences.data?.postage?.threshold !== 'threshold'}
        value={shippingChargeType}
        onSelected={setShippingChargeType}
        options={shippingChargeOptions}
        heading="Cost"
      />


      <ShippingCost

        show={shippingChargeType !== ShippingChargeType.freePostage}
        disabled={!excludeFromPostageThreshold && preferences.data?.postage?.threshold === 'threshold'}
        shippingType={shippingType}
        shippingChargeType={shippingChargeType}
        domesticShippingCharge={domesticShippingCharge}
        internationalShippingCharge={internationalShippingCharge}
        onDomesticShippingChanged={setDomesticShippingCharge}
        oninternationalShippingChanged={setInternationalShippingCharge}
      />
      <ShippingTracked value={shippingTracked} onSelected={onShippingTrackedChanged} title='Tracking' />
    </Section>
  );
}

function ShippingDestination(props: { disabled?: boolean, value?: ShippingType; onSelected?: (value: ShippingType) => void; }) {

  const [shippingType, setShippingType] = useState<ShippingType | undefined>(props.value ?? undefined);

  const [infoText, setInfoText] = useState<string>('');

  useEffect(() => {

    if (shippingType === ShippingType.domestic) {
      setInfoText('Domestic shipping only');
    } else {
      setInfoText('Ship to both domestic and international locations');
    }

  }, [shippingType]);


  return (
    <FlexBox direction='column'>
      <Heading text="Destination" />
      <ActiveRadioGroup
        row
        disabled
        aria-labelledby="condition-options"
        aria-orientation="horizontal"
        name="condition"
        value={shippingType || ''}
        onChange={(event) => {
          setShippingType(event.target.value as ShippingType);
          props.onSelected?.(event.target.value as ShippingType);
        }}
        options={shippingOptions}
      />
      <StyledInformation text={infoText} italic size='small' />
    </FlexBox>
  );
}


export function ShippingTracked(props: { show?: boolean; value?: boolean, title: string, showTitle?: boolean, onSelected?: (value: boolean) => void; }) {
  return <FlexBox direction='column'>
    <Heading text={props.title} />
    <ActiveRadioGroup
      row
      aria-labelledby="shipping-charge-options"
      aria-orientation="horizontal"
      name="shipping-charge-options"
      value={props.value}
      onChange={(event) => {
        props.onSelected?.(event.target.value === 'true');
      }}
      options={[{ value: true, label: 'Yes', id: 'yes', count: 0 }, { value: false, label: 'No', id: 'no', count: 0 }]}
    />
    <Instruction text={`A tracking number will ${props.value ? '' : ' NOT '} be provided`} size='small' italic />
  </FlexBox>;
}

function ShippingChargeOptions(props: {
  show?: boolean;
  value?: ShippingChargeType;
  options: IOption[];
  heading: string;
  onSelected?: (value: ShippingChargeType) => void;
}) {

  useEffect(() => {
    if (props.show && !props.value) {
      props.onSelected?.(ShippingChargeType.freePostage);
    }
  }, [props.show]);


  return (
    <FlexBox direction='column'>
      {props.show ? (
        <>
          <Heading text={props.heading}></Heading>
          <ActiveRadioGroup
            row
            aria-labelledby="shipping-charge-options"
            aria-orientation="horizontal"
            name="shipping-charge-options"
            value={props.value || ''}
            onChange={(event) => {
              props.onSelected?.(event.target.value as ShippingChargeType);
            }}
            options={props.options}
          />
          <StyledInformation italic text={`${props.value}`} size='small' />
        </>
      ) : (
        <></>
      )}
    </FlexBox>
  );
}

interface IShippingCostProps {
  shippingChargeType?: ShippingChargeType;
  shippingType?: ShippingType;
  domesticShippingCharge?: number;
  internationalShippingCharge?: number;
  domesticShippingAmountLabel?: string;
  internationalShippingAmountLabel?: string;
  show?: boolean;
  disabled?: boolean;
  onDomesticShippingChanged?: (value?: number) => void;
  oninternationalShippingChanged?: (value?: number) => void;
}

function ShippingCost(props: IShippingCostProps) {
  const [dmCharge, setDmShippingCharge] = useState<string>(props.domesticShippingCharge?.toString() ?? '');
  const [intlCharge, setIntlCharge] = useState<string>(props.domesticShippingCharge?.toString() ?? '');

  // useEffect(() => {
  //   props.onDomesticShippingChanged?.(parseFloat(dmCharge));
  //   props.oninternationalShippingChanged?.(parseFloat(intlCharge));
  // }, [dmCharge, intlCharge]);

  useEffect(() => {
    if (props.shippingChargeType === ShippingChargeType.freePostage) {
      setDmShippingCharge('');
      setIntlCharge('');
      props.onDomesticShippingChanged?.(undefined);
      props.oninternationalShippingChanged?.(undefined);
    }
  }, [props.shippingChargeType]);

  // useEffect(() => {
  //   setDmShippingCharge('');
  //   setIntlCharge('');
  //   props.onDomesticShippingChanged?.(undefined);
  //   props.oninternationalShippingChanged?.(undefined);
  // }, [props.shippingType]);

  useEffect(() => {
    setDmShippingCharge(props.domesticShippingCharge?.toString() ?? '');
    setIntlCharge(props.internationalShippingCharge?.toString() ?? '');
  }, [props.domesticShippingCharge, props.internationalShippingCharge]);

  return (
    <>
      {props.show ? (
        <>
          <Heading text="Postage Amount" style={{ marginBottom: 5 }} ></Heading>
          <TextField
            error={true}
            required
            disabled={props.disabled}
            label={props.domesticShippingAmountLabel ?? 'Domestic Postage'}
            variant="outlined"
            placeholder="0.00"
            value={dmCharge}
            InputLabelProps={{ style: { color: '#ffffff80' } }}
            className={classes.domesticPostageAmount}
            onChange={(e) => {
              setDmShippingCharge(e.target.value);
              props.onDomesticShippingChanged?.(parseFloat(e.target.value));
            }}
            helperText=""
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ color: 'blue' }}>
                  <span style={{ color: '#ffffff40' }}>$</span>
                </InputAdornment>
              ),
              inputComponent: CustomInputComponent,
              inputProps: {
                component: IInput,
              },
            }}
          />
          <div style={{ marginBottom: 5 }}></div>


          {/* remove international postage option for now */}
          {/* <TextField
            error={true}
            required
            label={props.internationalShippingAmountLabel ?? 'International Postage'}
            variant="outlined"
            value={intlCharge}
            placeholder={props.shippingType !== ShippingType.domesticInternational ? '' : '0.00'}
            disabled={props.shippingType !== ShippingType.domesticInternational}
            InputLabelProps={{ style: { color: '#ffffff80' } }}
            className={classes.internationalPostageAmount}
            onChange={(e) => {
              setIntlCharge(e.target.value);
            }}
            helperText="Enter your international postage price"
            style={{ width: 300, borderColor: 'white', outline: 'white' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span style={{ color: '#ffffff40' }}>
                    {props.shippingType !== ShippingType.domesticInternational ? 'N/A' : '$'}
                  </span>
                </InputAdornment>
              ),
              inputComponent: CustomInputComponent,
              inputProps: {
                component: IInput,
              },
            }}
          /> */}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
