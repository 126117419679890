import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { ListingBuyNow } from '../../features/listing/ListingBuyNow';
import { Listing } from '../../features/listing/Listing';

import { routeConfig } from '../../routes/routeConfig';
import { Marketplace } from '../Marketplace';
import { ListingPage } from '../../features/listing/ListingPage';
import { PurchaseComplete } from './PurchaseComplete';
import { LoadingWidget } from '../../components/shared/Loading';
import ProtectedRoute from '../../ProtectedRoute';
import { PageType } from 'src/components/Main';

export function ListingRoutes() {
  return (
    <React.Fragment>
      <Route path={routeConfig.listing.root} element={<ListingPage />}>
        <Route path={'p_p'} />
        <Route
          path={routeConfig.listing.buyNow}
          element={
            <Marketplace hideSearchBar>
              <ProtectedRoute component={ListingBuyNow} pageType={PageType.Marketplace} />
            </Marketplace>
          }
        ></Route>
        <Route
          path={routeConfig.listing.listingById}
          element={
            <Marketplace hideSearchBar>
              <Listing />
            </Marketplace>
          }
        ></Route>
        <Route
          path={routeConfig.listing.listingByIdSeriesSetName}
          element={
            <Marketplace hideSearchBar>
              <Suspense fallback={<LoadingWidget isLoading={true} />}>
                <Listing />
              </Suspense>
            </Marketplace>
          }
        ></Route>
        <Route
          path={routeConfig.listing.notFound}
          element={<ListingNotFound></ListingNotFound>}
        />
        <Route path={routeConfig.listing.purchaseComplete} element={<PurchaseComplete />}></Route>
      </Route>
    </React.Fragment>
  );
}

export function ListingNotFound() {

  return <Marketplace >
    <div>Listing not found</div>
  </Marketplace>;
}
