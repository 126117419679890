import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IListing } from '../../models/create-listing/iListing';
import { IUserState } from '../../models/user/IUserState';
import { StorageKey } from '../../stores/Storage';
import { IRegistrationLink } from '../../models/user/iRegistrationLinks';
import { IGraphSearchResult } from 'src/services/search-service/SolrSearchResults';
import { UserPreferences } from './MyPostalSettings';
import { IAsyncPayload } from '../listing/IAsyncPayload';

const initialState: IUserState = {
  sellerListings: { isLoading: false, data: {} as IGraphSearchResult },
  sellerSoldListings: { isLoading: false, data: {} as IGraphSearchResult },
  purchaseHistory: { isLoading: false, data: [] },
  sellerPaymentServiceOnboardingStatus: { isLoading: false, data: {} as IRegistrationLink },
  userPreferences: { isLoading: false, data: {} as UserPreferences }
};

const userSlice = createSlice({
  name: StorageKey.user,
  initialState: initialState,
  reducers: {
    setSellerPaymentServiceOnboardingStatus: (state, action) => {
      state.sellerPaymentServiceOnboardingStatus.data = action.payload.data;
      state.sellerPaymentServiceOnboardingStatus.isLoading = action.payload.isLoading ?? false;
    },
    setPurchaseHistory: (state, action: PayloadAction<{ data: IListing[], isLoading?: boolean; }>) => {
      state.purchaseHistory.data = action.payload.data;
      state.purchaseHistory.isLoading = action.payload.isLoading ?? false;
    },
    setFetchingSellerSoldListings: (state, action) => {
      state.sellerSoldListings.data = action.payload.data;
      state.sellerSoldListings.isLoading = action.payload.isLoading ?? false;
    },
    setSellerListings: (state, action: PayloadAction<{ data: IGraphSearchResult, isLoading?: boolean; }>) => {
      state.sellerListings.data = action.payload.data;
      state.sellerListings.isLoading = action.payload.isLoading ?? false;
    },
    setSellerSoldListings: (state, action: PayloadAction<{ data: IGraphSearchResult, isLoading?: boolean; }>) => {
      state.sellerSoldListings.data = action.payload.data;
      state.sellerSoldListings.isLoading = action.payload.isLoading ?? false;
    },
    setPostagePreferences: (state, action: PayloadAction<IAsyncPayload<UserPreferences>>) => {
      state.userPreferences = action.payload;

    }
  },
});

export const { setSellerListings
  , setSellerSoldListings
  , setFetchingSellerSoldListings
  , setPurchaseHistory
  , setSellerPaymentServiceOnboardingStatus
  , setPostagePreferences
} = userSlice.actions;

export default userSlice.reducer;
