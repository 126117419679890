import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useDebounceCallback } from '../../hooks/useDebounce';
import { SEARCH_DEBOUNCE_TIME } from '../../config/appConfig';
import { BoldedText } from './BoldedText';
import classes from '../../styles/autoComplete.module.scss';
import SearchRounded from '@mui/icons-material/SearchRounded';
import { useCloseDialogListener } from '../../hooks/useCloseDialogListener';
import { useRowHighlighter } from '../../hooks/useRowHighlighter';
import useMedia from 'react-use/lib/useMedia';
import { createPortal } from 'react-dom';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { StyledDivider } from '../styled/StyledComponents';

export interface IOption {
  label: string;
  value: string | boolean | number;
  count: number;
  id: string;
}

export interface IAutoCompleteSingleProps {
  selected?: any;
  value?: Array<any>;
  fullWidth?: boolean;
  options: Array<IOption>;
  placeholder?: string;
  label?: string;
  style?: React.CSSProperties;
  className?: string;
  open?: boolean;
  loading?: boolean;
  inputClassName?: string;
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  searchTextPrompt?: string;
  anchorElementId?: string;
  onSelected?: (value: any) => void;
  onInputTextChanged?: (value: any) => void;
  onBlur?: (value: any) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export function AutoComplete(props: IAutoCompleteSingleProps) {
  const isMobile = useMedia('(max-width: 800px)');
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputTextOption, setInputTextOption] = React.useState<IOption>(
    {} as IOption
  );

  const [inputText, setInputText] = React.useState<string>(
    props.selected || ''
  );
  const [pickerShow, setPickerShow] = React.useState<boolean>(
    props.open || false
  );
  const [searchContainerElement, setSearchContainerElement] =
    useState<HTMLElement>(document.createElement('div'));

  const {
    getHighlightClass,
    highlightedIndex,
    onRowKeyDown,
    resetHighlightedIndex,
  } = useRowHighlighter(props.options.length, classes.highlighted);

  const onKeyUpDebounce = useDebounceCallback(
    onKeyUp,
    +(SEARCH_DEBOUNCE_TIME || '200')
  );
  const ref = useRef<HTMLDivElement>(null);

  useCloseDialogListener(ref, () => setPickerShow(false));

  useEffect(() => {
    const element = document.getElementById('search-results-container');
    if (element) setSearchContainerElement(element);
  }, [pickerShow]);

  useEffect(() => {
    const open = (!!props.anchorElementId && props.open) || false;
    setPickerShow(open);
  }, [props.open]);

  useEffect(() => {
    setInputText(props.selected || '');
  }, [props.selected]);

  useEffect(() => {
    resetHighlightedIndex();
  }, [inputText]);

  function onSelected(_: any, option: IOption) {
    setPickerShow(false);
    props.onSelected?.(option);
    setInputText(option.label);
    props.onInputTextChanged?.(option.label);
  }

  function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (['Enter'].includes(event.key)) {
      const option = props.options[highlightedIndex - 1] || inputTextOption;

      props.onSelected?.(option);
      props.onInputTextChanged?.(option.label);
      setInputText(option.label);
      setPickerShow(false);
    }
    onRowKeyDown(event);
  }

  function onBlur() {
    props.onBlur?.(inputText);
  }

  function onKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    props.onKeyUp?.(event);
  }

  function onTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.target.value;
    setInputText(inputValue);
    setInputTextOption({
      label: inputValue,
      value: inputValue,
      count: 0,
      id: '',
    });
    setPickerShow(!!inputValue);
    props.onInputTextChanged?.(inputValue);
  }

  const picker = useCallback(() => {
    return (
      <div className={classes.autoCompleteContainer}>
        <StyledDivider />
        {[inputTextOption, ...props.options].map((option, index) => {
          const item = document.getElementsByClassName(
            getHighlightClass(index)
          );
          if (item[0]) {
            item[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            });
          }

          return (
            <div
              key={index}
              className={`${classes.autoCompleteOption} ${getHighlightClass(index) || ''
                }`}
              onClick={(event) => onSelected(event, option)}
            >
              <BoldedText label={option.label} searchStr={inputText} />
              {index === 0 && (
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: 12,
                    color: 'rgb(255 255 255 / 25%)',
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;{props.searchTextPrompt}
                </span>
              )}
            </div>
          );
        })}
      </div>
    );
  }, [props.options, highlightedIndex]);

  const alternativePicker = useCallback(() => {
    return <div className={classes.alternativePicker}>{picker()}</div>;
  }, [props.options, highlightedIndex]);

  return (
    <div className={classes.autoCompleteRoot}>
      <div className={classes.autoComplete} ref={ref}>
        <div className={classes.autoCompleteInner}>
          <div className={classes.inputContainer}>
            <input
              autoComplete="off"
              ref={inputRef}
              {...props.inputProps}
              type="text"
              name="field1"
              value={inputText}
              placeholder={props.placeholder}
              onBlur={onBlur}
              onChange={onTextChange}
              onKeyUp={onKeyUpDebounce}
              onKeyDown={onKeyDown}
            ></input>
            <IconButton
              aria-label="clear"
              onClick={() => {
                setInputText('');
                props.onInputTextChanged?.('');
                props.onSelected?.({ value: '', count: 0, label: '', id: '' });
              }}>
              <Close />
            </IconButton>
            <IconButton
              aria-label="search"
              className={classes.hideWhenMobile}
              style={{ cursor: 'pointer' }}
              onClick={(event: any) =>
                onSelected(event, {
                  value: inputText || '',
                  count: 0,
                  label: inputText,
                  id: '',
                })
              }
            >
              <SearchRounded />
            </IconButton>
          </div>
          <div id="picker">
            {!isMobile
              ? pickerShow && picker()
              : pickerShow &&
              createPortal(alternativePicker(), searchContainerElement)}
          </div>
        </div>
      </div>
    </div>
  );
}
