import React, { useEffect } from 'react';
import { Dialog } from './shared/dialog';
import classes from './tracking.module.scss';
import { createPortal } from 'react-dom';
import { Instruction } from './shared/Instruction';
import TextField from '@mui/material/TextField';
import { BarcodeScanner } from './QRScanner';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { ShippingTracked } from 'src/features/createListing/Shipping';

export interface ITrackingDialogProps<TMessageType = any> {
    show: boolean;
    onClose: () => void;
    onMessageSelected?: (message: TMessageType) => void;
    selectedMessage?: TMessageType;
}


export interface ITracking {
    trackingNumber?: string;
    listingId?: string;
    id?: string;
    trackingUrl?: string;
}

export function TrackingDialog(props: { show: boolean; onClose: () => void; onTrackingChanged?: (tracking: ITracking) => void, tracking?: ITracking; onSubmit?: (tracking: ITracking) => void; }) {

    const [trackingDetails, setTrackingDetails] = React.useState<ITracking>({} as ITracking);
    const [trackingEnabled, setTrackingEnabled] = React.useState(false);
    const [showScanner, setShowScanner] = React.useState(false);


    useEffect(() => {
        setTrackingDetails(props.tracking ?? {} as ITracking);
    }, [props.tracking]);

    function onTrackingSelected(tracking: boolean) {
        setTrackingDetails({} as ITracking);
        setTrackingEnabled(tracking);

        if (!tracking)
            setShowScanner(false);
    }


    function onChanged(propertyName: 'trackingUrl' | 'trackingNumber', value: string) {
        const tracking = { ...trackingDetails, [propertyName]: value } as ITracking;
        setTrackingDetails(tracking);
        props.onTrackingChanged?.(tracking);
    }

    function submit() {
        props.onSubmit?.(trackingDetails);
        close();
    }

    function close() {
        setShowScanner(false);
        props.onClose();
    }

    function cancel() {
        props.onClose();
    }


    return createPortal(<Dialog show={props.show} onClose={close} style={{ padding: 20 }} className={classes.dialog}>
        <listing-tracking-dialog class={classes.root}>
            <div className={classes.heading}>
                <IconButton onClick={close} style={{ color: '#ffffff60' }} className={classes.closeButton}>
                    <Close />
                </IconButton>
                <h4>Enter Tracking details</h4>
            </div>
            <ShippingTracked value={trackingEnabled} onSelected={onTrackingSelected} title='' />
            <div className={classes.content}>
                {showScanner
                    ? <BarcodeScanner onDetected={(result: string) => {

                        const formatReadResult = (result: string) => {
                            // const x1dindex = result.text.search('\x1D');
                            // result = result.substring(result.search(/[A-Za-z]/));
                            // result = result.substring(0, result.search(/\(/));

                            const x1dindex = result.search('\x1D');

                            if (x1dindex === -1)
                                return result;
                            // return result.text;

                            const firstLetterIndex = result.search(/[A-Za-z]/); // Find the first letter in the string
                            const r = result.substr(firstLetterIndex, x1dindex - firstLetterIndex);
                            return r;
                        };

                        const r = formatReadResult(result);

                        if (r.search(/http|https/) !== -1) {
                            onChanged('trackingUrl', r);
                        }
                        else
                            onChanged('trackingNumber', r);

                        setShowScanner(false);
                    }} />
                    : trackingEnabled && <>

                        <Instruction text="Provide a tracking number and URL so that your buyer has easy access to tracking information" />
                        <TextField
                            label='Tracking Number'

                            variant='outlined'
                            fullWidth
                            size='small'
                            style={{ color: '#ffffff80' }}
                            value={trackingDetails.trackingNumber ?? ''}
                            InputLabelProps={{
                                style: { color: '#ffffff', boxSizing: 'content-box' },
                                shrink: true
                            }}
                            inputProps={{
                                style: { boxSizing: 'content-box' },
                                placeholder: 'Enter tracking number',
                                onChange: (event) => onChanged('trackingNumber', event.currentTarget.value),
                                maxLength: 255,
                                name: 'tracking_number',
                                required: true,
                                className: classes.comment,

                            }}
                        ></TextField>
                        <TextField
                            fullWidth
                            variant='outlined'
                            size='small'
                            placeholder='Enter tracking url'
                            label="Tracking URL"
                            style={{ color: '#ffffff80' }}
                            value={trackingDetails.trackingUrl ?? ''}
                            onChange={(event) => onChanged('trackingUrl', event.currentTarget.value)}
                            name='url'
                            InputLabelProps={{
                                style: { color: 'white', boxSizing: 'content-box' },
                                shrink: true
                            }}
                        ></TextField>
                    </>}
            </div>
            <div className={classes.actionButtons} >
                {
                    !showScanner && trackingEnabled ? <a aria-label='link' className={classes.cancelButton} style={{ padding: 5, alignSelf: 'flex-start' }} onClick={() => setShowScanner(true)}>Scan QR Code</a>
                        : trackingEnabled && <a aria-label='link' className={classes.cancelButton} style={{ padding: 5, alignSelf: 'flex-start' }} onClick={() => setShowScanner(false)}>Enter Manually</a>
                }
                <a aria-label='link' className={classes.cancelButton} style={{ padding: 5 }} onClick={() => cancel()}>Cancel</a>
                {
                    (trackingDetails.trackingNumber || !trackingEnabled)
                        ? <a aria-label='link' className={classes.saveButton} style={{ padding: 5 }} onClick={() => submit()} >Confirm</a>
                        : <a aria-label='link' style={{ padding: 5, color: '#ffffff60' }}>Confirm</a>
                }
            </div>
        </listing-tracking-dialog>
    </Dialog >, document.body);
}




