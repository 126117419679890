/* eslint-disable react/react-in-jsx-scope */
import { CreateOrderActions, CreateOrderData, OnApproveActions, OnApproveData } from '@paypal/paypal-js';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';

import { error } from 'src/actions/logActions';
import classes from '../../styles/pay-now.module.scss';
import { LoadingWidget } from '../shared/Loading';
import { FlexBox } from '../styled/StyledComponents';
export function PayButtons(props: {
    errorText: string;
    merchantIds: string[];
    createOrderHandler?: (data: CreateOrderData, actions: CreateOrderActions) => Promise<string>;
    onApproveHandler?: (data: OnApproveData, actions: OnApproveActions) => Promise<void>;
    isLoading?: boolean;
}) {

    if (props.merchantIds.length === 0) {
        return (
            <FlexBox style={{ padding: 8, color: '#890000' }}>
                <h1>{props.errorText}</h1>
            </FlexBox>
        );
    }

    const getMerchantId = () => {
        if (props.merchantIds.length === 1) {
            return props.merchantIds[0];
        } else if (props.merchantIds.length > 1) {
            return '*';

        } else {
            return '';
        }
    };

    const getDataMerchantId = () => {
        if (props.merchantIds.length > 1) {
            return props.merchantIds.join(',');
        } else {
            return undefined;
        }
    };

    return (
        <LoadingWidget isLoading={props.isLoading ?? false}>
            <PayPalScriptProvider
                options={{
                    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID ?? '',
                    currency: 'AUD',
                    merchantId: getMerchantId(),
                    dataMerchantId: getDataMerchantId(),

                }}
            >
                <PayPalButtons
                    createOrder={props.createOrderHandler}
                    onApprove={props.onApproveHandler}
                    onError={(err) => {
                        error('CREATE_ORDER_ERROR', err);
                    }}
                    style={{ shape: 'pill', height: 40 }}
                    className={classes.payButtons}
                />
            </PayPalScriptProvider>
        </LoadingWidget>
    );

}