import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { UserPreferences } from './MyPostalSettings';
import { getPostagePreferencesService, resendVerificationEmailService, updatePostagePreferencesService } from 'src/services/user-service/userService';
import { AsyncThunkConfig } from 'src/types/toolkit';

interface IUserActions {
    updateUserPostageSettingsAction: AsyncThunk<any, { param: { postageSettings: UserPreferences; }; }, any>;
    getUserPostageSettingsAction: AsyncThunk<any, string, any>;
    verifyEmailAction: AsyncThunk<any, void, any>;
}

const actions: IUserActions = {
    verifyEmailAction: createAsyncThunk('api/verifyEmail',
        async (_, thunkapi: AsyncThunkConfig) => {
            try {
                await resendVerificationEmailService();
            } catch (ex: any) {
                // eslint-disable-next-line no-console
                console.error('VERIFY_EMAIL_ERROR', { errorMessage: ex.message });
                return thunkapi.rejectWithValue(ex.message ?? ex);
            }
        }),
    updateUserPostageSettingsAction: createAsyncThunk('api/updateUserPostageSettings',
        async (arg: { param: { postageSettings: UserPreferences; }; }, thunkapi: AsyncThunkConfig) => {
            try {
                const response = await updatePostagePreferencesService({ postageSettings: arg.param.postageSettings });
                return response.result as UserPreferences;

            } catch (ex: any) {
                // eslint-disable-next-line no-console
                console.error('UPDATE_USER_POSTAGE_SETTINGS_ERROR', { errorMessage: ex.message });
                return thunkapi.rejectWithValue(ex.message ?? ex);
            }
        }),
    getUserPostageSettingsAction: createAsyncThunk('api/getUserPostageSettings', async (userId: string, thunkapi: AsyncThunkConfig) => {
        try {

            const response = await getPostagePreferencesService(userId);

            if (!response?.err) {
                return response.result;
            }
            // eslint-disable-next-line no-console
            console.warn('GET_USER_POSTAGE_SETTINGS_ERROR', response.err);
            return thunkapi.rejectWithValue('no postage settings');
        } catch (ex: any) {
            // eslint-disable-next-line no-console
            console.error('GET_USER_POSTAGE_SETTINGS_ERROR', { errorMessage: ex.message });
            return thunkapi.rejectWithValue(ex.message ?? ex);
        }
    }),
};

export const {
    updateUserPostageSettingsAction,
    getUserPostageSettingsAction,
    verifyEmailAction
} = actions;