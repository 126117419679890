import React from 'react';
import { Heading } from '../../../components/shared/Heading';
import sellerActions from '../seller-actions.module.scss';
import { UserAsset } from '../UserAsset';
import { UserPageHeader } from '../components/UserPageHeader';
import { mockSellingAssets } from '../UserComponents';
import { ComingSoon } from '../../../components/shared/ComingSoon';



export function SellerUserActions() {
  return (
    <div className={sellerActions.sellerActions}>
      <UserPageHeader title="" pageTitle="Seller Actions" />

      <Heading text="Seller Account" />
      {/* <TumbleWeed message="When you start selling things, items that require actioning will appear here" /> */}
      <ComingSoon >
        {mockSellingAssets.map((asset, index) => (
          <UserAsset key={index} asset={asset.asset} actions={asset.actions}></UserAsset>
        ))}
        <hr />
      </ComingSoon>
    </div>
  );
}
