import React, { CSSProperties, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Main, PageType } from '../components/Main';
import { routeConfig } from '../routes/routeConfig';
import { ReactComponent as FAQICON } from '../assets/svg/user-profile/faq.svg';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import { Accent } from '../styles/components/Accent';
import { ReactComponent as Account } from '../assets/svg/user-profile/account.svg';
import { ReactComponent as Feedback } from '../assets/svg/user-profile/feedback.svg';
import { ReactComponent as PurchaseHistory } from '../assets/svg/user-profile/purchase-history.svg';
import { ReactComponent as SellersHub } from '../assets/svg/bottombar/marketplace.svg';
import { ReactComponent as SoldHistory } from '../assets/svg/user-profile/sold-history.svg';
import { ReactComponent as Accessibility } from '../assets/svg/user-profile/accessibility.svg';
import { ReactComponent as Messages } from '../assets/svg/user-profile/bell.svg';

import classes from './userPage.module.scss';
import { useAppDispatch } from '../hook';
import { getSellerPaymentServiceOnboardingStatus } from '../features/user/seller/sellerActions';
import { useEffectOnce, useMedia } from 'react-use';
import MiniDrawer from '../features/user/Drawer';
import { useAuthHook } from '../shared/hooks/useAuthHook';
import { useMobileFooterBar } from 'src/views/useMobileFooterBar';

interface IUserMenuOptions {
  label: string;
  value: string;
  icon: JSX.Element;
  scope: string[];
  children?: IUserMenuOptions[];
}

export const accountMenuOptions: IUserMenuOptions[] = [
  {
    label: 'My Account',
    value: routeConfig.user.myAccount,
    icon: <Account style={{ fill: '#ffffff' }} />,
    scope: ['userName', 'side-bar'],
    children: [

      {
        label: 'Shipping Details',
        value: routeConfig.user.postalSettings,
        icon: <></>,
        scope: ['side-bar'],
      }]
  },
  {
    label: 'Accessibility',
    value: '/no-path-set',
    icon: <Accessibility style={{ fill: '#ffffff' }} />,
    scope: [],
  },
  {
    label: 'Purchase History',
    value: routeConfig.user.purchaseHistory,
    icon: <PurchaseHistory style={{ fill: '#ffffff' }} />,
    scope: ['side-bar'],
  },
  {
    label: 'My Listings',
    value: routeConfig.user.myListings,
    icon: <SellersHub style={{ maxHeight: 24, maxWidth: 24, fill: '#ffffff' }} />,
    scope: ['side-bar'],
  },
  {
    label: 'Sold items',
    value: routeConfig.user.sellingHistory,
    icon: <SoldHistory style={{ fill: '#ffffff' }} />,
    scope: ['side-bar'],
  },
  {
    label: 'Feedback',
    value: routeConfig.user.feedback,
    icon: <Feedback style={{ fill: '#ffffff' }} />,
    scope: ['side-bar'],
  },
  {
    label: 'Messages',
    value: routeConfig.user.messages,
    icon: <Messages style={{ maxHeight: 24, maxWidth: 24, fill: '#ffffff' }} />,
    scope: ['side-bar'],
  },
  {
    label: 'FAQ',
    value: routeConfig.faq.path,
    icon: <FAQICON style={{ fill: '#ffffff' }} />,
    scope: ['top-bar'],
    children: [
      {
        label: 'Seller Information',
        scope: ['top-bar'],
        value: routeConfig.faq.sellerInformation,
        icon: <></>,
      },
      {
        label: 'Buyer Information',
        scope: ['top-bar'],
        value: routeConfig.faq.buyerInformation,
        icon: <></>,
      },
      {
        label: 'Card Knowledge',
        scope: ['top-bar'],
        value: routeConfig.faq.cardKnowledge,
        icon: <></>,
      },
      {
        label: 'Price Data',
        scope: ['top-bar'],
        value: routeConfig.faq.priceData,
        icon: <></>,
      },
      {
        label: 'Discord',
        scope: ['top-bar'],
        value: routeConfig.faq.discord,
        icon: <></>,
      },
      {
        label: 'Site Rules',
        scope: ['top-bar'],
        value: routeConfig.faq.siteRules,
        icon: <></>,
      },
      {
        label: 'Disputes',
        scope: ['top-bar'],
        value: routeConfig.faq.disputes,
        icon: <></>,
      },
    ]
  },
  {
    label: 'Logout',
    value: 'logout',
    icon: <LogoutOutlined style={{ fill: '#ffffff' }} />,
    scope: ['side-bar', 'userName'],
  },
];

export const getUserMenuOptions = (scope: string[]) =>
  accountMenuOptions.filter((i) => scope.some((a) => i.scope.includes(a)));

export default function UserPage() {
  const { logout, getAccessTokenSilently } = useAuthHook();
  const navigate = useNavigate();
  const isMobile = useMedia('(max-width: 800px)');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [selected, setSelected] = useState<string>(window.location.pathname);



  const onSelected = (value: string) => {
    setSelected(value);
    if (value === 'logout') {
      logout();
    } else {
      navigate(value);
    }
  };

  useEffectOnce(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      dispatch(getSellerPaymentServiceOnboardingStatus(token));
      setSelected(location.pathname);
    })();
  });


  const SideBar = () => <div className={classes.sidebar}>
    <div className={classes.contents}>
      <div className={classes.menuItems}>
        {getUserMenuOptions(['side-bar']).map((option, index) => {
          const { label } = option;

          const Icon = (props: any) => {
            const o = { ...option.icon, props: { ...props } };
            return o;
          };

          const labelStyle = {
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
          };

          return (
            <React.Fragment key={index}>
              <button aria-label={option.label} key={index} onClick={() => onSelected(option.value)}>
                <Accent style={{ ...labelStyle }} variant={selected === option.value ? 'primary' : 'secondary'}>
                  <Icon width={24} />
                  <span>{label}</span>
                </Accent>
              </button>
              {option.children && option.children.length > 0 ? option.children.map((child, index) => <button
                aria-label={option.label} key={index} onClick={() => onSelected(child.value)}>
                <Accent style={{ fontSize: 14, marginLeft: 20 }} variant={selected === child.value ? 'primary' : 'secondary'}>
                  <span>{child.label}</span>
                </Accent>
              </button>) : null}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  </div>;




  const options = getUserMenuOptions(['side-bar']).map((option, index) => {
    const { label } = option;

    const Icon = (props: CSSProperties) => {
      const o = { ...option.icon, props: { ...props } };
      return o;
    };
    return (
      <React.Fragment key={index}>
        <button aria-label={option.label} key={index} onClick={() => onSelected(option.value)}>
          <Accent style={{ fontSize: 14 }} variant={selected === option.value ? 'primary' : 'secondary'}>
            <Icon />
            <span>{label}</span>
          </Accent>
        </button>
        {option.children && option.children.length > 0 ? option.children.map((child, index) => <button aria-label={option.label} key={index} onClick={() => onSelected(child.value)}>
          <Accent style={{ fontSize: 14, marginLeft: 20 }} variant={selected === child.value ? 'primary' : 'secondary'}>
            <span>{child.label}</span>
          </Accent>
        </button>) : null}
      </React.Fragment>
    );
  });

  const {
    MobileFooterBar,
    mobileDrawerOpen,
    setMobileDrawerOpen,

  } = useMobileFooterBar();




  if (isMobile)
    return (
      <div>
        <Main className={classes.layout} pageType={PageType.User}>
          <>
            <MiniDrawer content={options} open={mobileDrawerOpen} onToggle={() => setMobileDrawerOpen(!mobileDrawerOpen)}>
            </MiniDrawer>
            <Outlet />
            {MobileFooterBar}
          </>
        </Main>
      </div >
    );
  else
    return (
      <div>
        <Main className={classes.layout} pageType={PageType.User}>
          <>
            <SideBar />
            <Outlet />
          </>
        </Main>
      </div>
    );
}


