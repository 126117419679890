import React, { ChangeEvent, useState } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import styled from 'styled-components';
import { Heading } from '../shared/Heading';
import { HeadingProps } from '../shared/InstructionProps';



export interface FlexBoxProps {
  height?: number | string;
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'space-between' | 'space-around';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  style?: React.CSSProperties;
  columnGap?: number;
  rowGap?: number;
  gap?: number;
  reverseOnMobile?: boolean;
  position?: 'relative' | 'absolute' | 'fixed' | 'sticky';
  stackOnMobile?: boolean;
}

export type SizeType = 'small' | 'medium' | 'large' | 'xSmall' | 'xLarge';

const sizeMappers = (size?: SizeType) => {
  switch (size) {
    case 'xSmall':
      return 'font-size: 9px';
    case 'small':
      return 'font-size: 12px';
    case 'medium':
      return 'font-size: 16px';
    case 'large':
      return 'font-size: 20px';
    case 'xLarge':
      return 'font-size: 24px';
    default:
      return 'font-size: 16px';
  }
};

export const StyledHeading = styled(Heading)`
    font-weight: 700;
    line-height: 24px;
    // font-size: 20px;
    ${(props: HeadingProps) => ({ color: props.color, fill: props.color, fontStyle: props.italic ? 'italic' : 'normal' })}
    ${(props: HeadingProps) => sizeMappers(props.size)}
    ${(props: HeadingProps) => ({ ...props.style })}

 `;



export const FlexBox = styled.div<FlexBoxProps>`
    display: flex;
    border-radius: 8px;
    flex-direction: ${(props: { direction?: string; }) => props.direction || 'row'};
    justify-content: ${(props: { justifyContent?: string; }) => props.justifyContent || 'flex-start'};
    align-items: ${(props: { alignItems?: string; }) => props.alignItems || 'stretch'};
    flex-wrap: ${(props: { flexWrap?: string; }) => props.flexWrap || 'nowrap'};
    gap: ${(props: { gap?: number; }) => props.gap || 0}px;
    column-gap: ${(props: { columnGap?: number; }) => props.columnGap || 0}px;
    row-gap: ${(props: { rowGap?: number; }) => props.rowGap || 0}px;
    height: ${(props: { height?: number | string; }) => props.height || 'auto'};
    position: ${(props: { position?: 'relative' | 'absolute' | 'fixed' | 'sticky'; }) => props.position || 'initial'};
    ${(props) => props.style && { ...props.style }}

    ${(props: { reverseOnMobile?: boolean; }) => props.reverseOnMobile && `
      @media (max-width: 768px) {
        flex-direction: column-reverse;
        width: 100%;
      }`};

    ${(props: { stackOnMobile?: boolean; }) => props.stackOnMobile && `
      @media (max-width: 400px) {
        flex-direction: column;
        
      }
`};

`;


export interface StyledInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  fullWidth?: boolean;
}

const StyledInputWrapper = styled.div<{ $fullWidth?: boolean; }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: ${(props) => (props.$fullWidth ? '100%' : 'auto')};
`;

const StyledCheckboxLabel = styled.label<{ disabled?: boolean; required?: boolean; checked?: boolean; }>`
  padding-left: 4px;
  color: #ffffff40;
  cursor: pointer;
  ${props => props.checked && `
   color: #ffffff;
    `}
`;

export const StyledLabel = styled.label<{ size?: SizeType; }>`
  ${(props) => sizeMappers(props.size)}`;

export const StyledText = styled.span<{ size?: SizeType; textAlign?: 'left' | 'right' | 'center'; color?: string; width?: string; height?: string; padding?: string; }>`
${(props) => props.style && { ...props.style }}
${(props) => props.textAlign && `text-align: ${props.textAlign};`}
${(props) => props.color && `color: ${props.color};`}
${(props) => props.width && `width: ${props.width};`}
${(props) => props.height && `width: ${props.height};`}
${(props) => props.padding && `padding: ${props.padding};`}
${(props) => sizeMappers(props.size)}

`;

const StyledInputLabel = styled.label<{ disabled?: boolean; required?: boolean; }>`
  margin-bottom: 8px;
  color: #ffffff80;
  font-size: 12px;
  position: relative;
  display: inline-block;
  top: 16px;
  padding: 0 4px;
  left: 10px;
  width: fit-content;
  background: #1a2329;
  font-weight: 700;
  line-height: 16px;
  ${props => props.disabled && `
    color: #ffffff40;
    `}
  
  ::after {
    content: ' *';
    ${props => props.required && !props.disabled &&
    `
      color: tomato;
    `}
  }
}
`;

const StyledInput = styled.input`
padding: 8px 12px;
border: none;
border-radius: 4px;
background: transparent;
color: #ffffff;
font-size: 16px;
border: 1px solid transparent;


${props => props.disabled ? `
  color: #ffffff80;
  border-bottom: 1px solid #ffffff20;
  ` : `
  border: 1px solid #ffffff40;
  outline: none;
  
  &:user-invalid {
    border: 1px solid #tomato;
    background: #ff00001a;
  }

  &:hover {
    border-bottom: 1px solid #ffffff80;
  }

  &:focus {
    border-bottom: 1px solid #ffffff;
  }
`}`;



export interface StyledCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const StyledCheckboxWrapper = styled.div`
  display: flex;
`;

const StyledCheckboxInput = styled.input`
  width: 16px;
  height: 16px;
  border: 1px solid #ffffff40;
  background: transparent;
  cursor: pointer;
  appearance: none;

  position: relative;

  &:checked {
    border-color:rgb(152, 255, 249);
  }

  &:checked::before {
    content: '■';
    color: rgb(152, 255, 249);;
    font-size:20px;
    position:absolute;
    bottom: -3px;
    left: 1px;
  }

  &:hover {
        border-color:rgb(152, 255, 249);
  }

  &:focus {
    border-color: #ffffff;
  }

  &[disabled] {
    border-color: transparent!important;
    background: transparent;
    cursor: not-allowed;
    }


`;

export const StyledCheckbox: React.FC<StyledCheckboxProps> = ({ label, ...props }) => {
  return (
    <StyledCheckboxWrapper>
      <StyledCheckboxInput type="checkbox" {...props} />
      {label && <StyledCheckboxLabel checked={props.checked} required={props.required} disabled={props.disabled}
        onClick={() => props.onChange?.({} as ChangeEvent<HTMLInputElement>)}

      >{label}</StyledCheckboxLabel>}
    </StyledCheckboxWrapper>
  );
};



export interface StyledInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  currency?: boolean;
}


const NumberFormatWrapper = (props: NumericFormatProps) => (
  <NumericFormat
    {...props}
    thousandSeparator={true}
    prefix={'$'}
    decimalScale={2}
    fixedDecimalScale={true}
    customInput={StyledInput}
  />
);

export const StyledNumberInput: React.FC<StyledInputProps> = ({ label, currency, ...props }) => {
  return (
    <StyledInputWrapper>
      {label && <StyledInputLabel required={props.required} disabled={props.disabled}>{label}</StyledInputLabel>}
      {currency ? (
        <NumberFormatWrapper {...(props as NumericFormatProps)} />
      ) : (
        <StyledInput {...props} />
      )}
    </StyledInputWrapper>
  );
};

export const StyledTextInput: React.FC<StyledInputProps> = ({ label, fullWidth, style, ...props }) => {
  return (
    <StyledInputWrapper $fullWidth={fullWidth} style={style}>
      {label && <StyledInputLabel disabled={props.disabled} required={props.required}>{label}</StyledInputLabel>}
      <StyledInput {...props} />
    </StyledInputWrapper>
  );
};

export const StyledImg = styled.img`
border-radius: 4px;
`;


export const StyledDivider = styled.div<{ opacity?: number; margin?: number; thickness?: number; orientation?: 'horizontal' | 'vertical'; style?: React.CSSProperties; }>`
width: 100%;
${props => props.style && { ...props.style }}
${props => props.opacity && `opacity: ${props.opacity};`}
${props => props.margin && `margin: ${props.margin}px 0;`}
${props => props.thickness && `border: ${props.thickness}px solid #ffffff;`}
${props => props.orientation === 'vertical' ? 'height: 100%; min-height: 20px' : 'width: 100%; min-width: 20px  '}
`;


export const StyledChip = styled.div<{ color?: string; backgroundColor?: string; borderColor?: string; }>`
display: flex;
align-items: center;
font-weight: 700;
min-width: 80px;
max-width: 180px;
padding: 0 8px;
border-radius: 25px;
color: black;
font-size: 12px;
border: 1px solid #98FFF9;
height: 25px;
${props => props.backgroundColor && `background-color: ${props.backgroundColor};`}
${props => props.color && 'color:' + props.color + ';'}
${props => props.borderColor && 'border-color:' + props.borderColor + ';'}
`;


const CollapsibleWrapper = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 16px;
`;

const CollapsibleHeader = styled.div`
  background-color: transparent;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // color: #ffffff80;
`;

const CollapsibleContent = styled.div<{ isOpen: boolean; }>`
  max-height: ${({ isOpen }) => (isOpen ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: ${({ isOpen }) => (isOpen ? '10px' : '0 10px')};
`;

const Arrow = styled.span<{ isOpen: boolean; }>`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(0)')};
  transition: transform 0.3s ease;
`;

export const StyledList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  `;

export const StyledListItem = styled.li`
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 0.5em;
  list-style-type: none;
  
  &::marker {
    content: "";
}
  &:before {
    content: "-";
    position: absolute;
    left: 0.5em;
    color: #666;
    font-size: 1.2em;
  }`;


interface CollapsibleProps {
  header: string;
  children: React.ReactNode;
}

export const StyledCollapsible: React.FC<CollapsibleProps> = ({ header, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CollapsibleWrapper>
      <CollapsibleHeader onClick={toggleOpen}>
        {header}
        <Arrow isOpen={isOpen}>▶</Arrow>
      </CollapsibleHeader>
      <CollapsibleContent isOpen={isOpen}>{children}</CollapsibleContent>
    </CollapsibleWrapper>
  );
};




