/* eslint-disable no-console */
import React from 'react';
import styles from '../styles/App.module.scss';
import classes from '../styles/marketplace.module.scss';
import { Main, PageType } from '../components/Main';
// import { direction, useScrollDirection } from '../hooks/useScrollDirection';
// import { InsightsSearchBar } from 'src/components/SearchBar/InsightsSearchBar';
// import { useAppSelector } from '../hook';
// import { ChevronLeft, Search } from '@mui/icons-material';
// import { useMedia } from 'react-use';
// import { IconButton } from '@mui/material';
// import { selectCardSearchParameters } from 'src/features/cardSearch/cardSearchSelectors';

import { QuickLinks } from './QuickLinks';
import { useMobileFooterBar } from './useMobileFooterBar';


export function InsightsCenter(props: {
  children?: JSX.Element;
  hideSearchBar?: boolean;
}) {

  const {
    MobileFooterBar
  } = useMobileFooterBar();

  return (
    <Main pageType={PageType.Insights} >
      <div className={styles.main} id='main-insight-center'>
        <div className={classes.mainContent}>
          <div className={styles.searchResults}>
            {props.children}
          </div>

        </div>
        <QuickLinks />
        {MobileFooterBar}

      </div>
    </Main >
  );
}
