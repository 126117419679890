import React, { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';
import { routeConfig } from '../../routes/routeConfig';

import { Feedback } from './Feedback';
import { MyProfile } from './MyProfile';
import { MyAccount } from './MyAccount';
import { MyListings } from './MyListings';
import { PurchaseHistory } from './PurchaseHistory';
import { SellerUserActions } from './seller/SellerUserActions';
import { SoldItems } from './SoldItems';
import { FAQ } from '../faq/FAQ';
import { Messages } from './messaging/Messages';
import { RenderFAQ } from '../faq/faqRenderer';
import { MyPostalSettings } from './MyPostalSettings';
import { PageType } from 'src/components/Main';

const UserPage = lazy(() => import('../../views/UserPage'));

export function userRoutes(this: any) {
  return (
    <React.Fragment>
      <Route
        path={routeConfig.user.path}
        element={
          <Suspense fallback={<>loading...</>}>
            <ProtectedRoute component={UserPage} pageType={PageType.User} />
          </Suspense>
        }
      >
        <Route path={routeConfig.user.profile} element={<MyProfile />} />
        <Route path={routeConfig.user.sellerActions} element={<SellerUserActions />} />
        <Route path={routeConfig.user.myAccount} element={<MyAccount />} />
        <Route path={routeConfig.user.myListings} element={<MyListings />} />
        <Route path={routeConfig.user.feedback} element={<Feedback />} />
        <Route path={routeConfig.user.messages} element={<Messages />} />
        <Route path={routeConfig.user.purchaseHistory} element={<PurchaseHistory />} />
        <Route path={routeConfig.user.sellingHistory} element={<SoldItems />} />
        <Route path={routeConfig.user.onboardingCallback} element={<SellerUserActions />} />
        <Route path={routeConfig.user.postalSettings} element={<MyPostalSettings />} />
      </Route>
    </React.Fragment>
  );
}

// export function FAQRoutes(source: keyof typeof routeConfig) {
export function FAQRoutes() {
  return <React.Fragment>
    <Route path={routeConfig.faq.path} element={<FAQ title="FAQ" url='/content/faq.json' />}>
      <Route path='' element={<RenderFAQ url='/content/faq.json' />} />
      <Route path={routeConfig.faq.sellerInformation} element={<RenderFAQ url='/content/faq/faq-sellerInformation.json' />} />
      <Route path={routeConfig.faq.buyerInformation} element={<RenderFAQ url='/content/faq/faq-buyerInformation.json' />} />

      <Route path={routeConfig.faq.cardKnowledge} element={<RenderFAQ url='/content/faq/faq-cardKnowledge.json' />} />
      <Route path={routeConfig.faq.priceData} element={<RenderFAQ url='/content/faq/faq-priceData.json' />} />
      <Route path={routeConfig.faq.discord} element={<RenderFAQ url='/content/faq/faq-discord.json' />} />
      <Route path={routeConfig.faq.siteRules} element={<RenderFAQ url='/content/faq/faq-siteRules.json' />} />
      <Route path={routeConfig.faq.disputes} element={<RenderFAQ url='/content/faq/faq-disputes.json' />} />
    </Route>
  </React.Fragment>;
}