import React, { useEffect, useState } from 'react';
import classes from '../styles/mobile-footer.module.scss';
import { ReactComponent as Logo } from '../assets/svg/avatar.svg';


import { ReactComponent as MarketplaceIcon } from '../assets/svg/bottombar/marketplace.svg';
import { ReactComponent as Insight } from '../assets/svg/bottombar/data.svg';
import { ReactComponent as PlusIcon } from '../assets/svg/create-listing.svg';
import { ReactComponent as FAQ } from '../assets/svg/user-profile/faq.svg';
import { routeConfig } from '../routes/routeConfig';
import { Menu } from '@mui/icons-material/';
import { useAuthHook } from 'src/shared/hooks/useAuthHook';
import { FlexBox } from 'src/components/styled/StyledComponents';
import { Link } from 'react-router-dom';
import { CartIcon } from 'src/components/HeaderBar';

export function MobileFooterBar(props: {
  onMenuToggle: () => void;
  toggleNotifications: () => void;
  toggleListingType: () => void;

}
) {

  const [
    isSettings
    , setIsSettings] = useState(false);
  const { user } = useAuthHook();

  useEffect(() => {
    setIsSettings(location.pathname.indexOf('user') > -1);
  }, [location.pathname]);

  return (
    <>
      <div className={classes.footer}>



        <FlexBox style={{ columnGap: 16 }} justifyContent='center' alignItems='flex-end'>

          {isSettings && <TextLink text='Menu' onClick={props.onMenuToggle} Icon={() => <Menu />} />
          }
          <Link to={routeConfig.searchResults.path} aria-label='marketplace'>
            <MarketplaceIcon />
          </Link>
          <Link to={routeConfig.insights.path} aria-label='insights'>
            <Insight />
          </Link>

          <a onClick={() => props.toggleListingType()} aria-label='place advert' style={{ cursor: 'pointer' }}>
            <PlusIcon />
          </a>
          <Link to={routeConfig.checkout.root} aria-label='Cart' style={{ position: 'relative' }}>
            <CartIcon />
          </Link>

          <TextLink text='Profile'
            to={routeConfig.user.myAccount}
            Icon={() =>
              <>
                {user?.picture
                  ? < img src={user?.picture} alt='user' className={classes.avatar} />
                  : <Logo style={{ height: 200 }} />
                }
              </>
            } />
        </FlexBox>
      </div >
    </>
  );
}

function TextLink(props: { to?: string; text?: string; Icon: (props: any) => JSX.Element; onClick?: () => void; }) {
  const { Icon } = props;

  const anchorStyle: React.CSSProperties = {
    width: 40,
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    letterSpacing: 0.8,
    fontSize: 6,
  };

  return <a aria-label='link' href={props.to ?? '#'} style={anchorStyle} onClick={props.onClick}>
    <FlexBox style={{ rowGap: 3, height: 51, paddingBottom: 8 }} direction='column' alignItems='center' justifyContent='center'>
      <Icon style={{ height: 25, fill: 'white' }} />
      <span >{props.text}</span>
    </FlexBox>
  </a>;
}


export function DiscordLink(props: { label?: string; }) {
  return <a aria-label='link' href={process.env.REACT_APP_DISCORD_URL} target='_blank' rel="noreferrer" >
    {props.label ? props.label : <FAQ />}
  </a>;
}
