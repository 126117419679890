import { useEffect, useState } from 'react';
import { useZone } from 'src/locales/useLocales';
import { useAuthHook } from 'src/shared/hooks/useAuthHook';
import { toCurrency } from 'src/utils/utilities';

export function useShippingLocation(itemLocation: string, intShippingCharge: number, domesticShippingCharge: number) {
    const { user } = useAuthHook();
    const [isInternational, setIsInternational] = useState<boolean>(false);
    const [shippingCharge, setShippingCharge] = useState<number>(0);
    const [shippingChargeFormatted, setShippingChargeFormatted] = useState<string>('');

    const zone = useZone();
    const getShippingCharge = (itemLocation: string, intShippingCharge: number, domesticShippingCharge: number) => {
        const isIntl = itemLocation?.toLowerCase() !== zone?.name?.toLowerCase();
        const charge = isIntl ? intShippingCharge : domesticShippingCharge ?? 0;
        return charge;
    };


    useEffect(() => {
        const isIntl = itemLocation.toLowerCase() !== zone?.name?.toLowerCase();

        const charge = isIntl ? intShippingCharge : domesticShippingCharge ?? 0;
        setIsInternational(isIntl);
        setShippingCharge(charge);
        setShippingChargeFormatted(toCurrency(charge));

    }, [itemLocation, intShippingCharge, domesticShippingCharge, user, zone]);

    return {
        isInternational,
        shippingCharge,
        shippingChargeFormatted,
        getShippingCharge
    };

}