import React from 'react';
import { IOption } from './AutoComplete';
import classes from '../../styles/select.module.scss';


export interface ISelectProps {
    options: IOption[];
    title?: string;
    className?: string;
    onChange?: (value: IOption | undefined) => void;
    selected?: IOption;
}

export function Select(props: ISelectProps) {
    const onChange = (option: string) => {
        const returnValue = props.options.find(i => i.value == option);
        props.onChange?.(returnValue);
    };

    return <div>
        {props.title && <span style={{ color: '#FFFFFF40' }}>{props.title}</span>}
        <div style={{ height: 25, overflow: 'visible' }} className={classes.customSelect}>
            <select
                value={props.selected?.value?.toString()}
                onChange={(e) => onChange(e.target.value)}>
                {props.options.map((i, index) => <React.Fragment key={`${index}-${i.id}`}>
                    <option value={i.value.toString()}>{i.label}</option>
                    <option disabled style={{ fontSize: 12, userSelect: 'none' }}></option>
                </React.Fragment>)}
            </select>
        </div>
    </div >;

}


