import React, { useEffect, useId, useRef, useState } from 'react';
import { UserPageHeader } from './components/UserPageHeader';
import { ReactComponent as Account } from '../../assets/svg/user-profile/account.svg';
import { LoadingWidget } from '../../components/shared/Loading';
import { FlexBox, StyledCollapsible, StyledHeading, StyledImg, StyledNumberInput, StyledTextInput } from 'src/components/styled/StyledComponents';
import { Information } from 'src/components/shared/Instruction';
import map from 'src/assets/png/map.png';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { ActiveRadioGroup } from 'src/components/shared/RadioButton';
import { useAppDispatch, useAppSelector } from 'src/hook';
import { selectPostageSettings } from './components/userSelectors';
import { getUserPostageSettingsAction, updateUserPostageSettingsAction } from './actions';
import { useEffectOnce } from 'react-use';
import { useAuth0 } from '@auth0/auth0-react';

export interface UserPreferences {
    postage?: PostageSettingsModel;
}

export interface PostageSettingsModel {
    itemLocation?: SuburbPostCodeCountry;
    mailingAddress?: SuburbPostCodeCountry;
    threshold?: string;
    thresholdAmount?: number;
    postageAmountFee?: number;
}


export interface UseUndo<T> {
    value: T;
    set: (newValue: T) => void;
    undo: () => void;
    isDirty: boolean;
}

export function useUndo<T>(initialValue: T): UseUndo<T> {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setIsDirty] = useState(false);

    const undo = () => {
        setValue(initialValue);
        setIsDirty(false);
    };

    const set = (newValue: T) => {
        setValue(newValue);
        setIsDirty(true);
    };

    return { value, set, undo, isDirty };
}

export function MyPostalSettings() {
    const [isEditable, setIsEditable] = useState(false);
    const { user } = useAuth0();
    const dispatch = useAppDispatch();
    const postageSettings = useAppSelector(selectPostageSettings);
    const form = useRef<HTMLFormElement>(null);

    const { undo, value, set } = useUndo(postageSettings.data?.postage);

    const submit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formEntries = Object.fromEntries(formData.entries());
        updatePostalSettings(formEntries);
        setIsEditable(false);
    };

    const updatePostalSettings = async (formEntries: any) => {
        const payload = mapFormValuesToObject(formEntries);
        dispatch(updateUserPostageSettingsAction({ param: { postageSettings: payload as any } }));
    };

    const mapFormValuesToObject = (formEntries: any) => {
        const payload: PostageSettingsModel = {
            itemLocation: {
                suburb: formEntries['itemLocation.suburb'],
                postCode: formEntries['itemLocation.postcode'],
                country: formEntries['itemLocation.country'],
            },
            threshold: formEntries['threshold'],
            thresholdAmount: +formEntries['thresholdAmount'],
            postageAmountFee: +formEntries['postageAmountFee']?.replace('$', ''),
        };
        return payload;
    };

    const cancelEditMode = () => {
        undo();
        setIsEditable(false);
    };

    const toggleEditMode = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (!form.current?.reportValidity()) return;

        if (isEditable) {
            form.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }

        setIsEditable(!isEditable);
    };

    const getPostagePreferences = async () => {



        try {
            if (!postageSettings.isLoading)
                dispatch(getUserPostageSettingsAction(btoa(user?.email ?? '')));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('error', error);
        }
    };

    const onItemLocationChange = (item: SuburbPostCodeCountry) => {
        set({ ...value, itemLocation: item });
    };

    const updateFormValues = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formEntries = Object.fromEntries(formData.entries());
        const payload = mapFormValuesToObject(formEntries);

        if (payload.threshold == 'individual') {
            payload.postageAmountFee = undefined;
            payload.thresholdAmount = undefined;
        }

        set(payload);
    };

    useEffectOnce(() => {
        getPostagePreferences();
    });

    useEffect(() => {
        set(postageSettings?.data?.postage);
    }, [postageSettings.data?.postage]);


    return (
        <FlexBox direction="column" style={{ padding: '20px 60px' }}>
            <UserPageHeader
                title="My Shipping Details"
                pageTitle="Shipping Details"
                icon={<Account height={30} width={30} />}
                showEdit={true}
                isEditable={isEditable}
                onEditClick={toggleEditMode}
                onSaveClick={toggleEditMode}
                onCancelClick={cancelEditMode}
            />

            <form onSubmit={submit} ref={form} onChange={updateFormValues}>
                <LoadingWidget isLoading={false} style={{ marginTop: 40, height: 100 }}>
                    <FlexBox direction="column" rowGap={8}>
                        <Information text="Save time when creating your listing by setting your prefill address" />
                        <Information size='small' text="We use this information to indicate aproximite location of the item only" italic />

                        <SuburbPostCodeCountry name="itemLocation" disabled={!isEditable} value={value?.itemLocation ?? {} as SuburbPostCodeCountry} onChange={onItemLocationChange} style={{ padding: '20px 0' }} />
                        <Divider style={{ borderColor: 'white' }} />

                        <StyledHeading style={{ margin: '8px 0px' }}>My Shipping Charges</StyledHeading>
                        <Information size="medium" text="Configure how you would like to charge your buyers when purchasing multiples of your products." />
                        <StyledHeading size="small" style={{ margin: '8px 0px' }}>Single Card Threshold</StyledHeading>
                        <ActiveRadioGroup
                            name='threshold'

                            style={{ display: 'flex', flexDirection: 'row' }}
                            value={value?.threshold ?? ''}
                            options={[
                                {
                                    label: 'Charge a fixed postage fee per item',
                                    value: 'individual',
                                    count: 0,
                                    id: 'individual',
                                },
                                {
                                    label: 'Flat rate for a set number of items',
                                    value: 'threshold',
                                    count: 0,
                                    id: 'threshold',
                                },
                            ]}
                            disabled={!isEditable}
                        />
                        {value?.postageAmountFee && <Information italic size='small'>{`Charge $${value?.postageAmountFee ?? 0} shipping for up to ${value?.thresholdAmount ?? 0} items.`}</Information>}

                        {value?.threshold != 'individual' &&
                            <FlexBox direction="row" style={{ columnGap: 8 }}>
                                <StyledNumberInput name='postageAmountFee' label='Postage Amount' required={isEditable && value?.threshold != 'individual'} value={value?.postageAmountFee} currency prefix="$" placeholder="Enter Postage Fee" style={{ width: 250 }} disabled={!isEditable || value?.threshold == 'individual'} />
                                <StyledNumberInput type='number' name='thresholdAmount' label='Threshold' required={isEditable && value?.threshold != 'individual'} value={value?.thresholdAmount ?? 0} placeholder="Enter Threshold" style={{ width: 250 }} disabled={!isEditable || value?.threshold == 'individual'} />
                            </FlexBox>
                        }
                    </FlexBox>
                    <StyledCollapsible header="How does the threshold work?" >
                        <Information style={{ padding: '8px 0' }}>
                            By default, we charge a fixed postage fee for each card. With this new setting, you have the ability to charge a postage fee based on what we call a “threshold”.
                        </Information>
                        <Information style={{ padding: '8px 0' }}>
                            Enabling the threshold will allow you to pre-set an automatic postage fee and threshold amount, allowing buyers to be charged a bundled postage fee when purchasing multiple Single Cards in a single transaction.
                        </Information>
                        <Information style={{ padding: '8px 0' }}>
                            For example, if you set your Postage Fee at $10 with a threshold of 10 cards, buyers will be charged $10 for every 10 cards they purchase. So, if a buyer adds 30 cards to their shopping cart, they will be charged $30, as the threshold of 10 cards has been met three times (10 + 10 + 10 = 30 cards... quick maths).
                        </Information>
                        <Information style={{ padding: '8px 0' }}>
                            * If you prefer to charge a fixed postage amount for certain listings, a checkbox option allows you to exclude individual listings, ensuring postage is charged separately.
                        </Information>
                    </StyledCollapsible>
                    <FlexBox justifyContent='flex-end'>
                        {isEditable &&
                            <>
                                <Button type="button" variant="contained"
                                    style={{ marginTop: 20, width: 200 }}
                                    onClick={cancelEditMode}
                                    disabled={!isEditable}>Cancel</Button>
                                <Button type="submit" variant="contained"
                                    style={{ marginTop: 20, width: 200 }}
                                    disabled={!isEditable}>Save</Button>
                            </>
                        }
                    </FlexBox>
                </LoadingWidget>
            </form>
        </FlexBox>
    );
}


interface SuburbPostCodeCountry {
    suburb: string;
    postCode: string;
    country: string;
}

export function SuburbPostCodeCountry(props: {
    onChange?: (item: SuburbPostCodeCountry) => void;
    name?: string;
    disabled?: boolean;
    value: SuburbPostCodeCountry;
    style: React.CSSProperties;
}) {
    const id = props.name ?? useId();

    const [stateValue, setStateValue] = useState<SuburbPostCodeCountry>(props.value);

    const onChange = (key: keyof SuburbPostCodeCountry, value: string) => {
        const newValue = { ...stateValue, [key]: value };
        setStateValue(newValue);
        props.onChange?.(newValue);
    };

    useEffect(() => {
        setStateValue(props.value);
    }, [props.value]);

    return (
        <FlexBox direction="row" style={{ ...props.style }}>
            <FlexBox alignItems='center'>
                <StyledImg src={map} alt="map" style={{ width: 120, height: 120 }} />
            </FlexBox>
            <FlexBox flexWrap="wrap" style={{ padding: '0 8px', columnGap: 8 }}>
                <StyledTextInput
                    required
                    name={`${id}.suburb`}
                    onChange={(e) => onChange?.('suburb', e.target.value)}
                    label="Suburb"
                    placeholder="Suburb"
                    fullWidth
                    disabled={props.disabled}
                    value={props.value?.suburb ?? ''}
                />
                <StyledTextInput
                    required
                    name={`${id}.postcode`}
                    onChange={(e) => onChange?.('postCode', e.target.value)}
                    label="Postcode"
                    placeholder="Postcode"
                    disabled={props.disabled}
                    value={props.value?.postCode ?? ''}
                />
                <StyledTextInput
                    required
                    name={`${id}.country`}
                    onChange={(e) => onChange?.('country', e.target.value)}
                    label="Country"
                    placeholder="Country"
                    disabled={props.disabled}
                    value={props.value?.country ?? ''}
                />
            </FlexBox>
        </FlexBox>
    );
}

export default MyPostalSettings;