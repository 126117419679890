import Divider from '@mui/material/Divider';
import React, { useEffect, useMemo, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { CommonMetaTags, SEOTags } from '../../HelmetSEO';
import { ReactComponent as InsightIcon } from '../../assets/svg/bottombar/data.svg';
import noPhoto from '../../assets/svg/no-photo.svg';
import { Img } from '../../components/Image';
import { ImageDialog } from '../../components/ImageDialog';
import { ImagePanel } from '../../components/ImagePanel';
import { RainbowButton, RainbowInput } from '../../components/shared/Button';
import { ActiveRadioGroup } from '../../components/shared/RadioButton';
import { useAppDispatch, useAppSelector } from '../../hook';
import { IGetMarketAnalyticsParameters } from '../../services/listing-service/listing-service';
import styles from '../../styles/App.module.scss';
import classes from '../../styles/card-insight.module.scss';
import { Accent } from '../../styles/components/Accent';
import { Skeleton } from '../../styles/components/Skeleton';
import { getInsightCardDetails } from '../cardSearch/cardActions';
import { ReportListing } from '../listing/Listing';
import { MarketAnalysis } from '../listing/MarketAnalysis';
import { getMarketAnalyticsSnapshot, getMarketAnalyticsSummary } from '../listing/listingActions';
import { ConfirmDialog } from '../user/ConfirmDialog';
import { reportListing, reportSeller } from '../user/seller/sellerActions';
import { selectSelectedCardDetails } from './insightSelectors';

export function CardInsights() {

    const dispatch = useAppDispatch();
    const { data, isLoading } = useAppSelector(selectSelectedCardDetails);


    const [dialogProps, setDialogProps] = React.useState<{ type: ReportListing; title: string; message: string; notes?: string; } | undefined>();
    const navigate = useNavigate();
    const [areYouSuredialogOpen, setAreYouSureDialogOpen] = React.useState(false);
    const [selectedReportReason, setSelectedReportReason] = React.useState('');
    const [selectedReportReasonOther, setSelectedReportReasonOther] = React.useState('');
    const containerRef = useRef<HTMLDivElement>(null);
    const [meta, setMeta] = React.useState<{ name: string; content: string; }[]>(
        [] as { name: string; content: string; }[]
    );


    useEffectOnce(() => {
        const cardId = location.pathname.split('/')[3];
        dispatch(getInsightCardDetails(cardId));
    });

    const closeAreYouSure = () => {
        setAreYouSureDialogOpen(false);
    };

    const confirmDelete = () => {
        closeAreYouSure();

        if (dialogProps?.type === ReportListing.seller)
            reportBadSeller();
        else if (dialogProps?.type === ReportListing.listing)
            reportBadListing();

        setDialogProps(undefined);
    };

    const reportBadSeller = () => {
        dispatch(reportSeller({ listingId: data?.id, reason: [selectedReportReason, selectedReportReasonOther].filter(i => i != '').join(':') }));
    };

    const [fullScreenImage, setFullScreenImage] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState(0);

    const reportBadListing = () => {
        dispatch(reportListing({ listingId: data?.id, reason: [selectedReportReason, selectedReportReasonOther].filter(i => i != '').join(':') }));
    };



    useEffect(() => {
        if (data && data?.cardId) {
            const grade = 0;

            const options = {
                cardId: data?.cardId,
                cardGrade: grade,
                period: 'snapshot',
            } as IGetMarketAnalyticsParameters;
            dispatch(getMarketAnalyticsSnapshot(options));
            dispatch(getMarketAnalyticsSummary(options));
        }
    }, [data?.cardId]);

    useEffect(() => {
        if (data) {
            const metaArray: { name: string; content: string; }[] = [
                { name: 'name', content: data?.name ?? '' },
                { name: 'series', content: data?.series ?? '' },
                { name: 'set', content: data?.set ?? '' },
                { name: 'number', content: data?.number ?? '' },
                { name: 'illustrator', content: data?.illustrator ?? '' },
                { name: 'rarity', content: data?.rarity ?? '' },
                { name: 'element_type', content: data?.elementType ?? '' }
            ];
            setMeta(metaArray);
        }
    }, [data]);

    const imageList = useMemo(() => {
        if (data) {
            return (data?.imageSrc ?? []);
        }
        return [];
    }, [data?.imageSrc]);



    const cardDetails = useMemo(() => {
        return <div className={classes.cardDetailsAndConditionGroup}>
            <CardDetailField title="Series" value={data?.series ?? ' - '} />
            <CardDetailField title="Set" value={data?.set ?? ' - '} />
            <CardDetailField title="Card Number" value={data?.number ?? ' - '} />
            <CardDetailField title="Rarity" value={data?.rarity ?? ' - '} />
            <CardDetailField title="Pokemon Style" value={data?.cardType ?? ' - '} />
            <CardDetailField title="Tag" value={data?.pokemonTag ?? ' - '} />
            <Divider style={{ border: '1px solid #505659' }} className={classes.gridRowDivider} />
            <CardDetailField title="Hitpoints" value={`${data?.hitPoints?.[0] ?? 0}`} />
            <CardDetailField title="Pokemon Type" value={data?.elementType ?? ' - '} />
            <CardDetailField title="Weaknesses" value={`${data?.weaknessType} x ${data?.weaknessAmount}`} />
            <CardDetailField title="Resistance" value={`${data?.resistType} ${data?.resistAmount ? ' x ' + data?.resistAmount : ''}`} />
            <CardDetailField title="Retreat" value={`${data?.illustrator}`} />
            <CardDetailField title="Release Date" value={`${data?.releaseDate}`} />
        </div>;

    }, [data]);


    const [seoDescription, setSeoDescription] = React.useState<string>('');

    useEffect(() => {

        if (!data) return;

        let desc = data?.abilityDescription1 ?? '';
        desc += data?.resistDescription ?? '';

        if (desc.length === 0)
            desc = `${data?.name ?? ''} from series ${data?.series ?? ''} and set ${data?.set ?? ''}`;

        setSeoDescription(desc);


    }, [data?.abilityDescription1, data?.resistDescription]);


    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SEOTags position={11}>
                <CommonMetaTags
                    title='Midnight Merchant'
                    description={seoDescription}
                    keywords={['Midnight Merchant', 'Search Results', data?.series, data?.set, data?.name]}
                    imageUrl={imageList[0]}
                    amount={data?.marketSummary?.[0]?.sold_price_median_3_roll?.toString()}
                    targetType={'product'} />
                {meta.map((m, index) => <meta key={index} name={m.name} content={m.content} />)}
            </SEOTags>
            <div className={classes.headerTitle} style={{ alignSelf: 'center', width: '100%', maxWidth: 1050 }}>
                <Accent><div style={{ display: 'flex', fontSize: 24, alignItems: 'center', columnGap: 8 }}><InsightIcon style={{ fill: '#98FFF9', height: 26 }} /><span>Insights</span></div></Accent>
                <Divider style={{ border: '1px solid #505659' }} />
                <div style={{ padding: 8 }}>
                    {'<'} <Link to="/insights" style={{ fontSize: 'smaller' }}>{'back to search results'}</Link>
                </div>
            </div>
            <div className={classes.content} ref={containerRef}>
                <div className={classes.card}>
                    <div className={classes.leftContainer}>
                        <div className={classes.imageContainer}>
                            <Img src={data?.imageSrc?.[0]} fallback={noPhoto} style={{ height: 475 }} alt={data?.name} />
                        </div>
                        <div className={classes.actionButtons}>
                            <RainbowButton onClick={() => navigate(`/search/${data?.name}`)}
                                disabled={data?.listings.numFound === 0} variant='secondary-outline'><div style={{ width: '100%', padding: 8, display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}><span>{data?.listings.numFound}</span><span>Listing/s Available</span> <span>{'>'}</span></div></RainbowButton>
                            <RainbowButton disabled={true} variant='secondary-outline'><div style={{ width: '100%', padding: 8, display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}><span></span><span>Place Advert</span> <span>{'+'}</span></div></RainbowButton>
                        </div>
                    </div>
                    <div className={classes.details}>

                        <div className={classes.background}>

                            <div className={classes.title}>
                                <Skeleton skelType="txt" loading={isLoading}>
                                    <div>
                                        <div className={classes.name}>{data?.name}</div>
                                    </div>
                                </Skeleton>
                            </div>

                            {cardDetails}
                            <Divider style={{ border: '1px solid #505659' }} />
                            <Divider style={{ border: '1px solid #505659' }} />

                            <Skeleton skelType="txtArea" loading={isLoading}>
                                <MarketAnalysis />
                            </Skeleton>
                        </div>
                    </div>
                    {/* {SellerListings} */}
                </div>
                <div className={classes.leftd}>
                    <ImagePanel className={styles.imagePanel} />
                </div>
                <ConfirmDialog
                    open={areYouSuredialogOpen}
                    onClose={closeAreYouSure}
                    onConfirm={confirmDelete}
                    message={dialogProps?.message}
                    title={dialogProps?.title}>
                    <ActiveRadioGroup
                        onChange={(event) => {
                            setSelectedReportReason(event.target.value);
                            setSelectedReportReasonOther('');
                        }}
                        value={selectedReportReason}
                        aria-labelledby="reason-options"
                        aria-orientation="vertical"
                        name="reason-options"
                        options={[
                            { label: 'Listing is Spam', value: 'spam', count: 0, id: '0' },
                            { label: 'Fake listing', value: 'fake', count: 0, id: '1' },
                            { label: 'Card image mismatch', value: 'mismatch', count: 0, id: '2' },
                            { label: 'Offensive language', value: 'offensive', count: 0, id: '3' },
                            { label: 'Inappropriate title', value: 'inapproptiate_title', count: 0, id: '4' },
                            { label: 'Report Abuse', value: 'abuse', count: 0, id: '5' },
                            { label: 'Report Other', value: 'other', count: 0, id: '99' }
                        ]} />

                    {selectedReportReason == 'other' && <RainbowInput style={{ height: 60 }}
                        variant='outlined'
                        value={selectedReportReasonOther}
                        onChange={(event) => {
                            setSelectedReportReasonOther(event.target.value);
                        }} />}
                </ConfirmDialog>
                <ImageDialog
                    selectedImage={selectedImage}
                    selectedImageChanged={(index: number) => {
                        setSelectedImage(index);
                    }}
                    imageList={imageList}
                    show={fullScreenImage}
                    onClose={() => {
                        setFullScreenImage(false);
                    }} />

            </div>

        </div >);
}



export function CardDetailField(props: { title: string; value: string; }) {
    return <div>
        <Accent style={{ margin: 'unset' }}>{props.title}</Accent>
        <div>{props.value}</div>
    </div >;
}