import {
  AnyAction,
  createListenerMiddleware,
  ListenerEffectAPI,
  PayloadAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { publishListing, updateDraftListing } from '../../features/listingSearch/searchActions';
import {
  setCurrentListing,
  setListingError,
  setListingType,
  clearCurrentListing,
  setCreateListingFeeTable,
  setLoadingStatus,
} from '../../features';
// import { Auth0Client } from '@auth0/auth0-spa-js';
import { IStoreState } from '../IStore';
import { removeSessionItem } from '../../hooks/useSessionStorage';
import { StorageKey } from '../Storage';
import { getFeeTable } from '../../features/createListing/createListingAction';
import { IListing } from 'src/models/create-listing/iListing';

export const createListingMiddleware = createListenerMiddleware();

type ListenerEffect = ListenerEffectAPI<
  unknown,
  ThunkDispatch<unknown, unknown, AnyAction>,
  unknown
>;

type ListenerCallback = (action: any, listenerApi: ListenerEffect) => void;

const listener = (actionCreator: any, callBack: ListenerCallback) =>
  createListingMiddleware.startListening({
    actionCreator: actionCreator,
    effect: (action, listenerApi) => {
      listenerApi.cancelActiveListeners();
      callBack(action, listenerApi);
    },
  });

const getState = async (listenerApi: ListenerEffect) => {
  return (await listenerApi.getState()) as IStoreState;
};

const _submitListing = async (action: any, listenerApi: any) => {
  const currentState = await getState(listenerApi);
  listenerApi.dispatch(setLoadingStatus(true));
  listenerApi.dispatch(updateDraftListing({ createListing: currentState.createListing.currentListing }));
};

const afterSetListingType = async (action: any, listenerApi: any) => {
  _submitListing(action, listenerApi);
};

const createListingMiddlewareListeners = {
  setListingType: listener(setListingType, afterSetListingType),
  createDraftListingError: createListingMiddleware.startListening({
    actionCreator: updateDraftListing.rejected,
    effect: (action, listenerApi) => {
      listenerApi.cancelActiveListeners();
      listenerApi.dispatch(setLoadingStatus(false));
      listenerApi.dispatch(setListingError(JSON.stringify(action.payload)));
    },
  }),
  createDraftListing: createListingMiddleware.startListening({
    actionCreator: updateDraftListing.fulfilled,
    effect: async (action: PayloadAction<IListing>, listenerApi) => {
      listenerApi.cancelActiveListeners();
      listenerApi.dispatch(setLoadingStatus(false));
      listenerApi.dispatch(setCurrentListing(action.payload));
    },
  }),
  publishListing: createListingMiddleware.startListening({
    actionCreator: publishListing.fulfilled,
    effect: async (action, listenerApi) => {
      listenerApi.cancelActiveListeners();
      listenerApi.dispatch(clearCurrentListing());
      listenerApi.dispatch(removeSessionItem(StorageKey.createListing));
    },
  }),
  listingFeeTable: createListingMiddleware.startListening({
    actionCreator: getFeeTable.fulfilled,
    effect: async (action, listenerApi) => {
      listenerApi.cancelActiveListeners();
      listenerApi.dispatch(setCreateListingFeeTable(action.payload));
    }
  }),
};

export const { createDraftListingError: createListingError } =
  createListingMiddlewareListeners;
