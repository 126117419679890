import { useEffect, useState } from 'react';
import { listingAutoComplete, searchListings } from '../features/listingSearch/searchActions';

import {
    selectTotalListings,
    selectListingSearchResults,
    selectListingSearchParameters,
    selectIsLoadingListings,
    selectAutocompleteResults,
    selectTotalSearchResults
} from '../features/listingSearch/listingSearchSelectors';

import { useAppDispatch, useAppSelector } from '../hook';
import { IAutocompleteResult } from '../models/iCardSearchState';
import { setListingSearchSortOrder } from 'src/features/listingSearch/listingSearchSlice';


export function useListings(withMap?: (array: Array<IAutocompleteResult>) => any) {

    const dispatch = useAppDispatch();
    const totalListingCount = useAppSelector(selectTotalListings);
    const autoCompleteResults = useAppSelector(selectAutocompleteResults);
    const searchResults = useAppSelector(selectListingSearchResults);
    const totalSearchResults = useAppSelector(selectTotalSearchResults);
    const searchParmeters = useAppSelector(selectListingSearchParameters);
    const loading = useAppSelector(selectIsLoadingListings);
    const [sortValue, setSortValue] = useState<string | undefined>('0');
    const [currentPage, setCurrentPage] = useState<number>(0); // this should probably be a ref given how many times this is used

    useEffect(() => {
        dispatch(searchListings(0));
    }, [searchParmeters.searchParams?.sortOrder]);

    const setSortOrder = (sortOrder?: string) => {
        setSortValue(sortOrder);
        if (sortOrder == '0') sortOrder = '';
        dispatch(setListingSearchSortOrder(sortOrder ?? ''));
    };

    const autocomplete = async (searchTerm: string) => {
        await dispatch(listingAutoComplete(searchTerm));
    };

    const getListings = async (page: number) => {
        dispatch(searchListings(page));
    };

    return {
        totalListingCount,
        searchResultsCount: totalSearchResults,
        listings: searchResults,
        getListings,
        loading,
        searchParmeters,
        currentPage,
        setCurrentPage,
        autocomplete,
        setSortOrder,
        sortValue,
        autoCompleteResults: withMap?.(autoCompleteResults) ?? autoCompleteResults
    };
}
