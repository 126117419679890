import { StateConfig } from 'state-mechanic';
import { IListing } from '../../models/create-listing/iListing';
import { ListingType } from '../../components/tiles/Tiles';
import { ShippingChargeType } from '../../features/createListing/Shipping';



// const hideWhenListingTypeis = (listingType: ListingType) => (model?: IListing) => {
//     if (model?.listingType) {
//         return listingType.toString() !== model.listingType;
//     }
//     return false;
// };

const hideWhenListingTypeisNot = (listingType: ListingType) => (model?: IListing) => {
    if (model?.listingType) {
        return listingType.toString() !== model.listingType;
    }
    return false;
};

const hideWhenListingTypeisNotIn = (listingTypes: ListingType[]) => (model?: IListing) => {
    if (model?.listingType) {
        return !listingTypes.includes(model.listingType as ListingType);
    }
    return false;
};



export const stateConfigV2: StateConfig<IListing> = {
    'listing-type': {
        name: 'listing-type',
        displayName: 'Listing Type',
        validate: (model) => {
            return !!model.listingType;
        },
        state: {
            title: {
                name: 'title',
                displayName: 'Search',
                validate: (model) => {
                    return !!model.title;
                },
                hide: hideWhenListingTypeisNot(ListingType.mixedBundle)
            },
            tags: {
                name: 'tags',
                displayName: 'tags',
                validate: (model) => {
                    return !!model.title;
                },
                hide: hideWhenListingTypeisNot(ListingType.mixedBundle)
            },
            search: {
                name: 'search',
                displayName: 'Search',
                hide: hideWhenListingTypeisNotIn([ListingType.singleCard, ListingType.sealedSingle]),
                validate: (model) => {
                    return !!model.card;
                },
            },
            condition: {
                name: 'condition',
                displayName: 'Condition',
                hide: hideWhenListingTypeisNotIn([ListingType.singleCard, ListingType.sealedSingle]),
                validate: (model) => {
                    return (
                        !!model.graded &&
                        !!model.grade &&
                        !!model.grade?.grade &&
                        !!model.grade?.grade.value &&
                        !!model.grade?.grade.company
                    );
                },
            },
        }
    },

    'upload-images': {
        name: 'upload-images',
        displayName: 'Upload',
        validate: (model) => {
            const images = model.images && Object.keys(model.images);
            if (images?.length > 0) {
                return images.some((i) => model.images[i].name);
            }
            return false;
        },
    },
    description: {
        name: 'description',
        displayName: 'Description',
        validate: (model) => {
            return !!model.description;
        },
    },
    shipping: {
        name: 'shipping',
        displayName: 'Shipping',
        validate: (model) => {
            if (model.shipping?.bundled && !model.shipping?.excludeFromShippingThreshold) {
                return true;
            }

            if (model.shipping?.domesticShippingCharge) {
                return true;
            }

            if (model.shipping?.shippingChargeType === ShippingChargeType.freePostage) {
                return true;
            }

            // if (model.shipping?.shippingChargeType === ShippingChargeType.chargePostage &&
            //     model.shipping.shippingType === ShippingType.domestic &&
            //     !!model.shipping?.domesticShippingCharge &&
            //     !model.shipping?.internationalShippingCharge
            // ) {
            //     return true;
            // }

            // if (model.shipping?.shippingChargeType === ShippingChargeType.chargePostage &&
            //     model.shipping.shippingType === ShippingType.domesticInternational &&
            //     !!model.shipping?.domesticShippingCharge &&
            //     !!model.shipping?.internationalShippingCharge
            //     // model.shipping.address?.fullAddress
            // ) {
            //     return true;
            // }


            // if (model.shipping?.shippingChargeType === ShippingChargeType.freePostage && model.shipping.address?.fullAddress) {
            //     return true;
            // }

            return false;
        },
    },
    transaction: {
        name: 'transaction',
        displayName: 'Transaction',

        validate: (model) => {
            return !!model.buyItNowPrice && +model.buyItNowPrice > 0;
        },

    },
    'register-payment-service': {
        name: 'register-payment-service',
        displayName: 'Payment',
        hide: (model) => {
            return model?.canBePaid == true;
        },
        validate: () => {
            return true;
        }
    },
    preview: {
        name: 'preview',
        displayName: 'Preview',
        validate: (model) => {
            return !!model.recaptchaToken;
        }
    },
};


