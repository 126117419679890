import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { error } from '../../../actions/logActions';
import { getPurchaseHistoryService, sendMessageService, submitSellerRatingService } from '../../../services/listing-service/listing-service';
import { mapToSearchResultItem } from '../../../services/search-service/mapper';
import { IGraphSearchResult } from '../../../services/search-service/SolrSearchResults';
import { IMessage } from '../../../components/MessageDialog';


interface IBuyerActions {
  getPurchaseHistory: AsyncThunk<any, any, any>;
  submitSellerRating: AsyncThunk<any, IUserRating, any>;
  sendMessage: AsyncThunk<any, IMessage, any>;
}

export interface IUserRating {
  listingId: string;
  speedOfShipping: number;
  accuracyOfDescription: number;
  communication: number;
  comment: string;
  timestamp: Date;
}

const actions: IBuyerActions = {
  sendMessage: createAsyncThunk('api/sendMessage', async (message: IMessage, args: any) => {
    try {
      if (!message) {
        return args.rejectWithValue('No message provided');
      }

      const response = await sendMessageService(message);
      if (!response?.err) {
        return response.result;
      }
    }
    catch (e: any) {
      args.rejectWithValue(e);
      error('USER_SEND_MESSAGE_ERROR', { errorMessage: e.message });
    }

    return args.rejectWithValue('We are having issues,  please contact us on discord');
  }),
  getPurchaseHistory: createAsyncThunk('api/purchaseHistory', async (userId: string, args: any) => {
    try {
      if (!userId) {
        return args.rejectWithValue('we could not fetch your purchase history, please contact us on discord');
      }
      const response = await getPurchaseHistoryService(userId);

      if (!response?.err) {
        const returnResult: IGraphSearchResult = {
          records: await mapToSearchResultItem(response?.result?.response.docs || []),
          totalRecords: response?.result?.response.numFound || 0,
          start: response?.result?.response.start || 0,
        };
        return returnResult.records;
      }

      if (!response) {
        return args.rejectWithValue('Error creating item order');
      }
      return response.err;
    } catch (e: any) {
      args.rejectWithValue(e);
      error('USER_GET_SELLER_LISTINGS_ERROR', { errorMessage: e.message });
      return args.rejectWithValue('Error creating item order');
    }
  }),
  submitSellerRating: createAsyncThunk('api/updateUserRating', async (rating: IUserRating, args: any) => {
    try {
      const response = await submitSellerRatingService(rating);

      if (!response?.err) {
        return response.result;
      }

      return args.rejectWithValue('We ran into a problem submitting your rating, please try again later, or come chat to us on discord.');
    } catch (e: any) {
      error('USER_SET_SELLER_RATING', { errorMessage: e.message });
      return args.rejectWithValue('We ran into a problem submitting your rating, please try again later, or come chat to us on discord.');
    }
  }),
};

export const { getPurchaseHistory, submitSellerRating, sendMessage } = actions;
