import React, { ComponentType } from 'react';
import { WithAuthenticationRequiredOptions, useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { EmailVerification } from './views/EmailVerification';
import { Main, PageType } from './components/Main';

export interface ProtectedRouteProps<P extends object> {
  component: ComponentType<P>;
  args?: WithAuthenticationRequiredOptions;
  pageType: PageType;
}

const ProtectedRoute: <T extends object>(props: ProtectedRouteProps<T>) => JSX.Element = <T extends object>(
  props: ProtectedRouteProps<T>
) => {
  const Component: any = withAuthenticationRequired(props.component, props.args);

  const { user, isAuthenticated } = useAuth0();

  if (isAuthenticated && !user?.email_verified)
    return <Main pageType={props.pageType}>
      <EmailVerification />
    </Main>;

  return <Component />;
};

export default ProtectedRoute;
