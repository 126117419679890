import { error } from '../actions/logActions';
import { ReCaptchaAction, getRecapToken } from '../helpers/recaptcha';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { IOptionalServiceResponse } from './ServiceResponse';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { User } from '@auth0/auth0-react';

let auth0: Auth0Client;

const getAuthClient = async () => {
  if (!auth0)

    auth0 = new Auth0Client({
      useRefreshTokens: true,
      domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
      useRefreshTokensFallback: true,
      cacheLocation: 'localstorage',
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
        scope: process.env.REACT_APP_AUTH0_SCOPE || '',
        useRefreshTokens: true,
        cacheLocation: 'localstorage'
      }
    });

  return auth0;
};


export const getUser = async () => {

  if (!auth0)
    getAuthClient();

  let token: User | undefined;
  try {
    token = await auth0.getUser();
  } catch (ex: any) {
    // eslint-disable-next-line no-console
    console.info(ex);
  }

  return token;
};


export const getAuthToken = async () => {
  if (!auth0)
    getAuthClient();

  let token = '';
  try {
    token = await auth0.getTokenSilently();
  } catch (ex: any) {
    // eslint-disable-next-line no-console
    console.info(ex);
  }

  return token;
};

const del = async function deleteAsync<TResult>(
  url: string,
  headers: any,
  action: ReCaptchaAction,
  useAuth = true) {
  const result: {
    result?: TResult;
    err?: any;
  } = {};

  let response: AxiosResponse<any>;
  try {
    const token = await getRecapToken(action);
    if (!token) {
      throw new Error('invalid-token');
    }

    let auth = '';
    if (useAuth) {
      auth = await getAuthToken();
      headers ??= {} as { [key: string]: string; };

      headers['Authorization'] = `Bearer ${auth}`;
    }

    const requestOptions: AxiosRequestConfig = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        // 'Cache-Control': 'public, max-age=31536000',
        // 'connection': 'keep-alive',
        'x-cap-tok': token,
        ...headers,
      },
    };

    // response = await fetch(url, requestOptions);
    response = await axios.delete(url, requestOptions) as AxiosResponse<any>;

    if (response.status == 200 || response.status == 201) {
      result.result = await response.data;
    } else {
      result.err = await response.data;
    }
  } catch (ex: any) {
    result.err = ex?.message ?? ex;
    // eslint-disable-next-line no-console
    error('POST_ERROR', { errorMessage: ex?.message ?? ex ?? 'Error deleting data' });
  }
  return result;


};




type Post = <TResult, TBody>(url: string, body: TBody, headers: { [key: string]: string | number; } | undefined, action: ReCaptchaAction, useAuth?: boolean, _t?: string | undefined) => Promise<{ result?: TResult; err?: any; }>;



const post: Post = async function post<TResult, TBody = any>(
  url: string,
  body: TBody,
  headers: { [key: string]: string | number; } | undefined,
  action: ReCaptchaAction,
  useAuth = true,
  _t: string | undefined = undefined
) {

  const result: {
    result?: TResult;
    err?: any;
  } = {};

  let response;
  try {
    const token = await getRecapToken(action);
    if (!token) {
      throw new Error('invalid-token');
    }
    if (useAuth) {
      const auth = _t ?? await getAuthToken();
      headers ??= {} as { [key: string]: string; };

      headers['Authorization'] = `Bearer ${auth}`;
    }

    response = await axios.post(url, body, {
      validateStatus: () => true,
      headers: {
        'Content-Type': 'application/json',
        'x-cap-tok': token,
        ...headers,
      },
    });

    if (response.status == 200 || response.status == 201) {
      result.result = response.data;
    } else {
      result.err = await response.data.error ?? 'Error posting data';
    }
  } catch (ex: any) {
    result.err = ex?.message ?? ex ?? 'Error posting data';
    // eslint-disable-next-line no-console
    console.error(response?.data, response, ex?.message, ex);
    error('POST_ERROR', { errorMessage: ex?.message ?? ex ?? 'Error posting data' });
  }


  return result;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const get = async <T>(
  url: string,
  headers?: { [key: string]: string; },
  action?: ReCaptchaAction,
  useAuth = true,
  _t: string | undefined = undefined
) => {

  const result: IOptionalServiceResponse<T> = {
    result: {} as T,
    err: undefined,
  } as IOptionalServiceResponse<T>;

  let response;
  try {
    const token = await getRecapToken(action ?? ReCaptchaAction.api_get);

    if (useAuth) {
      const auth = _t ?? await getAuthToken();
      headers ??= {} as { [key: string]: string; };
      headers.Authorization = `Bearer ${auth}`;
    }

    response = await axios.get(url, {
      headers: {
        'x-cap-tok': token,
        ...headers,
      },
    });

    result.result = await response.data;

  } catch (ex: any) {
    result.err = ex?.message ?? ex ?? 'Error getting data from: ' + url;
    // eslint-disable-next-line no-console
    console.error(response?.data, response, ex?.message, ex);
    error('GET_ERROR', { errorMessage: ex?.message ?? ex ?? 'Error getting data from: ' + url });
  }
  return result;
};

const searchServices = {
  post,
  get,
  del
};







export const { post: postAsync, get: getAsync, del: deleteAsync } = searchServices;
