/* eslint-disable no-console */
import React from 'react';
import styles from '../styles/App.module.scss';
import classes from '../styles/marketplace.module.scss';
import { Main, PageType } from '../components/Main';

import { QuickLinks } from './QuickLinks';
import { useMobileFooterBar } from './useMobileFooterBar';

export function Marketplace(props: {
  children?: JSX.Element;
  hideSearchBar?: boolean;
}) {

  const {

    MobileFooterBar
  } = useMobileFooterBar();

  return (
    <Main pageType={PageType.Marketplace}>
      <div className={styles.main} id='main-marketplace'>
        <div className={classes.mainContent}>
          <div className={styles.searchResults} >
            {props.children}
          </div>
        </div>
        <QuickLinks />
        {MobileFooterBar}
      </div>
    </Main >
  );
}


