/* eslint-disable no-useless-escape */
import { Filter } from '../../models/filter';
import { FilterGroups } from '../../models/filterGroup';
import { WritableDraft } from 'immer/dist/internal';
import { IStoreState } from '../../stores/IStore';
import { ISearchParameters } from './ISearchParameters';
import { error } from '../../actions/logActions';

const escapeSolrSpecialCharsRegex = /(\+)|(\-)|(\&\&)|(\|\|)|(\!)|(\()|(\))|(\{)|(\})|(\[)|(\])|(\^)|(\")|(\~)|(\*)|(\?)|(\:)|(\/)|(\')|(\$)|(\%)/gm;
const removeCharsRegex = /(\')/gm;


export const getSelectedDataFilters = (filter: Filter) => {

    const reduceFilters = (f: Filter) => {
        const filters = Array.from(Object.keys(f.filters));
        return filters.reduce((acc: Array<string>, k: string) => {
            if (f.filters?.[k]?.selected) {

                const childFilters = reduceFilters(f.filters[k]);
                if (!childFilters || childFilters.length === 0) {
                    acc.push(f.filters[k].path.join('-'));
                } else {
                    acc.push(...childFilters);
                }
            }
            return acc;
        }, []);
    };
    return reduceFilters(filter);
};



// add _alias to apim search facet fields for parent fields that have the same name as child fields
export const searchFiltersToDictionary = (filters: Filter) => {
    const reduceFilters = (f: Filter) => {
        const filters = Array.from(Object.keys(f.filters));


        const result = filters.reduce((acc: Array<any>, k: string) => {
            const filt = f.filters?.[k];
            if (filt?.selected) {
                const childFilters = reduceFilters(f.filters[k]);

                // if (filt.type !== filt.value) {
                if (!filt.isRoot) {
                    acc.push({ name: filt.type, value: filt.label });
                }

                if (childFilters.length > 0) {
                    // const children = childFilters; //.filter(i => i.name != filt.type);
                    acc.push(...childFilters);
                }
            }
            return acc;

        }, []);
        return result;
    };

    return reduceFilters(filters);
};


export const getListingSearchParameters = (state: IStoreState, searchType: SearchType = SearchType.listings) => {
    const { listingSearchParameters, filterData, activeFilter } = state.listingSearch;

    return {
        query: toSolrQueryFormat('search_all', listingSearchParameters?.autoCompleteSearchString ?? '') || '*:*',
        queryFilter: toSolrFilterFormat(filterData, activeFilter, searchType) || '*:*',
        currentPage: listingSearchParameters?.currentPage ?? 0,
        activeFilter: activeFilter
    } as ISearchParameters;
};

export const getCardSearchParameters = (state: IStoreState, searchType: SearchType = SearchType.listings) => {
    const { cardSearchParameters, filterData, activeFilter } = state.cardSearch;

    return {
        query: toSolrQueryFormat('search_all', cardSearchParameters?.autoCompleteSearchString ?? '') || '*:*',
        queryFilter: toSolrFilterFormat(filterData, activeFilter, searchType) || cardSearchParameters.filterString || '*:*',
        currentPage: cardSearchParameters?.currentPage ?? 0,
        activeFilter: activeFilter
    } as ISearchParameters;
};

const escapeSolrSpecialChars = (value: string) =>
    value.replace(escapeSolrSpecialCharsRegex, '').replace(removeCharsRegex, '');

export enum SearchType {
    filters = 'filters',
    listings = 'listings',
    cards = 'cards'
}


/**
 * 
 * @param filter 
 * @returns a string formatted as a SOLR  filter query or fq parameter.
 */
function toSOlRFilterQuery(filter: Filter, activeFilter: string[], searchType: SearchType): string {

    const mapFilters = (filterGroup: FilterGroups) => {
        const filt: string[] = Object.keys(filterGroup).reduce((acc: Array<string>, curr: string) => {
            const f = filterGroup[curr];

            if (activeFilter.join('') == f.path.join('') && searchType === SearchType.filters) {
                return acc;
            }

            const thisArray = [];
            let primaryFilter = '';

            if (f.selected && f.value !== f.type) {
                primaryFilter = `${f.type}_str:\\"${f.altLabel}\\"`;
                primaryFilter += ` OR ${f.type}:\\"${f.value}\\"`;

            }

            if (f.selected) {
                const fltr = mapFilters(f.filters).filter(f => f.length > 0);

                if (fltr.length > 0 && primaryFilter) {
                    thisArray.push(`(${[primaryFilter, ...fltr].join(' OR ')})`);
                }
                else if (fltr.length > 0 && !primaryFilter) {
                    thisArray.push(`(${[...fltr].join(' OR ')})`);
                }
                else if (primaryFilter) {
                    thisArray.push(`${primaryFilter}`);
                }

                acc = [...acc, ...thisArray];
            }

            return acc;
        }, [] as Array<string>);
        return filt;

    };

    const results = mapFilters(filter.filters).join(' AND ');
    return results;
}


export function stringToSOLRQuery(searchField: string, searchString: string) {
    searchString = escapeSolrSpecialChars(searchString?.trim() ?? '');

    if (searchString === '') {
        return '*:*';
    }


    const template = `(${searchField}:{search})`;
    const searchOperators = ['{value}', '{value}*', '*{value}*'];

    const fullQuery = searchOperators.map((i) =>
        i.replace('{value}', `${escapeSolrSpecialChars(searchString.trim())}`))
        .map((i, index) => template.replace('{search}', i).concat(`^=${25 / (index + 1)}`))
        .join(' OR ');


    const query = searchString?.trim().split(' ')
        .filter(i => i)
        .reduce((p, c) =>
            p += `(number:${escapeSolrSpecialChars(c)})^=6 OR (${searchField}:${escapeSolrSpecialChars(c)})^=5 OR (${searchField}:${escapeSolrSpecialChars(c)}*)^=1`, '')
        .trim();

    if (!query)
        return '';
    else {
        return fullQuery + ' OR ' + query;
    }

}


export const dirtyFiltersCount = (filters: FilterGroups): number => {
    return Object.keys(filters).reduce((p, c) => {
        const f = filters[c];
        if (f.isDirty) {
            p++;
        }
        p + dirtyFiltersCount(f.filters);
        return p;
    }, 0);
};


export const topLevelCategoriesSelectedCount = (filters: FilterGroups): Array<string> => {
    return Object.keys(filters).reduce((p, c) => {
        const f = filters[c];
        if (f.selected && !f.isDirty) {
            p.push(c);
        }
        return p;
    }, [] as Array<string>);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const setFilterCounts = (listingCategories: FilterGroups, currentFilterState: WritableDraft<FilterGroups>): WritableDraft<FilterGroups> => {

    const keys = Array.from(new Set([...Object.keys(currentFilterState), ...Object.keys(listingCategories)]));

    return keys
        .reduce((filterAccumulator: FilterGroups, curr: string) => {
            try {

                // if (curr == 'isRoot') return filterAccumulator;

                if (listingCategories[curr]?.path?.join('') === filterAccumulator[curr]?.path?.join('')) {

                    // Set readonly count here once
                    if (!listingCategories[curr]?.count) {
                        filterAccumulator[curr].count = listingCategories[curr].count;
                    }

                    // Set the search count here, this applies counts to all filters where the filter search contains a filter value.
                    filterAccumulator[curr].searchCount = listingCategories[curr].count;
                    setFilterCounts(listingCategories[curr].filters, filterAccumulator[curr].filters);
                }
                else {
                    if (!listingCategories[curr]) {
                        filterAccumulator[curr].searchCount = 0;
                        setFilterCounts({}, filterAccumulator[curr]?.filters);
                        return filterAccumulator;
                    }

                    filterAccumulator[curr] = listingCategories[curr];

                    setFilterCounts(listingCategories[curr]?.filters, filterAccumulator[curr]?.filters);
                }

            } catch (e: any) {
                // eslint-disable-next-line no-console
                error('SET_FILTER_COUNTS_ERROR', { errorMessage: e?.message ?? e ?? 'Error setting filter counts' });
            }

            return filterAccumulator;
        }, currentFilterState);
};


export const clearDirtyFilters = (filter?: WritableDraft<Filter>) => {
    if (!filter) return;

    const mapFilters = (filterGroup: FilterGroups) => {
        Object.keys(filterGroup).reduce((acc, curr) => {
            const f = filterGroup[curr];

            if (f.isDirty) {
                // the user may have selected a filter and then deselected it, which one is the right one ?
                f.selected = !f.selected;
                f.isDirty = false;
            }

            mapFilters(f.filters);
            return f;
        }, filterGroup.filters);
    };

    mapFilters(filter.filters);
};

const searchFormatters = {
    toSolrQueryFormat: stringToSOLRQuery,
    toSolrFilterFormat: toSOlRFilterQuery,
    clearDirtyFilters,
    dirtyFiltersCount,
    escapeSolrSpecialChars,
    escapeSolrSpecialCharsRegex,
    topLevelCategoriesSelectedCount,
    getSelectedDataFilters

};

export const { toSolrFilterFormat, toSolrQueryFormat } = searchFormatters;

