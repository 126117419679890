import { ReCaptchaAction } from 'src/helpers/recaptcha';
import { getAsync, getUser, postAsync } from '../baseService';
import { seller, user } from '../service-config';
import { UserPreferences } from 'src/features/user/MyPostalSettings';
import { IServiceResponse } from '../ServiceResponse';



export interface IUserService {
    updateMidService: (param: { userId: string, mId: string; }) => Promise<void>;
    updatePostagePreferencesService: (param: { postageSettings: UserPreferences; }) => Promise<IServiceResponse<UserPreferences>>;
    getPostagePreferencesService: (userId: string) => Promise<IServiceResponse<UserPreferences>>;
    resendVerificationEmailService: () => Promise<void>;
}

const userService: IUserService = {
    resendVerificationEmailService: async () => {
        try {
            const usr = await getUser();
            await postAsync<void, { email: string; }>(
                user.resendVerificationEmail,
                {
                    email: usr?.email ?? ''
                },
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                ReCaptchaAction.api_post,
                true
            );
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
    },

    updatePostagePreferencesService: async (param: { postageSettings: UserPreferences; }) => {

        const usr = await getUser();
        try {
            const response = await postAsync<UserPreferences, UserPreferences>(
                `${user.updatePostagePreferences}/${btoa(usr?.email ?? '')}`, param.postageSettings,
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                ReCaptchaAction.api_post,
                true
            );
            return { result: response.result } as IServiceResponse<UserPreferences>;
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            return {
                err: err
            } as IServiceResponse<UserPreferences>;
        }
    },
    getPostagePreferencesService: async (userId: string) => {
        try {

            const usr = { email: userId };


            const response = await getAsync<IServiceResponse<UserPreferences>>(
                `${user.getPostagePreferences}/${usr.email}`,
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }, undefined,
                false
            );
            return { result: response.result };
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            return { err: err } as any;
        }
    },
    updateMidService: async (param: { userId: string, mId: string; }) => {
        try {

            await postAsync<void, { id: string, mId: string; type: string; }>(
                seller.updateMidUrl,
                {
                    type: 'USER_MERCHANT_ID',
                    id: param.userId,
                    mId: param.mId
                },
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
                ,
                ReCaptchaAction.api_post,
                true
            );

        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
    }
};


export const {
    updateMidService,
    getPostagePreferencesService,
    updatePostagePreferencesService,
    resendVerificationEmailService
} = userService;