import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { error, logEvents } from '../../actions/logActions';
import { cardAutoCompleteService, cardSearchService, getCardByIdService, getInsightCardDetailsService, getInsightCardListService } from '../../services/cards-service/cards-service';
import { getCardSearchParameters, toSolrQueryFormat } from '../../services/search-service/searchUtilities';
import { ISearchParameters } from '../../services/search-service/ISearchParameters';
import { IGraphSearchResult } from '../../services/search-service/SolrSearchResults';
import { mapToSearchResultItem } from '../../services/search-service/mapper';
import { apolloQuery } from 'src/useApolloQuery';
import { cardGetCardsBySeriesQuery as cardGetCardsBySetQuery, cardSeriesSetQuery } from 'src/data/gql/queries/cardQueries';
import { setIsLoadingCards } from './cardSearchSlice';
import { ICardPagedResults } from '../insights/IPagedResults';
import { AsyncThunkConfig } from 'src/types/toolkit';

export interface ICardSeries {
    cardSeries: Array<{ name: string, sets: Array<{ name: string; }>; }>;
    cards: any;
}

export interface ICardCollectionResults {
    cardCollection: Array<ICardSeries>;
    groupedCards: {
        series_str: {
            groups: Array<{
                doclist: {
                    docs: Array<{
                        series_str: string;
                        set_str: string;
                        imageSrc: string;
                    }>;
                };
            }>;
        };
    };
}

export interface ISeriesSet {

    name: string;
    sets: Array<{ name: string; }>;
    cards: any;
}

interface IQueryFilter { series: string, set: string; }


interface ICardActions {
    getCardCollection: AsyncThunk<ISeriesSet | undefined, string, AsyncThunkConfig>;
    getCardsBySetService: AsyncThunk<ISeriesSet, IQueryFilter, AsyncThunkConfig>;
    getCardByIdAction: any;
    searchCardsAction: any;
    cardsAutoCompleteAction: any;
    getInsightsCardList: AsyncThunk<ICardPagedResults, { searchTerm: string | undefined, currentPage: number; }, AsyncThunkConfig>;
    getInsightCardDetails: any;
}


const cardActions: ICardActions = {
    getCardsBySetService: createAsyncThunk('cards/getCardsBySeries', async (filter: { series: string, set: string; }, thunkApi: any) => {
        try {


            const filters = [];
            filter?.series && filters.push({ name: 'series_str', value: filter?.series });
            filter?.set && filters.push({ name: 'set_str', value: filter?.set });

            const { data, error } = await apolloQuery<ICardCollectionResults>(cardGetCardsBySetQuery.query,
                {
                    ...cardGetCardsBySetQuery.variables,
                    filter: filters
                });

            if (error) {
                return thunkApi.rejectWithValue(error);
            }

            if (data) {
                return data.cardCollection[0].cards;
            }

            return thunkApi.rejectWithValue('No data');

        } catch (ex: any) {
            error('CARD_SEARCH_ERROR', { currentUrl: window.location.href, errorMessage: ex.stack || ex.message, moduleName: 'cardActions' });
            return thunkApi.rejectWithValue(ex.stack || ex.message);
        }
    }),
    getCardCollection: createAsyncThunk('cards/getCardCollection', async (_, thunkApi: any) => {
        try {
            const { data, error } = await apolloQuery<ICardCollectionResults>(cardSeriesSetQuery.query, { ...cardSeriesSetQuery.variables });

            if (error) {
                return thunkApi.rejectWithValue(error);
            }

            if (data) {

                return data.cardCollection[0].cardSeries.map(i => ({ name: i.name, sets: i.sets.map(s => ({ name: s.name, cards: [] })) }));
            }

            return thunkApi.rejectWithValue('No data');

        } catch (ex: any) {
            error('CARD_SEARCH_ERROR', { currentUrl: window.location.href, errorMessage: ex.stack || ex.message, moduleName: 'cardActions' });
            return thunkApi.rejectWithValue(ex.stack || ex.message);
        }

    }),
    getCardByIdAction: createAsyncThunk('cards/getCardById', async (cardId: string, thunkApi: any) => {
        try {
            const result = await getCardByIdService(cardId);
            if (!result?.err) {
                return result?.result;
            }

            thunkApi.dispatch(logEvents({ eventName: 'CARD_SEARCH_ERROR', payload: { error: result.err } }));
            return thunkApi.rejectWithValue(result.err);

        } catch (ex: any) {

            error('CARD_SEARCH_ERROR', { currentUrl: window.location.href, errorMessage: ex.stack || ex.message, moduleName: 'cardActions' });
            return thunkApi.rejectWithValue(ex.message);
        }
    }),
    cardsAutoCompleteAction: createAsyncThunk('cards/autocomplete',
        async (searchString: string, thunkApi: any) => {
            try {

                const query = toSolrQueryFormat('search_all', searchString);
                const results = await cardAutoCompleteService(query);
                const returnValue =
                    results?.result?.grouped?.search_all.groups
                        .flatMap((i: any) => i.doclist.docs
                            .map((b: any) => ({
                                id: b.cardId,
                                value: (b.search_all as string[])
                                    .filter((_, index) => index < 4)
                                    .join(' ')
                            }))
                        ) || [searchString];

                return [{ id: 0, value: searchString }, ...returnValue];

            } catch (ex: any) {
                error('CARD_SEARCH_ERROR', { currentUrl: window.location.href, errorMessage: ex.stack || ex.message, moduleName: 'cardActions' });
                return thunkApi.rejectWithValue(ex.stack || ex.message);
            }
        }),
    searchCardsAction: createAsyncThunk('cards/search',
        async (_, thunkApi: any) => {
            try {
                thunkApi.dispatch(setIsLoadingCards(true));
                const params = getCardSearchParameters(thunkApi.getState()) as ISearchParameters;
                const result = await cardSearchService(params);

                if (!result?.err) {
                    const returnResult: IGraphSearchResult = {
                        records: await mapToSearchResultItem(result?.result?.response.docs || []),
                        facets: result?.result?.facets || {},
                        facetCounts: result?.result?.facet_counts.facet_fields || {},
                        totalRecords: result?.result?.response.numFound || 0,
                        start: result?.result?.response.start || 0,
                    };
                    return returnResult;
                }

                thunkApi.dispatch(logEvents({ eventName: 'CARD_SEARCH_ERROR', payload: { error: result.err } }));
                return thunkApi.rejectWithValue(result.err);

            } catch (ex: any) {
                thunkApi.dispatch(logEvents({ eventName: 'CARD_SEARCH_ERROR', payload: { error: 'failed to get card with search parameters ' } }));
                return thunkApi.rejectWithValue(ex.message);
            }
        }),
    getInsightsCardList: createAsyncThunk('cards/getCardinsightsList', async (args: { searchTerm: string | undefined, currentPage: number; }, thunkApi: any) => {
        try {
            const result: ICardPagedResults | undefined = await getInsightCardListService({ query: args.searchTerm, currentPage: args.currentPage } as ISearchParameters);

            if (result != undefined) {
                return result.cards;
            }

            thunkApi.dispatch(logEvents({ eventName: 'CARD_SEARCH_ERROR', payload: { error: result } }));
            return thunkApi.rejectWithValue(result);

        } catch (ex: any) {

            error('CARD_SEARCH_ERROR', { currentUrl: window.location.href, errorMessage: ex.stack || ex.message, moduleName: 'cardActions' });
            return thunkApi.rejectWithValue(ex.message);
        }
    }),
    getInsightCardDetails: createAsyncThunk('cards/getCardinsightDetails', async (cardId: string, thunkApi: any) => {
        try {
            const result = await getInsightCardDetailsService(cardId);
            if (!result?.err) {
                return result;
            }

            thunkApi.dispatch(logEvents({ eventName: 'CARD_SEARCH_ERROR', payload: { error: result.err } }));
            return thunkApi.rejectWithValue(result.err);

        } catch (ex: any) {

            error('CARD_SEARCH_ERROR', { currentUrl: window.location.href, errorMessage: ex.stack || ex.message, moduleName: 'cardActions' });
            return thunkApi.rejectWithValue(ex.message);
        }
    })
};

export const {
    getCardByIdAction,
    searchCardsAction,
    cardsAutoCompleteAction,
    getCardCollection,
    getCardsBySetService,
    getInsightsCardList,
    getInsightCardDetails
} = cardActions;
