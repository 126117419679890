import React, { useEffect } from 'react';
import { Section } from './Section';
import classes from '../../styles/preview.module.scss';
import { Instruction } from '../../components/shared/Instruction';
import { Heading } from '../../components/shared/Heading';
import { useAppDispatch, useAppSelector } from '../../hook';
import { selectCurrentListing, selectFeeTable } from './createListingSelectors';
import ReCAPTCHA from 'react-google-recaptcha';
import { toCurrency } from '../../utils/utilities';
import { getFeeTable } from './createListingAction';
import { setRecapToken } from './store/createListingSlice';



export function CreateListingReview() {

    const feeTable = useAppSelector(selectFeeTable);
    const currentListing = useAppSelector(selectCurrentListing);
    const dispatch = useAppDispatch();




    window.recapCallback = (response: any) => {
        dispatch(setRecapToken(response));
    };

    useEffect(() => {
        (+currentListing.buyItNowPrice || 0) > 0 &&
            dispatch(getFeeTable({
                buyItNowPrice: +currentListing.buyItNowPrice,
                countryCode: currentListing.countryCode ?? 'UNKNOWN',
                domesticShippingCharge: currentListing.shipping.domesticShippingCharge,
                intlShippingCharge: currentListing.shipping.internationalShippingCharge,
            }));
    }, []);

    return <Section title="Review Listing">
        <div style={{ padding: 10 }}>
            <Instruction text="Please ensure that all steps accurately reflect your listed productions condition, price and postage desires." style={{ margin: 0 }} />
            <br />
            <br />
            <Instruction text="Use the back button to make changes to your listing or cancel this listing and start again." style={{ margin: 0 }} />
            <br />
            <br />
            <Instruction text="All listing are covered by Paypal Buyers Protection Insurance, please check our FAQs for more details" style={{ margin: 0 }} />
            <br />
            <br />
            <Heading text="Listing Fee Breakdown" />

        </div>
        <div className={classes.previewRoot} style={{ padding: 10 }}>
            <div className={classes.table}>
                <div className={classes.headerRow}>
                    <span className={classes.cell}>Transaction Types</span>
                    <span className={classes.cell}>Local Buyer</span>
                    <span className={classes.cell}>International Buyer</span>
                </div>
                <div className={classes.row}>
                    <div className={classes.cell}><span>Sold Amount</span> <span className={classes.cellPost}>(Postage Included)</span></div>
                    <span className={classes.cell}>{toCurrency(feeTable?.soldAmount ?? 0)}</span>
                    <span className={classes.cell}>{toCurrency(feeTable?.soldAmount ?? 0)}</span>
                </div>
                <div className={classes.row}>
                    <span className={classes.cell}>Tax</span>
                    <span className={classes.cell}>{toCurrency(feeTable?.salesTaxAmount ?? 0)}</span>
                    <span className={classes.cell}>{toCurrency(feeTable?.salesTaxAmount ?? 0)}</span>
                </div>
                <div className={classes.row}>
                    <span className={classes.cell}>Est. Payment Gateway Fee</span>
                    <span className={classes.cell}>{toCurrency(feeTable?.pgTotalLocal ?? 0)}</span>
                    <span className={classes.cell}>{toCurrency(feeTable?.pgTotalInt ?? 0)}</span>
                </div>
                <div className={classes.row}>
                    <span className={classes.cell}>Buyers Protection</span>
                    <span className={classes.cell}>Paypal GnS</span>
                    <span className={classes.cell}>Paypal GnS</span>
                </div>
                <div className={classes.row}>
                    <span className={classes.cell}>Platform Fee</span>
                    <span className={classes.cell}>{toCurrency(feeTable?.midnightMerchantFee ?? 0)}</span>
                    <span className={classes.cell}>{toCurrency(feeTable?.midnightMerchantFee ?? 0)}</span>
                </div>
                <div className={classes.row}>
                    <span className={classes.cell}>Shipping</span>
                    <span className={classes.cell}>{toCurrency(feeTable?.domesticShippingAmount ?? 0)}</span>
                    <span className={classes.cell}>{toCurrency(feeTable?.internationalShippingAmount ?? 0)}</span>
                </div>
                <div className={classes.row}>
                    <span className={classes.cell}>Total Fees & Tax</span>
                    <span className={classes.cell}>{toCurrency(feeTable?.totalFeesAndTaxLocal ?? 0)}</span>
                    <span className={classes.cell}>{toCurrency(feeTable?.totalFeesAndTaxInt ?? 0)}</span>
                </div>
                <div className={classes.row}>
                    <span className={classes.cell}>Final Payout Amount</span>
                    <span className={classes.cell}>{toCurrency(feeTable?.totalLocalBuyer ?? 0)}</span>
                    <span className={classes.cell}>{toCurrency(feeTable?.totalInternationalBuyer ?? 0)}</span>
                </div>
            </div>
            <Instruction text="Fees & Taxes are evaluated subject to the buyers location, the breakdown provides a close estimation of your final payout when sold" style={{ fontStyle: 'italic', margin: 0, marginTop: 10, maxWidth: 600 }} />
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_V2 ?? ''}
                onChange={window.recapCallback}
            />


            {/* <div className="g-recaptcha" data-sitekey={process.env.REACT_APP_RECAPTCHA_V2} data-callback='recapCallback'></div> */}
        </div>
    </Section >;


}
