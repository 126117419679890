import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../hook';
import classes from '../../styles/market-analysis.module.scss';
import styles from './../../styles/listing-price.module.scss';
import { toCurrency } from '../../utils/utilities';
import { Heading } from '../../components/shared/Heading';
import { PriceTiles } from '../createListing/PriceTiles';
import { LineGraph } from '../../components/graphs/LineGraph';



export function MarketAnalysis() {
    const summary = useAppSelector((state) => state.listing.marketAnalyticsSummary);
    const [hasData, setHasData] = useState(false);

    useEffect(() => {
        const hd = !!summary?.data?.sold_price_low_current_months ||
            !!summary?.data?.sold_price_median_current_months ||
            !!summary?.data?.sold_price_high_current_months;
        setHasData(hd);
    }, [summary?.data]);


    const loading = summary?.isLoading;

    return <div className={classes.container}>
        <Heading text="Market Analysis"></Heading>

        <div className={`${classes.priceSum}`}>
            <div className={`${!hasData ? classes.noData : classes.hide}`}></div>
            <div className={classes.priceEst}>
                <span>{toCurrency(summary?.data?.sold_price_low_current_months ?? 0, 'en', '0', 'AUD')}</span>
                <div className={classes.priceInd}>
                    <span>Low</span>
                </div>
            </div>
            <div style={{ border: '1px solid #ffffff30' }}></div>
            <div className={classes.priceEst}>
                <span style={{ color: '#EE8F00' }}>{toCurrency(summary?.data?.sold_price_median_current_months ?? 0, 'en', '0', 'AUD')}</span>
                <div className={classes.priceInd}>
                    <span>Mid</span>
                </div>
            </div>
            <div style={{ border: '1px solid #ffffff30' }}></div>
            <div className={classes.priceEst}>
                <span>{toCurrency(summary?.data?.sold_price_high_current_months ?? 0, 'en', '0', 'AUD')}</span>
                <div className={classes.priceInd}>
                    <span>High</span>
                </div>
            </div>

        </div>

        <div className={classes.currencyText}>
            <span>*Market Analysis Data based on USD currency, displayed in AUD.</span>
        </div>


        <div className={styles.container}>
            <PriceTiles
                newPrice={''}
                growthRate=""
                desc1="Median Price"
                desc2="Growth Rate"
                tooltip="-"
                label1=""
                isLoading={loading}
            ></PriceTiles>
            <PriceTiles
                newPrice={toCurrency(summary?.data?.sold_price_median_3_months ?? 0, 'en', '0', 'AUD')}
                growthRate={`${summary?.data?.growth_rate_median_3_months ?? 0}%`}
                label1="3 Months"
                tooltip=""
                desc1=""
                desc2=""
                isLoading={loading}
            ></PriceTiles>
            <PriceTiles
                newPrice={toCurrency(summary?.data?.sold_price_median_6_months ?? 0, 'en', '0', 'AUD')}
                growthRate={`${summary?.data?.growth_rate_median_6_months ?? 0}%`}
                label1="6 Months"
                tooltip=""
                desc1=""
                desc2=""
                isLoading={loading}
            ></PriceTiles>
            <PriceTiles
                newPrice={toCurrency(summary?.data?.sold_price_median_12_months ?? 0, 'en', '0', 'AUD')}
                growthRate={`${summary?.data?.growth_rate_median_12_months ?? 0}%`}
                label1="1 Year"
                tooltip=""
                desc1=""
                desc2=""
                isLoading={loading}
            ></PriceTiles>
            <PriceTiles
                newPrice={toCurrency(summary?.data?.sold_price_median_24_months ?? 0, 'en', '0', 'AUD')}
                growthRate={`${summary?.data?.growth_rate_median_24_months ?? 0}%`}
                label1="2 Year"
                tooltip=""
                desc1=""
                desc2=""
                isLoading={loading}
            ></PriceTiles>
            <PriceTiles
                newPrice={toCurrency(summary?.data?.sold_price_median_all_months ?? 0, 'en', '0', 'AUD')}
                growthRate={`${summary?.data?.growth_rate_median_all_months ?? 0}%`}
                label1="All Time"
                tooltip=""
                desc1=""
                desc2=""
                isLoading={loading}
            ></PriceTiles>
        </div>

        <LineGraph isLoading={true} />
    </div>;
}
