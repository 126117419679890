import { useEffect, useState } from 'react';
import { useEffectOnce, useGeolocation } from 'react-use';
import { logError } from '../actions/logActions';
import tzones from 'moment-timezone/data/meta/latest.json';
// import { t } from 'i18next';


// interface ResolvedDateTimeFormatOptions {
//     locale: string;
//     calendar: string;
//     numberingSystem: string;
//     timeZone: string;
//     hour12?: boolean;
//     weekday?: string;
//     era?: string;
//     year?: string;
//     month?: string;
//     day?: string;
//     hour?: string;
//     minute?: string;
//     second?: string;
//     timeZoneName?: string;
// }


export interface Zone {
    name: string;
    abbr: string;
    zones: string[];
}
export function useLocales() {

    const [country, setCountry] = useState<Zone | undefined>();
    const location = useGeolocation();

    const [loc, setLoc] = useState<any>(location);

    useEffect(() => {

        let isActive = true;

        (async () => {
            const t = await getZones();
            if (isActive) setCountry(t);

        })();

        return () => { isActive = false; };
    });

    const successCallback = (position: any) => {
        setLoc(position);
    };

    const errorCallback = (error: any) => {
        logError(error);
    };

    useEffectOnce(() => {
        const id = navigator.geolocation.watchPosition(successCallback, errorCallback);
        return () => navigator.geolocation.clearWatch(id);
    });
    return {
        country, loc
    };
}


export const useZone = () => {
    const [zone, setZone] = useState<Zone | undefined>();
    useEffect(() => {
        (async () => {
            const t = await getZones();
            setZone(t);
        })();
    }, []);

    return zone;

};

export const getZones = (): Zone | undefined => {
    const locale = new Intl.DateTimeFormat().resolvedOptions();

    const { zones, countries } = tzones;
    const thisCountry: { [key: string]: any; } = {};
    if (Intl) {
        const timeZone = locale.timeZone;
        for (const zone in zones) {
            const z = zone as keyof typeof zones;
            const country = zones[z].countries[0];
            thisCountry[z as string] = countries[country as keyof typeof countries];
        }

        return thisCountry[timeZone];
    }
    else {
        // eslint-disable-next-line no-console
        console.warn('Intl not supported');
        return undefined;
    }
};